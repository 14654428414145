<template>
  <Dialog visible @close="onClose">
    <div class="w-full flex items-center flex-col">
      <p class="mb-6 text-text-deep font-bold text-2xl text-center">
        {{ step == 1 ? $t("reset-forgot") : $t("reset-pw") }}
      </p>
      <div class="iv-form">
        <div v-if="step == 1" class="iv-form-item">
          <div class="iv-form-item-label">{{ $t('login-label-email') }}</div>
          <div class="iv-form-item-box">
            <div class="iv-form-item-input">
              <input
                type="email"
                :placeholder="$t('email')"
                v-model="form.email"
              />
            </div>
          </div>
          <div class="iv-err-tip" v-if="error.email">！{{ error.email }}</div>
        </div>
        <div v-if="step == 1" class="iv-form-item">
          <div class="iv-form-item-label">{{ $t('sign-up-label-code') }}</div>
          <div class="iv-form-item-box">
            <div class="iv-form-item-input">
              <input
                :maxlength="6"
                :placeholder="$t('verify-code')"
                v-model="form.verifyCode"
              />
            </div>
            <ASpin :loading="sendLoading" class="code-spinning">
              <div
                class="btn-normal-2 iv-send-code"
                @click="onSendCode"
                :class="{ disable: isSend }"
              >
                <div>
                  {{ isSend ? time + "s" : $t("send-code") }}
                </div>
              </div>
            </ASpin>
          </div>
          <div class="iv-err-tip" v-if="error.verifyCode">
            ！{{ error.verifyCode }}
          </div>
        </div>
        <div v-if="step == 2" class="iv-form-item">
          <div class="iv-form-item-label">{{ $t('login-label-password') }}</div>
          <div class="iv-form-item-box">
            <div class="iv-form-item-input">
              <input
                type="password"
                :placeholder="$t('password')"
                v-model="form.pwd"
                :maxlength="16"
              />
            </div>
          </div>
          <div class="iv-err-tip" v-if="error.pwd">！{{ error.pwd }}</div>
          <div class="iv-valid-tip" v-else>
            *{{ $t("sign-up-tip-pw-format") }}
          </div>
        </div>
        <div v-if="step == 2" class="iv-form-item">
          <div class="iv-form-item-label">{{ $t('sign-up-confirm-password') }}</div>
          <div class="iv-form-item-box">
            <div class="iv-form-item-input">
              <input
                type="password"
                :placeholder="$t('sign-up-ph-confirm-password')"
                v-model="form.rePwd"
                :maxlength="16"
              />
            </div>
          </div>
          <div class="iv-err-tip" v-if="error.rePwd">！{{ error.rePwd }}</div>
          <div class="iv-valid-tip" v-else>
            *{{ $t("sign-up-tip-input-repw") }}
          </div>
        </div>
      </div>
      <!-- <div class="other">
        <span @click="onClose">&lt; {{ $t("login") }}</span>
      </div> -->
      <div
          class="mt-8 sm:mt-[60px] h-10 rounded-full w-full font-Roboto-Bold font-bold  text-base leading-10 text-white text-center "
          :class="disable ? 'bg-btn-gray cursor-not-allowed' : 'bg-mainColor cursor-pointer'"
          @click="submit"
        >
        <icon-loading v-show="loading" class="mr-3"/>{{ $t("login-login") }}
        </div>
    </div>
    <template #footer>
      <p class="text-text-deep text-xs mt-7">
        {{ $t('modal-sign-account') }}
            <span @click="onClose" class="text-mainColor cursor-pointer"
              >{{ $t('modal-sign-signIn') }}</span
            >
          </p>
    </template>
  </Dialog>
</template>
<script setup lang="ts">
import { publicKey } from "@/utils/config";
import { verifyEmail, verifyPw } from "@/utils/verify";
import messageBox from "@/composables/messageBox";
import { useMainStore } from "@/store/index";
const mainStore = useMainStore();
const { t } = useI18n();
const { $encryptor } = useNuxtApp();
interface Form {
  email: string;
  pwd: string;
  rePwd: string;
  verifyCode: string;
}
interface Error {
  email: string;
  pwd: string;
  rePwd: string;
  verifyCode: string;
}
const sendTime = 60;
const time = ref(sendTime);
const form = ref<Form>({
  email: "",
  pwd: "",
  rePwd: "",
  verifyCode: "",
});
const error = ref<Error>({
  email: "",
  pwd: "",
  rePwd: "",
  verifyCode: "",
});
const loading = ref(false);
const isSend = ref(false);
const codeTimer = ref();
const step = ref(1);
const sendLoading = ref(false);
// const decrypt = ref();
const disable = computed(() => {
  if (step.value == 1) {
    if (!form.value.email.trim() || !form.value.verifyCode.trim()) {
      return true;
    }
  } else {
    if (!form.value.pwd.trim() || !form.value.rePwd.trim()) {
      return true;
    }
  }
  return false;
});
onMounted(() => {
  // const JSEncrypt = require("jsencrypt");
  // decrypt.value = new JSEncrypt.JSEncrypt();
  // $encryptor?.setPublicKey(publicKey); // 设置公钥
});
function onClose() {
  loading.value = false;
  mainStore.setVisibleForgot(false);
  mainStore.setVisibleLogin(true);

  clearInterval(codeTimer.value);
}
function submit() {
  if (step.value == 1) {
    retrieve();
  } else {
    reset();
  }
}
function retrieve() {
  if (disable.value) {
    return;
  }
  switch (verifyEmail(form.value.email)) {
    case -1:
    case -2:
      error.value.email = t("login-tip-invalid-email");
      return;
    default:
      error.value.email = "";
      break;
  }
  loading.value = true;
  useApi
    .tmpVerifyCode({
      email: form.value.email,
      verifyCode: form.value.verifyCode,
    })
    .then((res: any) => {
      const { data, code } = res;

      if (code === 0) {
        step.value = 2;
      } else if (code === 300004) {
        step.value = 1;
      }
      loading.value = false;
    })
    .catch((e: any) => {
      loading.value = false;
      // messageBox.error(t("login-sign-up"), t("sign-err"));
    });
}
function reset() {
  if (disable.value) {
    return;
  }
  switch (verifyPw(form.value.pwd.trim())) {
    case -1:
    case -2:
      error.value.pwd = t("sign-up-tip-pw-format");
      return;
    default:
      error.value.pwd = "";
      break;
  }

  if (form.value.pwd.trim() != form.value.rePwd.trim()) {
    error.value.rePwd = t("sign-up-tip-diff-pw");
    return;
  }
  error.value.rePwd = "";

  if (loading.value) {
    return;
  }
  // console.log(values);
  loading.value = true;
  $encryptor?.setPublicKey(publicKey);
  const pwd = $encryptor?.encrypt(form.value.pwd);
  useApi
    .forgetPwd({
      email: form.value.email,
      pwd: pwd,
      rePwd: pwd,
      verifyCode: form.value.verifyCode,
    })
    .then((res: any) => {
      const { data, code } = res;

      if (code === 0) {
        messageBox.success(t("reset-pw"), t("reset-pw-success"));
        onClose();
      } else if (code === 300004) {
        step.value = 1;
        error.value.pwd = "";
        error.value.rePwd = "";
        form.value.pwd = "";
        form.value.rePwd = "";
      }
      loading.value = false;
    })
    .catch((e: any) => {
      loading.value = false;
      messageBox.error(t("reset-pw"), t("reset-pw-err"));
    });
}
function onSendCode() {
  switch (verifyEmail(form.value.email)) {
    case -1:
    // error.value.email = t("login-tip-empty-pw");
    // return;
    case -2:
      error.value.email = t("login-tip-invalid-email");
      return;
    default:
      error.value.email = "";
      break;
  }
  if (isSend.value || sendLoading.value) return;
  sendLoading.value = true;
  useApi
    .sendCode({ emailType: 2, email: form.value.email })
    .then((res: any) => {
      const { data, code } = res;
      if (code === 0) {
        isSend.value = true;
        codeTimer.value = setInterval(() => {
          time.value--;
          if (time.value <= 0) {
            isSend.value = false;
            clearInterval(codeTimer.value);
            time.value = sendTime;
          }
        }, 1000);
      } else {
        isSend.value = false;
      }
      sendLoading.value = false;
    })
    .catch((e: any) => {
      isSend.value = false;
      sendLoading.value = false;
    });
}
</script>

<style scoped lang="scss">
@import '~/assets/css/iv-form.scss';
.sign-up {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  margin-top: 20px;
  margin-bottom: 24px;
  color: #000;
  font-family: "Roboto-Bold";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}
.btn {
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 36px;
  // width: 440px;
  margin-bottom: 16px;

  border: 1px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  // background-image: linear-gradient(to right, #fff), $linear-gradient-vip;
  background-color: #fff;
  width: 100%;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-image: linear-gradient(to right, #ffffff, #ffffff),
      $linear-gradient-vip;
  }
  img {
    width: 24px;
    height: 24px;
  }
  span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    margin-left: 16px;
    font-size: 14px;
    color: $text-deep;
  }
}

.disable {
  background: $color-bg !important;
  &:hover {
    background: $color-bg !important;
    cursor: not-allowed;
  }
}
.other {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  width: 100%;

  span {
    color: $text-deep;
    cursor: pointer;
    text-align: right;
    font-family: "Roboto-Bold";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.dialog-footer {
  margin-top: 40px;
  // width: 100%;
  .btn-normal-2 {
    margin: 0 auto;
    // width: 300px;
    height: 43px;
    border-radius: 44px;
    font-family: "Roboto-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding-left: 60px;
    padding-right: 60px;
    color: #fff;
    background: $main-btn-color-hover;
  }
}
@media screen and (max-width: 767px) {
}
.spinning {
  border-radius: 100px;
  overflow: hidden;
}
.code-spinning {
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 96px;
}
</style>
