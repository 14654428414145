<template>
  <div class="common-mask" v-show="visible">
    <!-- <div v-show="visible" class="shadow" /> -->

    <div class="mask-container">
      <div class="main">
        <div class="dialog">
          <div class="ellipse1" />
          <div class="ellipse2" />
          <div class="ellipse3" />

          <div class="closeBox" @click="cancel">
            <img src="@/assets/images/close.png" alt="close" />
          </div>

          <div class="dialog-header">
            <p>{{ t("api-contact-title1") }}</p>
            <p>{{ t("api-contact-title2") }}</p>
          </div>

          <div class="dialog-content">
            <a-form
              ref="formRef"
              :rules="rules"
              :model="form"
              layout="vertical"
              @submit="submit"
            >
              <a-form-item
                field="firstName"
                :label="t('api-contact-firstName')"
                validate-trigger="blur"
              >
                <a-input v-model="form.firstName" />
              </a-form-item>
              <a-form-item
                field="lastName"
                :label="t('api-contact-lastName')"
                validate-trigger="blur"
              >
                <a-input v-model="form.lastName" />
              </a-form-item>
              <a-form-item
                field="email"
                :label="t('api-contact-email')"
                validate-trigger="blur"
              >
                <a-input v-model="form.email" />
              </a-form-item>
              <a-form-item
                field="company"
                :label="t('api-contact-company')"
                validate-trigger="blur"
              >
                <a-input v-model="form.company" />
              </a-form-item>
              <a-form-item
                field="yourNeeds"
                :label="t('api-contact-yourNeeds')"
                validate-trigger="blur"
              >
                <a-textarea v-model="form.yourNeeds" auto-size />
              </a-form-item>
              <div class="dialog-footer">
                <!-- <a-spin :loading="loading">
                  <a-button html-type="submit" class="btn-normal-2" style="color: #fff;">{{
                    t("submit")
                  }}</a-button>
                
                </a-spin> -->
                <a-button
                  :loading="loading"
                  html-type="submit"
                  class="btn-normal-2"
                  style="color: #fff"
                  >{{ t("submit") }}</a-button
                >
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
// import { verifyEmail } from "@/utils/verify";
import messageBox from "@/composables/messageBox";
const model = defineModel("visible", { required: true });
const { t } = useI18n();
// const props = defineProps({
//   visible: {
//     type: Boolean,
//     default: false,
//   },
// });
const emit = defineEmits(["update:visible"]);
const loading = ref(false);

function validator(value, cb) {
  const match = /^\s/;
  if (match.test(value)) {
    cb(t("api-contact-empty-char"));
  } else {
    cb();
  }
}
const form = ref({
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  yourNeeds: "",
});
const rules = ref({
  firstName: [
    {
      required: true,
      message: t("pleaseInput", {
        name: t("api-contact-firstName"),
      }),
    },
    { maxLength: 50, message: t("inputLimit") },
    {
      validator,
    },
  ],
  lastName: [
    {
      required: true,
      message: t("pleaseInput", {
        name: t("api-contact-lastName"),
      }),
    },
    { maxLength: 50, message: t("inputLimit") },
    {
      validator,
    },
  ],
  email: [
    {
      required: true,
      message: t("pleaseInput", {
        name: t("api-contact-email"),
      }),
    },
    { maxLength: 100, message: t("inputLimit") },
    { type: "email", message: t("api-contact-email-msg") },
  ],
  company: [
    {
      required: true,
      message: t("pleaseInput", {
        name: t("api-contact-company"),
      }),
    },
    { maxLength: 100, message: t("inputLimit") },
    {
      validator,
    },
  ],
  yourNeeds: [
    {
      required: true,
      message: t("pleaseInput", {
        name: t("api-contact-yourNeeds"),
      }),
    },
    { maxLength: 250, message: t("inputLimit") },
    {
      validator,
    },
  ],
});
function cancel() {
  loading.value = false;
  emit("update:visible", false);
  // model.value = false;
}

function submit({ values, errors }) {
  console.log("submit", values, errors);
  if (errors) {
    return;
  }
  if (loading.value) {
    return;
  }
  useLogEvent({ eventName: "api-form-submit" });
  useFBLogEvent({ eventName: "api-form-submit" });
  // console.log(values);
  loading.value = true;
  useApi
    .feedbackMsg({
      surname: values.lastName.trim(),
      name: values.firstName.trim(),
      email: values.email,
      company: values.company.trim(),
      detail: values.yourNeeds.trim(),
    })
    .then((res) => {
      if (res.code === 0) {
        messageBox.success(
          t("api-contact-success"),
          t("api-contact-successContent")
        );
        // emit("update:visible", false);
        useFBLogEvent({ track: "track", eventName: "Lead", needPrefix: false });

        model.value = false;
      }
      loading.value = false;
    })
    .catch((e) => {
      loading.value = false;
    });
}
</script>

<style scoped lang="scss">
.mask-container {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: auto;

  .main {
    margin: 0 auto;
    width: 520px;
    position: relative;
    // top: 100px;
    // padding-bottom: 60px;
    top: 50%;
    transform: translateY(-50%);
    .dialog {
      padding: 60px;
      background: #ffffff;
      border-radius: 30px;
      position: relative;
      overflow: hidden;

      .ellipse1 {
        position: absolute;
        right: -86px;
        bottom: -40%;
        width: 542px;
        height: 560px;
        opacity: 0.75;
        background: $color-bg;
        filter: blur(250px);
      }
      .ellipse2 {
        position: absolute;
        left: -20px;
        top: -100px;
        width: 470px;
        height: 470px;
        flex-shrink: 0;
        border-radius: 470px;
        background: rgba(255, 181, 137, 0.2);
        filter: blur(150px);
      }
      .ellipse3 {
        position: absolute;
        left: -40px;
        top: -200px;
        width: 319px;
        height: 325px;
        flex-shrink: 0;
        border-radius: 325px;
        background: rgba(255, 181, 137, 0.2);
        filter: blur(100px);
      }

      .closeBox {
        position: absolute;
        top: 0;
        right: 0;
        padding: 22px;
        cursor: pointer;

        img {
          width: 20px;
        }
      }

      .dialog-header {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        color: $text-deep;
        text-align: left;
        position: relative;
      }

      .dialog-content {
        position: relative;
        text-align: left;

        :deep(.arco-form-item) {
          margin: 30px 0 0;
          padding: 0;

          .arco-form-item-label-col {
            padding-bottom: 10px;

            label {
              font-family: "Roboto";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              color: $text-gray;

              &:before {
                margin: 0;
                content: "";
              }
              &:after {
                margin-left: 8px;
                content: "*";
                display: inline-block;
                color: $auxiliary-color;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
              }
              .arco-form-item-label-required-symbol {
                display: none;
              }
            }
          }

          // .ant-form-item-control-wrapper {
          .arco-form-item-content-wrapper {
            padding: 0 0 4px;
            border-bottom: 2px solid $gray-middle;

            &:focus-within {
              border-bottom: 2px solid $text-deep;
            }
            .arco-input-wrapper,
            .arco-textarea-wrapper {
              background-color: transparent !important;
              border-color: transparent !important;
            }
            .arco-textarea-wrapper.arco-textarea-error:focus-within,
            .arco-textarea-wrapper.arco-textarea-error.arco-textarea-wrapper-focus {
              border: transparent;
            }
            .arco-input-wrapper.arco-input-error:focus-within,
            .arco-input-wrapper.arco-input-error.arco-input-wrapper-focus {
              border: transparent;
            }
            textarea,
            input {
              padding: 0;
              height: 20px;
              font-family: "Roboto-Bold";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 20px;
              color: $text-deep;
              border: 0;
              box-shadow: none;
              border-radius: 0;
              background: transparent;
            }
          }

          .arco-form-item-message {
            margin-top: 8px;
          }
          // }
        }
      }

      .dialog-footer {
        margin-top: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        .btn-normal-2 {
          margin: 0 auto;
          width: 300px;
          height: 51px;
          border-radius: 44px;
          font-family: "Roboto-Bold";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #fff;
          background: $main-btn-color-hover;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .mask-container {
    padding: 100px 16px 40px;
    .main {
      width: 343px;
      margin: 0 auto;
      transform: none;
      top: 0;
      .dialog {
        padding: 72px 24px 40px 24px;
        .dialog-footer {
          .btn-normal-2 {
            width: auto;
            height: 55px;
          }
        }
      }
    }
  }
}
</style>
