<template>
  <nuxt-link
      class="block cursor-pointer"
      :to="
        localePath({
          name: 'blog-slug',
          params: { slug: article.urlPath },
        })
      "
    >
      <div>
        <NuxtImg
          :src="article.articleCover"
          alt="logo"
          height="260"
          width="390"
          format="webp"
          class="object-cover w-full aspect-[3/2] rounded-2xl"
          loading="lazy"
        />
      </div>
      <div
        class="py-[16px] flex gap-x-6 text-text-gray text-sm border-b border-solid border-t-gray-line"
      >
        <p v-for="tag in article.articleTagNameList" :key="tag">{{ tag }}</p>
      </div>
      <p class="mb-[20px] mt-[16px] text-xl text-text-deep font-Roboto-Bold font-bold  text-ellipsis line-clamp-2">
        {{ article.articleTitle }}
      </p>
      <p class="text-base text-text-deep font-Roboto text-ellipsis line-clamp-2">
        {{ article.seoDetail }}
      </p>
    </nuxt-link>
</template>

<script setup>
const localePath = useLocalePath();
const {article} = defineProps({
    article: Object,
});
</script>
