<template>
  <div class="w-full bg-[#1A1A1A] pt-[168px] lg:pt-[228px]">
    <div class="bg1">
      <div class="ml-[30px] text-white lg:ml-[60px]">
        <h1 class="my-0 text-text-gray text-[12px] leading-[14px] tracking-[0.2em]">
          {{ $t("index-title") }}
        </h1>
        <h2 class="font-Montserrat font-bold text-[44px] leading-[54px] my-2 lg:text-[64px] lg:leading-[78px]">
          <span>{{ $t("index-desc1") }}</span>
          <span class="bg-vip bg-clip-text text-transparent">{{ $t("index-desc2") }}</span
          ><br /><span>{{ $t("index-desc3") }}</span>
        </h2>
        <!-- <p class="text-text-gray text-xl w-1/2">
          AI elevates photo quality to HD across all devices, restoring vintage
          images with remarkable detail and achieving professional imaging
          excellence.
        </p> -->
        <ArrowBtn class="mt-5 mb-10 border border-white-line" :text="$t('tryNow')" path="picma_web-enhance" @click="toEnhance(FuncType.Enhance_PRO.path)" mode="white"></ArrowBtn>
        <!-- 图片滚动区域 -->
        <IndexImageScroll></IndexImageScroll>
        
      </div>
      <div class=" flex flex-col items-center">
          <div class="mb-[60px] mt-[80px] flex gap-4">
            <a
              href="https://apps.apple.com/us/app/picma-ai-photo-enhancer/id1599103406"
              target="_blank"
              @click="downIOS"
            >
              <NuxtImg
                src="/assets/images/index/appStore.webp"
                alt="app store"
                height="50"
                width="160"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.magictiger.ai.picma"
              target="_blank"
              @click="downGooglePlay"
            >
              <NuxtImg
                src="/assets/images/index/googlePlay.webp"
                alt="google play"
                height="50"
                width="160"
              />
            </a>
          </div>
        </div>
      <div class="max-w-[1000px] mx-auto  text-center">
        <p class=" font-Montserrat font-bold text-[30px] leading-[36px] text-white lg:text-[56px] lg:leading-[68px]">{{ $t('index-enhance-title') }}</p>
        <p class="mt-5 mb-10 text-base text-text-gray lg:text-xl">{{ $t('index-enhance-desc') }}</p>
      </div>
      <!-- 功能对比图 -->
      <div class="h-[520px] max-w-[1080px] mx-auto relative z-[3] px-4 lg:px-0 lg:h-[476px]">
        <IndexFuncCompare ></IndexFuncCompare>
        <div class="  mt-10 flex justify-center">
              <ArrowBtn :text="$t('index-enhance-btn')" path="picma_web-enhance" @click="toEnhance(FuncType.Enhance_PRO.path)"></ArrowBtn>
            </div>
      </div>
      
    </div>

    <div class="flex flex-col items-center bg-white relative">
      <div class="max-w-[1080px] w-full pt-[180px] pb-[60px] z-[2] px-4 lg:px-0 lg:pt-[390px] lg:pb-[160px]">
        <!-- 缩放操作 -->
        <div>
          <p class=" text-base text-text-deep mb-5 tracking-[5%]">{{ $t("AI-Eraser") }}</p>
          <p class="w-3/4 font-Montserrat font-bold text-[30px] leading-[36px] lg:text-[48px] lg:leading-[50px]">
            {{ $t('index-eraser-desc') }}
          </p>
          <div class="mt-5 mb-[50px] flex items-center gap-x-10">
            <p class="flex-1 text-base leading-6 text-text-gray">
              {{ $t('index-eraser-detail') }}
            </p>
            <div class="w-[200px] flex justify-end">
              <ArrowBtn :text="$t('tryNow')" path="picma_web-enhance" @click="toEnhance(FuncType.AI_Eraser.path)"></ArrowBtn>

            </div>
          </div>
          <IndexCtlShow></IndexCtlShow>
        </div>
        <div class="my-[160px] flex ">
          <div
            class="flex-1 bg-white rounded-[30px] p-5 border-white-line shadow-[0_8px_30px_0px_rgba(200,200,232,0.25)]"
          >
            <!-- <CompareImg
              class="rounded-[20px] overflow-hidden"
              origin="https://picsum.photos/id/200/560/560?blur"
              enhance="https://picsum.photos/id/200/560/560"
            ></CompareImg> -->
            <NuxtImg
                src="/assets/images/index/3/1.webp"
                alt="Color Master"
                loading="lazy"
                height="560"
                width="560"
                class=" rounded-[20px]"
              />
          </div>
          <div class="w-1/2 flex justify-center flex-col">
            <div class="ml-[60px]">
              <p class=" text-base text-text-deep mb-5 tracking-[5%]">{{ $t("ColorMaster") }}</p>

              <p
                class=" font-Montserrat font-bold text-[30px] leading-[36px] text-text-deep lg:text-[56px] lg:leading-[68px]"
              >
              {{ $t('index-colorMaster-desc') }}
              <!-- <br /><span class="text-mainColor">Colorize different types of B&W photos.</span> -->
              </p>
              <p
                class="text-[16px] leading-[20px] mt-3 mb-[60px] text-text-gray lg:text-[20px] lg:leading-[28px]"
              >
              {{ $t('index-colorMaster-detail') }}
              </p>
              <ArrowBtn :text="$t('tryNow')" path="picma_web-enhance" @click="toEnhance(FuncType.Color_Master.path)"></ArrowBtn>
            </div>
          </div>
        </div>
        <div class="flex ">
          <div class="w-1/2 flex justify-center flex-col">
            <div class="ml-[60px]">
              <p class=" text-base text-text-deep mb-5 tracking-[5%]">{{ $t("Descratch") }}</p>
              <p
              class="font-Montserrat font-bold text-[30px] leading-[36px] text-text-deep lg:text-[56px] lg:leading-[68px]"
              >
              {{ $t('index-descratch-desc') }}
              <!-- <br /><span class="text-mainColor">DeScratch</span> -->
              </p>
              <p
              class="text-[16px] leading-[20px] mt-3 mb-[60px] text-text-gray lg:text-[20px] lg:leading-[28px]"
              >
             {{ $t('index-descratch-detail') }}
              </p>
              <ArrowBtn :text="$t('tryNow')" path="picma_web-enhance" @click="toEnhance(FuncType.Descratch.path)"></ArrowBtn>
            </div>
          </div>
          <div
            class="flex-1 bg-white rounded-[30px] p-5 border-white-line shadow-[0_8px_30px_0px_rgba(200,200,232,0.25)]"
          >
            <!-- <CompareImg
              class="rounded-[20px] overflow-hidden"
              origin="https://picsum.photos/id/200/560/560?blur"
              enhance="https://picsum.photos/id/200/560/560"
            ></CompareImg> -->
            <NuxtImg
                src="/assets/images/index/3/2.webp"
                alt="AI Descratch"
                loading="lazy"
                height="560"
                width="560"
                class=" rounded-[20px]"
              />
          </div>
        </div>
      </div>
      <img class=" absolute left-1/2 -translate-x-1/2 bottom-0 scale-[-1]" src="/assets/images/index/bg2.webp" alt="bg">

      <img class=" absolute left-1/2 -translate-x-1/2 bottom-[950px]" src="/assets/images/index/bg2.webp" alt="bg">
    </div>
    <!-- 图片 -->
    <div class="bg-[#1A1A1A] relative pt-20" id="ai">
      <NuxtImg
        src="/assets/images/index/img1.webp"
        alt="origin"
        loading="lazy"
        format="webp"
        class="w-full"
      />
      <div
        class="w-2/3 absolute top-10 left-1/2 -translate-x-1/2 flex flex-col items-center lg:top-20 lg:max-w-[1000px]"
      >
        <p class="text-[16px] leading-[16px] text-center tracking-[5px] text-white mb-3 lg:text-[20px] lg:leading-[24px]">{{ $t('index-generation') }}</p>
        <p
          class=" my-0 font-Montserrat font-bold text-[36px] leading-[40px] text-center text-white lg:text-[56px] lg:leading-[68px]"
        >
        <span>{{ $t('index-generation-desc1') }}</span>
          <span class="bg-vip bg-clip-text text-transparent">{{ $t('index-generation-desc2') }}</span><span>{{ $t('index-generation-desc3') }}</span>
        </p>
        <p class="text-base leading-4 my-4 text-text-gray lg:mt-5 lg:mb-[40px]">
          {{ $t('index-generation-desc-2') }}
        </p>
        <div class="flex items-center gap-4">
          <a
            href="https://apps.apple.com/us/app/picma-ai-photo-enhancer/id1599103406"
            target="_blank"
            @click="downIOS"
          >
            <NuxtImg
              src="/assets/images/index/appStore.webp"
              alt="app store"
              height="50"
              width="160"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.magictiger.ai.picma"
            target="_blank"
            @click="downGooglePlay"
          >
            <NuxtImg
              src="/assets/images/index/googlePlay.webp"
              alt="google play"
              height="50"
              width="160"
            />
          </a>
        </div>
      </div>
    </div>
    <!-- 视频列表 -->
    <!-- <div class="bg-white">
      <div class="w-[1200px] mx-auto pt-[160px] flex flex-col items-center">
        <p class="font-Roboto-Bold font-bold  text-[56px] leading-[68px]">
          AI Video <span class="text-mainColor">Enhancer</span>
        </p>
        <p class="text-xl leading-6 mt-5 mb-8 text-text-gray">
          Illuminate every frame to captivate your audience and elevate the
          viewing experience
        </p>
        <ArrowBtn text="Learn More" path="picma_web-enhance"></ArrowBtn>
        <IndexVideoBanner class="mt-10"></IndexVideoBanner>
      </div>
    </div> -->
    <!-- 评价列表 -->
    <div class="bg-light-gray-bg relative">
      <div class="max-w-[1080px] mx-auto pt-[100px] flex flex-col items-center px-4 lg:px-0">
        <div>
          <p
            class="w-full mx-auto font-Montserrat font-bold text-[32px] leading-[36px] text-center lg:text-[56px] lg:leading-[68px]"
          >
            {{ $t('index-review1') }} <br /><span
              class="text-mainColor"
              >{{ $t('index-review2') }}</span
            >
          </p>
          <div
            class="mx-auto my-[60px] flex gap-x-[30px] gap-y-[20px] flex-wrap justify-center w-4/5 lg:gap-x-[66px] lg:gap-y-[30px]"
          >
            <span
              class="bg-btn-bg-hover h-[40px] rounded-full text-center text-base leading-[40px] px-10 text-text-deep lg:h-[64px] lg:leading-[64px] lg:text-xl"
              v-for="tag in [
                'Photographer',
                'Designer',
                'Entrepreneur',
                'Family',
                'Marketing',
                'E-Commerce',
                'Museum',
              ]"
              :key="tag"
              >{{ tag }}</span
            >
          </div>
          <IndexReview></IndexReview>
        </div>
      </div>
      <div
        class="absolute left-0 bottom-0 h-2/5 w-full"
        style="
          background-image: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 27.58%,
            #ffffff 76.91%
          );
        "
      ></div>
    </div>
    <TryNow></TryNow>
  </div>
</template>
<script setup>
import { useUploadStore } from "@/store/upload";
const uploadStore = useUploadStore();
// import { FuncType } from "@/utils/type";
const {getFuncType} = useFuncType()
const FuncType = ref(getFuncType())
// const localePath = useLocalePath();
// import { termsofService, privacyPolicy, cookiePolicy } from "@/utils/config";

// function goTop() {
//   window.scrollTo({ top: 0, behavior: "smooth" });
// }
function downIOS() {
  useLogEvent({ eventName: "index-ios-download" });
  useFBLogEvent({ eventName: "index-ios-download" });
}
function downGooglePlay() {
  useLogEvent({ eventName: "index-google-play-download" });
  useFBLogEvent({ eventName: "index-google-play-download" });
}
function toEnhance(path) {
  // uploadStore.setFuncType(FuncType.Enhance_PRO.path);
  uploadStore.setFuncType(path);
}
</script>

<style scoped lang="scss">
.bg1 {
  background-image: url("~/assets/images/index/bg1.webp");
  background-position: center;
  background-size: cover;
}
.bg2 {
  background-image: url("~/assets/images/index/bg2.webp");
  // background-position: center;
  // background-size: cover;
}
</style>
