<template>
  <nuxt-link
    v-if="props.mode === 'black'"
    :to="
      props.path
        ? localePath({
            name: props.path,
          })
        : ''
    "
    class="group px-[30px] rounded-full bg-text-deep inline-flex justify-center items-center gap-5 h-[48px] cursor-pointer border border-white-line lg:h-[64px] lg:px-[30px]"
  >
    <span class="text-white font-Roboto-Bold font-bold  pl-2 text-base leading-[18px] lg:text-[20px] lg:leading-[24px]">{{ props.text }}</span>
    <span
      class="w-[20px] transition-all duration-300"
    >
      <svg
        class="hidden arrow-move group-hover:inline-block w-4 h-4 lg:w-5 lg:h-5"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 2.22168L17.7782 9.99985L10 17.778"
          stroke="white"
          stroke-width="3"
        />
        <path d="M2.22183 9.99985H17.7782" stroke="white" stroke-width="3" />
      </svg>
      <svg
        class="group-hover:hidden w-4 h-4 lg:w-5 lg:h-5"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 2.22168L17.7782 9.99985L10 17.778"
          stroke="white"
          stroke-width="3"
        />
        <path d="M2.22183 9.99985H17.7782" stroke="white" stroke-width="3" />
      </svg>
    </span>
  </nuxt-link>
  <nuxt-link
    v-else
    :to="
      props.path
        ? localePath({
            name: props.path,
          })
        : ''
    "
    class="group px-[16px] rounded-full bg-white inline-flex justify-center items-center gap-5 h-[48px] cursor-pointer border border-white-line lg:h-[64px] lg:px-[30px]"
  >
    <span class="text-text-deep font-Roboto-Bold font-bold pl-2 text-base leading-[18px] lg:text-[20px] lg:leading-[24px]">{{ props.text }}</span>
    <span
      class=" w-5 transition-all duration-300"
    >
      <svg
        class="hidden arrow-move group-hover:inline-block w-4 h-4 lg:w-5 lg:h-5"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 2.22168L17.7782 9.99985L10 17.778"
          stroke="#1F1F1F"
          stroke-width="3"
        />
        <path d="M2.22183 9.99985H17.7782" stroke="#1F1F1F" stroke-width="3" />
      </svg>
      <svg
        class=" group-hover:hidden w-4 h-4 lg:w-5 lg:h-5"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 2.22168L17.7782 9.99985L10 17.778"
          stroke="#1F1F1F"
          stroke-width="3"
        />
        <path d="M2.22183 9.99985H17.7782" stroke="#1F1F1F" stroke-width="3" />
      </svg>
    </span>
  </nuxt-link>
</template>

<script setup>
const props = defineProps({
  text: {
    type: String,
    default: "",
  },
  path: {
    type: String,
    default: "",
  },
  // black | white
  mode: {
    type: String,
    default: "black",
  },
});
const localePath = useLocalePath();
</script>
