<template>
  <div
    class="h-full w-full relative  overflow-hidden select-none"
    @mouseleave="mouseleave"
    @wheel="handleMouseWheel"
    @touchmove.prevent
    @wheel.prevent
    style="transform: translate3d(0, 0, 0);"
  >
    <div
      class="h-full w-full bg-no-repeat bg-contain bg-center"
      :style="`background-image: url(${url});transform: scale(${scaleZoom/100}) translate3d(${translate.x}px,${translate.y}px,0)`"
    ></div>
    <div
      class="absolute w-full h-full top-0 left-0 cursor-grab "
      @mousemove="mousemove"
      @mouseup="onmouseup"
      @mousedown="onmouseDown"
    />
  </div>
</template>
<script setup>
const props = defineProps({
  url: {
    type: String,
    default: "",
  },
  minScale: {
    type: Number,
    default: 50,
  },
  maxScale: {
    type: Number,
    default: 250,
  },
  step: {
    type: Number,
    default:10,
  },
});
const translate = ref({ x: 0, y: 0 });
const scaleZoom = ref(100);
const moveX = ref(0);
const moveY = ref(0);
const isImgDown = ref(false);
const emit = defineEmits(["scaleChange"]);
defineExpose({ setScale });

function mousemove(e) {
    // console.log('mousemove');
  if (isImgDown.value) {

    const posX = e.offsetX - moveX.value;
    const posY = e.offsetY - moveY.value;
    if(Math.abs(translate.value.x - posX)<2 || Math.abs(translate.value.y - posY)<2 ){
        return
    }
    // console.log(posX, posY);

    translate.value = {
      x: posX,
      y: posY,
    };
  }
}

function onmouseDown(e) {
  e.preventDefault();
  isImgDown.value = true;

  moveX.value = e.offsetX;
  moveY.value = e.offsetY;
}
function onmouseup(e) {
  e.preventDefault();
  isImgDown.value = false;
}

function setScale(scale) {
  scaleZoom.value = scale;
  if (scale == 100) {
    translate.value = {
      x: 0,
      y: 0,
    };
  }
}
function mouseleave() {
//   console.log("mouseleave");
  isImgDown.value = false;
}

function handleMouseWheel(e) {
  if (!window.scrollY) {
    //   if (!isEnter.value) return;
    // 禁止页面滚动
    // e.preventDefault()
    // console.log('scaleZoom',scaleZoom.value);

    let scale = scaleZoom.value;
    // console.log(e.wheelDelta,scale);

    if (e.wheelDelta) {
      // 判断浏览器IE，谷歌滑轮事件
      if (e.wheelDelta > 0) {
        // scale = Decimal(scale).add(Decimal(props.step)).toNumber();
        scale += props.step;
        checkScale(scale);
      }
      if (e.wheelDelta < 0) {
        // 当滑轮向下滚动时
        // scale = Decimal(scale).sub(Decimal(props.step)).toNumber();
        scale -= props.step;

        checkScale(scale);
      }
    } else if (e.detail) {
      // Firefox滑轮事件
      if (e.detail > 0) {
        // 当滑轮向下滚动时
        // scale = Decimal(scale).sub(Decimal(props.step)).toNumber();
        scale -= props.step;
        checkScale(scale);
      }
      if (e.detail < 0) {
        // 当滑轮向上滚动时
        // scale = Decimal(scale).add(Decimal(props.step)).toNumber();
        scale += props.step;
        checkScale(scale);
      }
    }
  }
}
function checkScale(scale) {
  console.log("scale", scale, props.minScale, props.maxScale);

  if (scale >= props.minScale && scale <= props.maxScale) {
    scaleZoom.value = scale;
      emit("scaleChange", scale);
  }
}
</script>

