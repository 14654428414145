<template>
  <div class="common-mask" @click="onClose">
    <ASpin :loading="loading" class="spinning" :tip="$t('vip-payloading')" @click.stop>
      <div class="pay">
        <div class="price-box">
          <div class="pb-t">
            <img
              src="@/assets/images/caret-left.svg"
              alt="back"
              @click="onClose"
            />
            <div>
              <p>
                <span class="SB20">${{ price }}</span
                ><span class="M14"> /{{ unit[packageType] }}</span>
              </p>
            </div>
          </div>
          <div class="pb-b">
            <img src="@/assets/images/calendar.svg" alt="back" />
            <div class="text-overlay-black-60">
              <p class="B12">{{ $t("dialog-vip-pay-reminder") }}</p>
              <p class="M12">{{ $t("dialog-vip-pay-reminder-txt") }}</p>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <p class="SB18 mb-4">{{ $t("dialog-vip-paymentMethod") }}</p>
        <form ref="form" @submit.prevent="handleSubmit" id="payment-form">
          <div id="link-authentication-element"></div>
          <div id="payment-element" />
          <button
            type="submit"
            class="btn-normal-2 submit"
            :class="{ disable: disable }"
            :disabled="disable"
          >
            {{ $t("dialog-vip-subscription") }}
          </button>
        </form>
      </div>
    </ASpin>
  </div>
</template>
<script setup>
import { loadStripe } from "@stripe/stripe-js";
import { useMainStore } from "@/store/index";
import { MemberTypePrice } from "~/utils/type";
// import { toRaw } from "@vue/reactivity";
import messageBox from "@/composables/messageBox";
const {
  public: { STRIPE_KEY },
} = useRuntimeConfig();
const { t } = useI18n();
const fullLoading = useFullLoading();
const email = ref("");
const timeout = ref();
const disable = ref(true);
const loading = ref(false);
const stripe = ref();
const elements = ref();
const linkAuthenticationElement = ref();
const queryInfoTime = ref();
const mainStore = useMainStore();
const { packageType, userInfo, vipFrom } = mainStore;
// const packageType = mainStore.mainStore;
onMounted(() => {
  initStripe();
});
const price = MemberTypePrice[packageType];
const packNames = {
  1:'Week',
  2:'Year',
  3:'Week Pro',
  4:'Year Pro',
  5:'Month',
  6:'Month Pro'
}
const unit = ref({
  1:t('week'),
  2:t('year'),
  3:t('week'),
  4:t('year'),
  5:t('month'),
  6:t('month')
})
const packName = packNames[packageType];
function onClose() {
  //   useLogEvent("login-close");
  if(loading.value){
    return
  }
  mainStore.setVisiblePay(false);
  mainStore.setVisibleVipDialog(true);
}
async function initStripe() {
  stripe.value = await loadStripe(STRIPE_KEY);
  elements.value = stripe.value.elements({
    mode: "setup",
    currency: "usd",
    paymentMethodTypes: ["card"],
  });
  if (!userInfo.email) {
    linkAuthenticationElement.value =
      elements.value.create("linkAuthentication");
    linkAuthenticationElement.value.on("change", (event) => {
      const email = event.value.email;
      console.log("email", email);
      email.value = email;
    });
    linkAuthenticationElement.value.mount("#link-authentication-element");
  } else {
    email.value = userInfo.email;
  }

  const paymentElement = elements.value.create("payment", {
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
  });
  paymentElement.on("change", (event) => {
    console.log("event", event);
    disable.value = !event.complete;
  });

  paymentElement.mount("#payment-element");
}
async function handleSubmit(e) {
  e.preventDefault();
  if (disable.value) {
    return;
  }
  useLogEvent({ eventName: "vipDiaog-buy-" + vipFrom });
  useFBLogEvent({ eventName: "vipDiaog-buy-" + vipFrom });
  createOrder();
}
function createOrder() {
  loading.value = true;
  useApi
    .createOrder({
      orderType: packageType,
      productName: "picma_web",
      payWay: 1,
      email: email.value,
    })
    .then(async (res) => {
      if (!res || res.code !== 0 || !res.data) {
        loading.value = false;
        return;
      }

      useLogEvent({
        eventName: "begin_checkout",
        eventParams: {
          currency: "USD",
          value: price,
          items: [
            {
              item_id: res.data.orderNo,
              item_name: packName,
              creative_slot: vipFrom,
            },
          ],
        },
        needPrefix: false,
      });
      useFBLogEvent({
        eventName: "begin_checkout",
        eventParams: {
          currency: "USD",
          value: price,
          items: [
            {
              item_id: res.data.orderNo,
              item_name: packName,
              creative_slot: vipFrom,
            },
          ],
        },
        needPrefix: false,
      });
      let payError = null;
      const pay = JSON.parse(res.data.url);
      console.log("pay", pay);
      const orderInfo = { ...res.data, pay };
      let el = toRaw(elements.value);
      el.submit();

      let originStripe = toRaw(stripe.value);
      //试用
      const { error } = await originStripe.confirmSetup({
        elements: el,
        clientSecret: orderInfo.pay.clientSecret,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: "https://picma.magictiger.ai/order",
        },
        //redirect: 'if_required'如果设置redirect: 'if_required'则不跳转returnUrl
        redirect: "if_required",
      });
      payError = error;
      console.log("支付结果：", payError);
      if (payError && payError.code) {
        messageBox.error(t("vip-payFail"), t(payError.message));
        loading.value = false;
      } else {
        getOrder(res.data.orderNo);
      }
    })
    .catch((err) => {
      loading.value = false;
      console.log(err);
    });
}
function getOrder(orderNo) {
  useApi
    .queryOrder(orderNo)
    .then((res) => {
      if (!res || res.code !== 0 || !res.data) {
        // loading.value = false;

        return;
      }
      clearTimeout(timeout.value);
      switch (res.data.orderStatus) {
        case 1:
          // 支付成功
          messageBox.success(t("vip-pay"), t("vip-paySuccess"));
          const gEventParams = {
            affiliation: "stripe",
            currency: "USD",
            value: price,
            items: [
              {
                id: orderNo,
                name: packName,
                quantity: 1,
                price: price,
              },
            ],
          };
          const fbEventParams = {
            affiliation: "stripe",
            currency: "USD",
            value: price,
            content_ids: [`${orderNo}`],
            content_name: packName,
            content_type: "product",
          };
          //试用
          if (!userInfo.freeEndTime) {
            console.log('试用');
            useLogEvent({
              eventName: "StartTrial",
              eventParams: gEventParams,
              needPrefix: false,
            });
            useFBLogEvent({
              track: "track",
              eventName: "StartTrial",
              eventParams: fbEventParams,
              needPrefix: false,
            });
          } else {
            console.log('订阅');

            // 订阅
            useLogEvent({
              eventName: "Subscribe",
              eventParams: gEventParams,
              needPrefix: false,
            });
            useFBLogEvent({
              track: "track",
              eventName: "Subscribe",
              eventParams: fbEventParams,
              needPrefix: false,
            });
            useLogEvent({
              eventName: "purchase",
              eventParams: gEventParams,
              needPrefix: false,
            });
            useFBLogEvent({
              track: "track",
              eventName: "Purchase",
              eventParams: fbEventParams,
              needPrefix: false,
            });
          }

          // loading.value = false;
          // clearInterval(queryInfoTime.value);
          // this.cancel();
          queryInfoTime.value = setInterval(() => {
            console.log("查询会员状态");
            // 定时查询用户会员，防止用户开了多个对话框，在其他页面购买会员，当前页一直在loading
            useApi
              .getUserInfo()
              .then((res) => {
                if (res.code == 0) {
                  if (res.data.memberType > 0) {
                    fullLoading.hide();
                    loading.value = false;
                    console.log("会员购买成功，刷新数据");
                    // 会员购买成功，通知增强页面，如果是非会员，水印图变成无水印图
                    mainStore.setUserInfo(res.data);
                    window.dispatchEvent(new CustomEvent("enhanceVipEvent"));
                    mainStore.setVisibleVipDialog(false);
                    mainStore.setVisiblePay(false);
                    clearInterval(queryInfoTime.value);
                  }
                } else {
                  clearInterval(queryInfoTime.value);
                  loading.value = false;
                }
              })
              .catch((e) => {
                clearInterval(queryInfoTime.value);
                loading.value = false;
              });
          }, 2000);
          break;

        case 5:
          // 支付失败
          messageBox.error(t("vip-pay"), t("vip-payFail"));
          loading.value = false;
          break;

        case 7:
          // 支付失败
          messageBox.error(t("vip-pay"), t("vip-payFail"));
          loading.value = false;
          break;

        default:
          timeout.value = setTimeout(() => getOrder(orderNo), 2000);
      }
    })
    .catch((e) => {
      messageBox.error(t("vip-pay"), t("vip-payFail"));
      loading.value = false;
      clearTimeout(timeout.value);
    });
}
</script>

<style scoped lang="scss">
.SB16 {
  line-height: 20px;
  font-weight: 600;
  font-size: 16px;
}
.text-gray-400 {
  color: rgb(121, 121, 126);
}
.mb-1 {
  margin-bottom: 4px;
}
.SB18 {
  line-height: 21.48px;
  font-size: 18px;
}
.M14 {
  line-height: 16.71px;
  font-weight: 500;
  font-size: 14px;
}

.SB18,
.SB20 {
  font-weight: 600;
}
.text-black {
  color: #000;
}
.B12,
.B15 {
  font-weight: 700;
}
.B12 {
  line-height: 15px;
  letter-spacing: 3%px;
  font-size: 12px;
}
.M12 {
  line-height: 14.32px;
  font-size: 12px;
}
.M12,
.M13 {
  font-weight: 500;
}
.text-overlay-black-60 {
  color: rgba(0, 0, 0, 0.6);
}
.ms-3 {
  margin-inline-start: 12px;
}
.mb-4 {
  margin-bottom: 16px;
}
.spinning {
  border-radius: 30px;
  overflow: hidden;
  :deep(.ant-spin-text) {
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    color: #000;
  }
}
.pay {
  width: 426px;
  padding: 30px;
  // display: flex;
  // align-items: center;
  // flex-direction: column;
  border-radius: 30px;
  background-color: #fff;
  transition: opacity 0.6s ease-in;
  .price-box {
    border-radius: 30px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.07);
    border: 2px solid rgb(237, 235, 243);
    overflow: hidden;
    .pb-t {
      padding: 20px 24px 16px 24px;
      display: flex;
      align-items: center;
      img {
        padding: 8px;
        margin-right: 8px;
        width: 12px;
        box-sizing: content-box;
        cursor: pointer;
      }
    }
    .pb-b {
      padding: 20px 24px 16px 24px;
      display: flex;
      align-items: center;
      background-color: rgba(237, 235, 243, 0.4);
      img {
        padding: 8px;
        margin-right: 8px;
        width: 12px;
        box-sizing: content-box;
      }
    }
  }
  .line {
    background-color: rgba(237, 235, 243, 1);
    margin: 32px 0;
    height: 1px;
  }
  .submit {
    width: 100%;
    height: 60px;
    margin-top: 16px;
    border: none;
  }
  .disable {
    background: $color-bg !important;
    &:hover {
      background: $color-bg !important;
      cursor: not-allowed;
    }
  }
}
:deep(.ant-spin-nested-loading > div > .ant-spin) {
  max-height: 100%;
}
:deep(.ant-spin-blur) {
  opacity: 1;
}
@media screen and (max-width: 767px) {
  .pay {
    width: 100%;
    padding: 20px;
    .submit {
      height: 43px;
    }
  }
}
</style>
