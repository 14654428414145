import { useMainStore } from "@/store/index";
import { MemberType } from "~/utils/type";

export function useUserInfo() {
  const mainStore = useMainStore();
  const isLogin = computed(() => !!mainStore.userInfo?.userId);
  const isVip = computed(() => !!mainStore.userInfo?.userId && mainStore.userInfo?.memberType > MemberType.None);
  const isNormalVip = computed(() => !!mainStore.userInfo?.userId && (mainStore.userInfo?.memberType == MemberType.Week || mainStore.userInfo?.memberType == MemberType.Year || mainStore.userInfo?.memberType == MemberType.Month));
  const isVipPro = computed(() => !!mainStore.userInfo?.userId && (mainStore.userInfo?.memberType == MemberType.WeekPro || mainStore.userInfo?.memberType == MemberType.YearPro || mainStore.userInfo?.memberType == MemberType.MonthPro));
  function getUserInfo() {
    // const {data} = useUserInfo()
    // if(data.value?.code === 0){
    //     mainStore.setUserInfo(data.value.data)
    // }
  }
  return { isLogin, isVip, getUserInfo,isNormalVip,isVipPro };
}
