<template>
  <div class="w-full h-full border border-gray-line rounded-xl md:rounded-2xl flex flex-col items-center justify-center cursor-pointer hover:bg-btn-bg-hover">
    <a-upload
      ref="uploadRef"
      name="file"
      :multiple="props.acceptCount>1"
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      @before-upload="onBeforeUpload"
      :accept="props.imageAccept.map(v=>'.'+v).join()"
      :show-file-list="false"
      class="w-full h-full flex  items-center justify-center"
    >
    <template #upload-button>
      <div v-if="!props.isReUpload" class="h-full flex  items-center justify-center px-4">
        <svg  width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.25 13H22.75" stroke="#1F1F1F" stroke-width="3" stroke-linecap="round"/>
        <path d="M13 22.75L13 3.25" stroke="#1F1F1F" stroke-width="3" stroke-linecap="round"/>
        </svg>

        <span class="flex-1 text-sm text-text-gray ml-3">{{ $t("enhance-add") }}</span>
        <span class=" text-sm text-text-gray"
              >{{ props.total }} /
              {{ props.maxUploadCount }}</span
            >
      </div>
      <div v-else class="h-full flex  items-center justify-center">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9673 17.8076V4.0418M5.66504 9.27016L11.9673 3.43771L17.8669 9.27016" stroke="#1F1F1F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.30273 17.1025V22.5729H21.6325V17.1025" stroke="#1F1F1F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <span  class="ml-3 text-sm text-text-gray">{{ $t("enhance-reupload") }}</span>
      </div>
    </template>
    </a-upload>
  </div>
</template>
<script setup>
import { getFileSuffix } from "@/utils/index";
import {
  imageAccept,
  maxImageSize,
  maxUploadCount,
  minWidth,
  maxWidth,
} from "@/utils/config";
import messageBox from "@/composables/messageBox";
const props = defineProps({
  total: {
    type: Number,
    default: 0
  },
  currIndex: {
    type: Number,
    default: 0
  },
  acceptCount: {
    type: Number,
    default: 1
  },
  isReUpload: {
    type: Boolean,
    default: false
  },
  maxUploadCount: {
    type: Number,
    default: maxUploadCount
  },
  imageAccept: {
    type: Array,
    default: imageAccept
  }
})
const { t } = useI18n();
const { isVip,isVipPro } = useUserInfo();
const timeout = ref();
const formatFailFiles = ref([]);
const sizeFailFiles = ref([]);
const fileList = ref([]);
const count = ref(0);
const isFirstUpload = ref(false);
const uploadRef = ref();
// const acceptCount = computed(() => {
//   return isVip.value ? maxUploadCount : 1;
// });
const emit = defineEmits(["onSuccess"]);
function onBeforeUpload(file) {
  if (isFirstUpload.value) {
    formatFailFiles.value = [];
    fileList.value = [];
    sizeFailFiles.value = [];
    isFirstUpload.value = false;
    count.value = 0;
  }
  count.value++;
  const suffix = getFileSuffix(file.name).toLowerCase();
  console.log(suffix, file);
  if (props.imageAccept.includes(suffix)) {
    // 检查是否符合图片大小
    if (file.size < maxImageSize) {
      fileList.value.push(file);
    } else {
      sizeFailFiles.value.push(file);
    }
  } else {
    formatFailFiles.value.push(file);
  }

  debounce(() => {
    isFirstUpload.value = true;
    if (fileList.value.length > props.acceptCount) {
      if (isVipPro.value) {
        messageBox.error(t("index-upload"), t("index-upload-limit"));
      } else {
        messageBox.error(
          t("index-upload-batch"),
          t("index-upload-batch-limit")
        );
      }

      return;
    }
    goHandlePage();
  });
  return false;
}

function debounce(fun, time = 500) {
  if (timeout.value) {
    clearTimeout(timeout.value); // 规定时间内若定时器存在则清除
  }
  timeout.value = setTimeout(() => {
    fun(); // 调用接口
  }, time);
}

function goHandlePage() {
  if (formatFailFiles.value.length > 0) {
    messageBox.error(t("index-upload"), t("index-upload-limit-format",{format: props.imageAccept.join('、')}));
    formatFailFiles.value = [];
    fileList.value = [];
    sizeFailFiles.value = [];

    return;
  } else if (sizeFailFiles.value.length > 0) {
    messageBox.error(t("index-upload"), t("index-upload-limit-size"));
    formatFailFiles.value = [];
    fileList.value = [];
    sizeFailFiles.value = [];
    return;
  } else if (fileList.value.length > props.acceptCount) {
    messageBox.error(t("index-upload"), t("index-upload-limit"));
    formatFailFiles.value = [];
    fileList.value = [];
    sizeFailFiles.value = [];
    return;
  }
  let count = 0;
  let hasSizeFail = false;
  fileList.value.map((v) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      let image = new Image();
      image.onload = function () {
        count++;
        // 检查图片尺寸大小
        if (
          image.width < minWidth ||
          image.height > maxWidth ||
          image.width > maxWidth ||
          image.height < minWidth
        ) {
          hasSizeFail = true;
        }
        if (count == fileList.value.length) {
          if (hasSizeFail) {
            messageBox.error(t("index-upload"), t("index-upload-limit-width"));
            return;
          }
          emit("onSuccess", fileList.value);
        }
      };
      image.onerror = function () {
        count++;
        // hasSizeFail = true;

        if (count == fileList.value.length) {
          if (hasSizeFail) {
            messageBox.error(t("index-upload"), t("index-upload-limit-width"));
            return;
          }
          emit("onSuccess", fileList.value);
        }
        // reject(new Error("error"));
      };
      image.src = e.target.result;
    };
    reader.readAsDataURL(v);
  });
}
defineExpose({onUpload})
/**
 * 外部调用上传
 */
function onUpload() {
  const inputElement = uploadRef.value.$el.children[0];
  var clickEvent = new MouseEvent("click", {
    bubbles: false,
    cancelable: true,
  });
  inputElement.dispatchEvent(clickEvent);
}
</script>

<style scoped lang="scss">
</style>
