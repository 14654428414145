// import {useDefaultRequest} from './request'

interface ArticleQuery {
  language: string;
  page: number;
  size: number;
  isRecent?: number;
  isCarousel?: number
}
// 登陆
const login = (params: any) => {
  return useDefaultRequest.post({
    url: "/api/googleIdentity/google_sso",
    params,
  });
};
// 登陆
const loginByEmail = (params: any) => {
  return useDefaultRequest.post({ url: "/api/auth/login", params });
};
// 登出
const logout = () => {
  return useDefaultRequest.delete({ url: "/api/auth/logout" });
};
// 忘记密码
const forgetPwd = (params: any) => {
  return useDefaultRequest.post({ url: "/api/auth/forgetPwd", params });
};
// 校验码
const tmpVerifyCode = (params: any) => {
  return useDefaultRequest.post({ url: "/api/auth/tmpVerifyCode", params });
};
// 注册
const register = (params: any) => {
  return useDefaultRequest.post({ url: "/api/auth/regist", params });
};

// 验证码
const sendCode = (params: any) => {
  return useDefaultRequest.post({ url: "/api/auth/sendCode", params });
};

// 获取用户信息
const getUserInfo = () => {
  return useDefaultRequest.get({ url: "/api/auth/info" });
};

// 获取增强配置
const enhanceCfgList = (type: any) => {
  return useDefaultRequest.get({
    url: "/api/enhanceCfg/list?enhanceType=" + type,
  });
};

// 获取S3临时token
const getS3Token = (type: any) => {
  return useDefaultRequest.post({ url: "/api/s3/token/" + type });
};

// S3签名
const getS3Url = (params: any) => {
  return useDefaultRequest.post({ url: "/api/s3/getS3Url", params });
};

// 创建订单
const createOrder = (params: any) => {
  return useDefaultRequest.post({ url: "/api/pay/create", params });
};

// 查询订单
const queryOrder = (orderNo: any) => {
  return useDefaultRequest.get({ url: "/api/pay/order/" + orderNo });
};

// 图片增强
const enhanceImg = (params: any) => {
  return useDefaultRequest.post({ url: "/api/enhance/img", params });
};

// 查询结果
const getResult = (enhanceOrderId: any) => {
  return useDefaultRequest.get({
    url: "/api/enhance/result/" + enhanceOrderId,
  });
};

// 文章分类
const getArticleTag = (language: string) => {
  const params:ArticleQuery = {
    language,
    page: 0,
    size: 100
  }
  return useDefaultRequest.get({ url: "/api/v1/article/tag/page", params });
};
// const getArticleTag = async (language='en',page=0,size=100) => {
//   return await request.get('/api/v1/article/tag/page?'+'language='+language+'&page='+page+'&size='+size)
// }

// 文章列表
const getArticle = (params: ArticleQuery) => {
  return useDefaultRequest.get({ url: "/api/v1/article/page", params });
};
// 最近文章列表
const getRecentArticle = (params: ArticleQuery) => {
  return useDefaultRequest.get({ url: "/api/v1/article/recent", params });
};
// 轮播图文章列表
const getCarouselArticle = (params: ArticleQuery) => {
  return useDefaultRequest.get({ url: "/api/v1/article/carousel", params });
};
// 文章详情
const getArticleDetail = (urlPath: string, language: string) => {
  return useDefaultRequest.get({
    url: "/api/v1/article/detail/" + urlPath + "?language=" + language,
  });
};

// 联系
const feedbackMsg = (params: any) => {
  return useDefaultRequest.post({ url: "/api/message_feedback/add", params });
};

// 退订
const payCancel = () => {
  return useDefaultRequest.post({ url: "/api/pay/cancel" });
};

// 问卷获取
const getQuestion = (language: string) => {
  return useDefaultRequest.get({
    url: "/api/v1/question_naire/get/" + language,
  });
};

// 问卷获取
const sendQuestion = (params: any) => {
  return useDefaultRequest.post({ url: "/api/v1/question_naire/send", params });
};
// 图片评价
const imgEvaluate = (params: any) => {
  return useDefaultRequest.post({ url: "/api/enhance/evaluate", params });
};
// 减少免费下载次数
const freeDownloadCount = () => {
  return useDefaultRequest.post({ url: "/api/auth/free_download_count/sub" });
};
// 上传头像
const uploadAvatar = (logoUrl: string) => {
  return useDefaultRequest.post({ url: "/api/auth/update",params: {logoUrl} });
};
export const useApi = {
  login,
  loginByEmail,
  logout,
  forgetPwd,
  tmpVerifyCode,
  register,
  sendCode,
  getUserInfo,
  enhanceCfgList,
  getS3Token,
  getS3Url,
  createOrder,
  queryOrder,
  enhanceImg,
  getResult,
  getArticleTag,
  getArticle,
  getRecentArticle,
  getCarouselArticle,
  getArticleDetail,
  feedbackMsg,
  payCancel,
  getQuestion,
  sendQuestion,
  imgEvaluate,
  freeDownloadCount,
  uploadAvatar
};
