<template>
  <header>
    <WebHeader v-if="!mainStore.isMobile" />
    <MobileHeader v-else />
  </header>
</template>
<script setup lang="ts">
import { useMainStore } from "@/store/index";
const mainStore = useMainStore();
const device = useDevice();
onMounted(async () => {
  const token = localStorage.getItem("token");
  if (token) {
    mainStore.updateUserInfo();
  }
  console.log('appHeader');
  
  if (device.isMobile) {
    mainStore.setMobile(true);
  } else {
    if (window.innerWidth < 768) {
      // console.log("移动端");
      if (mainStore.isMobile) return;
      mainStore.setMobile(true);
    }
    window.onresize = () => {
      // console.log(window.innerWidth);
      if (window.innerWidth < 768) {
        if (mainStore.isMobile) return;
        mainStore.setMobile(true)
      } else {
        if (!mainStore.isMobile) return;
        mainStore.setMobile(false)
      }
    };
    
  }
  // 获取屏幕宽度
  const screenWidth = window.screen.width;
  // 获取屏幕高度
  const screenHeight = window.screen.height;
  console.log(`屏幕分辨率为：${screenWidth}x${screenHeight}`);
});

</script>
<style lang="scss" scoped>

</style>