// const app = useNuxtApp()
export function useFuncType() {
  const { locale, t } = useI18n();
  function getFuncType() {
    const FuncType = {
      Enhance_PRO: {
        path: "Enhance_PRO",
        name: t("EnhancePRO"),
      },
      WildFocus_AI: {
        path: "WildFocus_AI",
        name: t("WildFocusAI"),
      },
      Landscape_AI: {
        path: "Landscape_AI",
        name: t("LandscapeAI"),
      },
      Stable_Motion_AI: {
        path: "Stable_Motion_AI",
        name: t("StableMotionAI"),
      },
      AI_Eraser: {
        path: "AI_Eraser",
        name: t("AI-Eraser"),
      },
      Color_Master: {
        path: "Color_Master",
        name: t("ColorMaster"),
      },
      Descratch: {
        path: "Descratch",
        name: t("Descratch"),
      },
      PNG_HD: {
        path: "PNG_HD",
        name: t("PNG_HD"),
      },
      Word_HD: {
        path: "Word_HD",
        name: t("Word_HD"),
      },
    }
    return FuncType
  }

  return { getFuncType };
}
