export const uploadStatus = {
  none: 0, // 未上传
  imageLoadFail: 1, // 图片加载失败
  uploading: 2, // 上传中
  uploadSuccess: 3, // 上传成功
  uploadFail: 4, // 上传失败
  handle: 5, // 增强中
  fail: 6, // 增强失败
  lodingImg: 7, // 加载图片
  success: 8, // 增强成功
};
export enum EnhanceFuncs {
  Enhance,
  WildFocus, //动物毛发
  Landscape, //风景超分
  StableMotion, //运动去模糊
  Eraser, // 涂抹
  PNG_HD, // PNG
  Word_HD, // 涂抹
}
// export const FuncType = {
//   Enhance_PRO: {
//     path: "Enhance_PRO",
//     name: "Enhance PRO",
//   },
//   WildFocus_AI: {
//     path: "WildFocus_AI",
//     name: "WildFocus AI",
//   },
//   Landscape_AI: {
//     path: "Landscape_AI",
//     name: "Landscape AI",
//   },
//   Stable_Motion_AI: {
//     path: "Stable_Motion_AI",
//     name: "Stable Motion AI",
//   },
//   AI_Eraser: {
//     path: "AI_Eraser",
//     name: "AI Eraser",
//   },
//   Color_Master: {
//     path: "Color_Master",
//     name: "Color Master",
//   },
//   Descratch: {
//     path: "Descratch",
//     name: "Descratch",
//   },
// };
export enum MemberType  {
  None,
  Week,
  YearPro,
  WeekPro,
  Year,
  Month,
  MonthPro
}
export const MemberTypePrice = {
  0:0,
  1:6.99,
  2:99.99,
  3:9.99,
  4:69.99,
  5:14.99,
  6:24.99
}
