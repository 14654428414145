<template>
  <div
    class="max-w-full overflow-hidden cursor-pointer relative"
    @mousedown="mousedown"
    @mouseup="mouseup"
    @mouseleave="boxMouseleave"
    @mouseenter="boxMouseenter"
  >
    <ul
      class="h-[300px] w-full flex items-center gap-3 no-scrollbar"
      :style="`transform: translateX(${-translateX + 'px'});`"
    >
      <li
        class="group flex-none h-full rounded-[20px] relative overflow-hidden"
        v-for="(item, index) in scrollImages"
        :key="index"
        :style="{width: item.width+'px'}"
      >
        <NuxtImg
          v-if="item.type === 'image'"
          :src="item.enhance"
          :key="index"
          alt="image"
          class="h-full max-w-full select-none object-cover"
        />
        <CompareImgAuto v-else :origin="item.origin" :enhance="item.enhance" :title="item.name"></CompareImgAuto>
        <div
          v-if="item.type === 'image'"
          class="absolute left-1/2 -translate-x-1/2 w-max bottom-10 bg-[#00000088] rounded-full text-center px-6 h-11 leading-[44px] text-base font-Roboto-Bold font-bold text-white block"
        >
          {{ item.name }}
        </div>
      </li>
    </ul>
    <div
      v-if="device.isMobileOrTablet || isMousedown"
      @mouseenter="mouseenter"
      @mouseleave="mouseleave"
      @mousemove="mousemove"
      @touchmove="touchmove"
      @touchstart="touchstart"
      @touchend="touchend"
      class="absolute top-0 left-0 right-0 bottom-0 opacity-20 z-9"
    ></div>
  </div>
</template>

<script setup>
// import { FuncType } from "~/utils/type";
const { locale, t } = useI18n();
const {getFuncType} = useFuncType()
const FuncType = ref(getFuncType())
const device = useDevice();
const startX = 2550 + 12 * 7;
const endX = (2550 + 12 * 7) * 2;
const isEnter = ref(false);
const translateX = ref(startX);
const isMousedown = ref(false);
const mousedownX = ref(0);
const scrollImagesTemp = ref(getScrollImagesTemp());
// const placeholder =
//   "data:image/webp;base64,UklGRjIBAABXRUJQVlA4ICYBAADQFQCdASqWAJYAPpFCnUolo6Khpnc6sLASCWlu3QpnwF8ekfSPo4cP9ScfPtqRFCYR6O1XYEb219iWp9Y2QxtaBqBc5GoetOUy1fiuGY410iqACIQL8oPsy8ySFyi1YXuoMIxz3Qs4TZ9EMw+doVRuOCc8TRTooMQNx4cwzl4fmlXLH7yxJC4V7jxjls5cgR1mGtEnBRUhjFwPYApzm/FN2vaIYQKDEN7o1azbTeKJwkbKNlGyjMAA/veMAo0BNUOLZRE5AU+l0x75F/4BePn25U2ns5dNlNz9V27vHkpj1i/YHIfOfDNB/x7181P4sFLB0ZVqDSU1zn7ew4MYABCwXaRb9HFIVz0S/TVkQUohA4fCZ8MYNGg7JCApuMr5zmCBs4AAAAA=";
const timer = ref();
const scrollImages = ref([
  ...scrollImagesTemp.value,
  ...scrollImagesTemp.value,
  ...scrollImagesTemp.value
]);
watch(
  () => locale.value,
  () => {
    const scrollImagesTemp = getScrollImagesTemp()
    scrollImages.value = [
      ...scrollImagesTemp,
      ...scrollImagesTemp,
      ...scrollImagesTemp
    ]
  }
)
onMounted(() => {
  timer.value = setInterval(() => {
    if (isEnter.value) {
      return;
    }
    translateX.value += 1;
    if (translateX.value === endX) {
      translateX.value = startX;
    }
  }, 50);
});
onUnmounted(() => {
  clearInterval(timer.value);
});
function getScrollImagesTemp() {
  FuncType.value = getFuncType()
  return [
  {
    name: FuncType.value.Enhance_PRO.name,
    origin: "/assets/images/index/1/Enhance-1.webp",
    enhance: "/assets/images/index/1/Enhance-2.webp",
    width: 200,
  },
  {
    name: FuncType.value.Landscape_AI.name,
    origin: "/assets/images/index/1/Landscape-1.webp",
    enhance: "/assets/images/index/1/Landscape-2.webp",
    width: 300,
  },
  {
    name: FuncType.value.Stable_Motion_AI.name,
    origin: "/assets/images/index/1/Stable-1.webp",
    enhance: "/assets/images/index/1/Stable-2.webp",
    width: 480,
  },
  {
    name: FuncType.value.WildFocus_AI.name,
    origin: "/assets/images/index/1/WildFocus-1.webp",
    enhance: "/assets/images/index/1/WildFocus-2.webp",
    width: 450,
  },
  {
    name: t("header-photo"),
    enhance: "/assets/images/index/1/ai_enhance.webp",
    width: 300,
    type: 'image'
  },
  {
    name: FuncType.value.Descratch.name,
    origin: "/assets/images/index/1/Descratch-1.webp",
    enhance: "/assets/images/index/1/Descratch-2.webp",
    width: 295,
  },
  {
    name: FuncType.value.Color_Master.name,
    origin: "/assets/images/index/1/Color-1.webp",
    enhance: "/assets/images/index/1/Color-2.webp",
    width: 225,
  },
  {
    name: FuncType.value.AI_Eraser.name,
    origin: "/assets/images/index/1/Eraser-1.webp",
    enhance: "/assets/images/index/1/Eraser-2.webp",
    width: 300,
  },
];
}
function mouseenter() {
  console.log("mouseenter");
  isEnter.value = true;
}
function mouseleave() {
  // isEnter.value = false
  // isMousedown.value = false
  // console.log('mouseleave');
}
function mousedown(e) {
  isMousedown.value = true;
  mousedownX.value = e.clientX;
}
function mouseup() {
  isMousedown.value = false;
}
function mousemove(e) {
  if (isMousedown.value) {
    const offset = e.clientX - mousedownX.value;
    mousedownX.value = e.clientX;
    console.log("offset", offset);

    if (offset > 0) {
      translateX.value -= offset;
    } else if (offset < 0) {
      translateX.value -= offset;
    }
    if (translateX.value < startX) {
      translateX.value = endX;
    } else if (translateX.value >= endX) {
      translateX.value = startX;
    }
  }
}
function touchend(e) {
  isEnter.value = false;
}
function touchstart(e) {
  mousedownX.value = e.touches[0].clientX
  isEnter.value = true;

}
function touchmove(e) {
  e.preventDefault();
  const offset = e.touches[0].clientX - mousedownX.value;
    mousedownX.value = e.touches[0].clientX;
    // console.log("offset", offset);

    if (offset > 0) {
      translateX.value -= offset;
    } else if (offset < 0) {
      translateX.value -= offset;
    }
    if (translateX.value < startX) {
      translateX.value = endX;
    } else if (translateX.value >= endX) {
      translateX.value = startX;
    }
}
function boxMouseenter() {
  isEnter.value = true;
}
function boxMouseleave() {
  isEnter.value = false;
  isMousedown.value = false;
}
</script>
