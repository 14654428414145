<template>
  <div class="w-full flex h-full gap-4">
    <div class="flex-1 flex flex-col">
      <div
        class="flex-1 w-full relative overflow-hidden rounded-3xl"
        v-if="imagelist.length"
      >
        <div
          v-if="
            imagelist[currIndex].status == uploadStatus.handle ||
            imagelist[currIndex].status == uploadStatus.lodingImg
          "
          class="w-full h-full flex items-center justify-center bg-no-repeat bg-center bg-contain"
          :style="`background-image: url(${imagelist[currIndex].preImage});`"
        >
          <div
            class="w-full h-full absolute flex items-center justify-center flex-col bg-[#00000088]"
          >
            <Loading v-show="tryCount < 3" />
            <p
              class="mt-10 text-white max-w-[50%] text-center text-xs"
              v-html="$t('enhance-process')"
              v-show="imagelist[currIndex].status == uploadStatus.handle"
            ></p>
            <p
              class="mt-10 text-white max-w-[50%] text-center text-xs"
              v-if="
                imagelist[currIndex].status == uploadStatus.lodingImg &&
                imagelist.length == 1 &&
                tryCount >= 3
              "
            >
              {{ t("loading-err")
              }}<a @click="reloadImg" class="cursor-pointer underline">
                {{$t('reload')}}?</a
              >
            </p>
            <img
              v-if="
                tryFlag && imagelist[currIndex].status == uploadStatus.lodingImg
              "
              ref="preLoadRef"
              style="display: none"
              :src="
                isVip
                  ? imagelist[currIndex].enhanceUrl
                  : imagelist[currIndex].watermark ||
                    imagelist[currIndex].enhanceUrl
              "
              alt="temp"
              @error="enhanceError"
            />
          </div>
        </div>
        <div
          v-else-if="imagelist[currIndex].status == uploadStatus.success"
          class="w-full h-full"
        >
          <EnhanceCompareImg
            ref="compareImgRef"
            :origin="imagelist[currIndex].preImage"
            :enhance="
              isVip
                ? imagelist[currIndex].enhanceUrl
                : imagelist[currIndex].watermark ||
                  imagelist[currIndex].enhanceUrl
            "
            :minScale="minScale"
            :maxScale="maxScale"
            @scaleChange="CompareImgScaleChange"
          />
        </div>
        <EnhanceImageView
          ref="imageViewRef"
          v-else
          :minScale="minScale"
          :maxScale="maxScale"
          :url="imagelist[currIndex].preImage"
          @scaleChange="imageViewChange"
        ></EnhanceImageView>
      </div>
      <div v-else class="flex-1  rounded-3xl flex items-center justify-center">
        <div class="w-4/5">
          <FuncTypeUpload class="h-[280px] w-full z-[2]" :acceptCount="acceptCount" @onSuccess="getFileList" :title="$t('enhance-upload')" :imageAccept="imageAcceptList"></FuncTypeUpload>
        </div>
      </div>
      <div v-if="imagelist.length" class="h-[88px] w-full flex items-center px-5 relative mt-6">
        <div
          class="w-[68px] hidden lg:block"
          v-show="
            imagelist[currIndex].enhanceUrl && !imagelist[currIndex].isEvaluate
          "
        ></div>
        <EnhanceBaseControls
          class="flex-1 flex justify-center"
          ref="baseControlsRef"
          :minScale="minScale"
          :maxScale="maxScale"
          @change="baseCtlScaleChange"
          @changeMove="baseCtlChangeMove"
        ></EnhanceBaseControls>
        <EnhanceGiveALike
          v-if="isLogin"
          v-show="
            imagelist[currIndex].enhanceUrl && !imagelist[currIndex].isEvaluate
          "
          :id="imagelist[currIndex].enhanceRecordId"
          @onEvaluate="onEvaluate"
        ></EnhanceGiveALike>
        <div
          class="absolute left-0 top-0 bottom-0 right-0"
          v-if="
            !imagelist.length ||
            (imagelist.length &&
              !(
                imagelist[currIndex].status === uploadStatus.success ||
                imagelist[currIndex].status === uploadStatus.none ||
                imagelist[currIndex].status === uploadStatus.fail ||
                imagelist[currIndex].status === uploadStatus.uploadFail
              ))
          "
        ></div>
      </div>
    </div>
    <div class="w-[287px] flex flex-col">
      <div class="flex-1 bg-white rounded-[20px] py-[30px]">
        <div
          class="overflow-y-auto"
          style="max-height: calc(100vh - 100px - 100px - 88px - 24px - 60px)"
          id="image-box"
        >
          <div
            class="w-[225px] h-[60px] rounded-2xl flex flex-col items-center mb-4 mx-auto relative"
            v-show="!!resultId && imagelist.length>0"
            >
            <EnhanceUpload
              key="reUpload"
              ref="reUploadRef"
              @onSuccess="reloadFileList"
              :total="imagelist.length"
              :currIndex="currIndex"
              :acceptCount="acceptCount"
              :isReUpload="!!resultId"
              :imageAccept="imageAcceptList"
            />
            <div
              class="absolute left-0 top-0 bottom-0 right-0 cursor-not-allowed"
              v-show="queryResultTimeout!==null"
            ></div>
          </div>
          <EnhanceImgItem
            v-for="(item, index) in imagelist"
            :key="item.id"
            :imgInfo="item"
            :isSelect="currIndex === index"
            @click="selectImg(index)"
            @delImg="() => delImg(index)"
            @downloadImg="() => onDownloadOne(index)"
            :isFinish="queryResultTimeout===null"
            :funcIndex="props.funcIndex"
          ></EnhanceImgItem>
          <div
            class="w-[225px] h-[60px] rounded-2xl flex flex-col items-center mb-4 mx-auto relative"
            v-show="!(isVipPro && acceptCount === imagelist.length)"
            >
            <EnhanceUpload
              key="upload"
              @onSuccess="getFileList"
              :total="imagelist.length"
              :currIndex="currIndex"
              :acceptCount="acceptCount"
              :isReUpload="false"
              :imageAccept="imageAcceptList"
            />
            
            <div
              class="absolute left-0 top-0 bottom-0 right-0 cursor-pointer"
              v-show="acceptCount === imagelist.length"
              @click="onBatchUpolad"
            ></div>
            <div
              class="absolute left-0 top-0 bottom-0 right-0 cursor-not-allowed"
              v-show="queryResultTimeout!==null"
            ></div>
          </div>
        </div>
      </div>
      <div class="h-6"></div>
      <EnhanceDownload
        :downloadEnable="isAllDownload"
        :resultId="resultId"
        :imagelist="imagelist"
        multiple
        :funcIndex="props.funcIndex"
      ></EnhanceDownload>
    </div>
    <!-- <a-modal
      v-model:visible="reTryVisible"
      @ok="onTryHandle"
      @cancel="handleCancel"
      :mask-closable="false"
      hide-title
    >
      <template #title> </template>
      <div>部分增强失败，是否重试失败的图片？</div>
    </a-modal> -->
    <BaseModal
      :title="$t('modal-retry-title')"
      :content="$t('modal-retry-text')"
      :visible="reTryVisible"
      @close="handleCancel"
    >
      <div>
        <span
          class="bg-text-deep inline-block h-10 leading-10 px-10 cursor-pointer rounded-full text-white mr-3"
          @click="handleCancel"
          >{{ $t('delete') }}</span
        >
        <span
          class="bg-mainColor inline-block h-10 leading-10 px-10 cursor-pointer rounded-full text-white"
          @click="onTryHandle"
          >{{ $t('modal-retry-ok') }}</span
        >
      </div>
    </BaseModal>
    <BaseModal
      :title="$t('modal-size-limit-title')"
      :content="$t('modal-size-limit-text',{maxWidth})"
      :visible="sizeLimitVisible"
      @close="sizeLimitCancel"
    >
      <div>
        <span
          class="bg-text-deep inline-block h-10 leading-10 px-10 cursor-pointer rounded-full text-white mr-3"
          @click="sizeLimitCancel"
          >{{ $t('cancel') }}</span
        >
        <span
          class="bg-mainColor inline-block h-10 leading-10 px-10 cursor-pointer rounded-full text-white"
          @click="sizeLimitOk"
          >{{ $t('modal-size-limit-ok') }}</span
        >
      </div>
    </BaseModal>
  </div>
</template>
<script setup>
import Compressor from "compressorjs";
import { maxUploadCount, privateKey,minWidth,maxWidth,maxImageSize,imageAccept } from "@/utils/config";
import { uploadStatus, EnhanceFuncs } from "@/utils/type";
import { drawWaterMark } from "@/utils/index";
import { useMainStore } from "@/store/index";
import { useUploadStore } from "@/store/upload";
import EXIF from "@/assets/js/exif.js";
import messageBox from "@/composables/messageBox";
const fullLoading = useFullLoading();
const { locale, t } = useI18n();
const mainStore = useMainStore();
const uploadStore = useUploadStore();
const dayjs = useDayjs();
const { $encryptor } = useNuxtApp();
//   const link = useSeoLangLink();
const props = defineProps({
  funcIndex: {
    type: Number,
    default: EnhanceFuncs.Enhance,
  },
  configs: {
    type: Array,
    default: [],
  },
});
const {
  public: { MODE },
} = useRuntimeConfig();
const { isLogin, isVip, isVipPro } = useUserInfo();
const emit = defineEmits("applyEnabled");
// const url =
//   "https://t10.baidu.com/it/app=25&f=JPEG&fm=175&fmt=auto&u=279358749%2C1786789764?w=640&h=360&s=B98E9F5056B9502541A9BD41030060F3";
//所有照片列表
const imagelist = ref([
]);
const currIndex = ref(0);
const totalCount = ref(0);
const currUploadIdx = ref(0);
const isAllDownloading = ref(false);
const imageMap = ref({});
// const downActived = ref(false);
const jsTryCount = ref(false);
// const questionVisible = ref(false);
// 只要有一张处理好，用于判断是否弹窗调查问卷
const hasFirstResult = ref(false);
const clickDownloadIdx = ref(-1);
const compareImgRef = ref();
const reUploadRef = ref(null);
const timeout2 = ref();
// const isReUpload = ref(false);
const queryResultTimeout = ref(null);
const fileNameList = ref([]);
const tryCount = ref(0);
const tryFlag = ref(true);
const resultId = ref(null);
const minScale = 50;
const maxScale = 250;
const baseControlsRef = ref(null);
const imageViewRef = ref(null);
const smearRef = ref(null);
const reTryVisible = ref(false);
const uploadCount = ref(0);
const sizeLimitVisible = ref(false)
const preLoadRef = ref()
const applyEnabled = computed(() => {
  if (props.funcIndex === EnhanceFuncs.Enhance) {
    return (
      imagelist.value.length > 0 &&
      // imagelist.value.length == totalCount.value &&
      props.configs.some((v) => v != "") &&
      !queryResultTimeout.value
    );
  }
  return (
    imagelist.value.length > 0 && !queryResultTimeout.value
  );
});
defineExpose({ onApply, initData, imagelist,reUploadRef });
const isAllDownload = computed(() => {
  const failLen = imagelist.value.filter(
    (v) => v.status == uploadStatus.fail || v.status == uploadStatus.uploadFail
  ).length;
  // console.log("failLen", failLen, imagelist.value.length);
  // 如果都处理好了，无论成功还是失败，并且失败的张数不能超过总张数
  return (
    imagelist.value.length > 0 &&
    failLen < imagelist.value.length &&
    imagelist.value.every((v) => v.status > uploadStatus.handle || v.status == uploadStatus.uploadFail) &&
    !isAllDownloading.value
  );
});
// // 检查是否都处理好
// const isFinish = computed(() => {
//   return (
//     imagelist.value.every((v) => v.status === uploadStatus.none) ||
//     imagelist.value.every(
//       (v) =>
//         v.status > uploadStatus.handle || v.status === uploadStatus.uploadFail
//     )
//   );
// });
const acceptCount = computed(() => {
  return isVipPro.value
    ? maxUploadCount
    : 1;
});
watch(
  () => applyEnabled.value,
  () => {
    emit("applyEnabled", applyEnabled.value);
  }
);
const imageAcceptList = computed(() => {
  return props.funcIndex === EnhanceFuncs.PNG_HD?['png']: imageAccept;
});
// watch(
//   () => isFinish.value,
//   () => {
//     emit("applyEnabled", isFinish.value);
//   }
// );
onMounted(async () => {
  // await nextTick()
  console.log("images", uploadStore.images);
  const images = [...uploadStore.images];
  uploadStore.setImages([]);
  if (images.length > 0) {
    initFileList(images);
  }
  // $encryptor?.setPrivateKey(privateKey);

  window.addEventListener("enhanceVipEvent", enhanceVipEvent);
  window.addEventListener("logoutEvent", logoutEvent);
  // if (!isLogin.value) {
  window.addEventListener("visitorLoginEvent", visitorLoginEvent);
  // }
  if (MODE == "pro") {
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
  }
});
onUnmounted(() => {
  uploadStore.setImages([]);
  window.removeEventListener("enhanceVipEvent", enhanceVipEvent);
  window.removeEventListener("visitorLoginEvent", visitorLoginEvent);
  window.removeEventListener("logoutEvent", logoutEvent);
  clearTimeout(queryResultTimeout.value);
  clearTimeout(timeout2.value);
});
function initData() {
  clearTimeout(queryResultTimeout.value);
  clearTimeout(timeout2.value);
  queryResultTimeout.value = null
  imagelist.value = [];
  currIndex.value = 0;
  // configs.value = [6, "", ""];
  // isApply.value = false;
  currUploadIdx.value = 0;
  imageMap.value = {};
  // totalCount.value = 0;
  // downActived.value = false;
  tryCount.value = 0;
  resultId.value = null;
  // isReUpload.value = false;
  if (baseControlsRef.value) {
    baseControlsRef.value.setScale(100);
  }
}
function logoutEvent() {
  // clearTimeout(queryResultTimeout.value);
  // clearTimeout(timeout2.value);
  initData();
}
function getFileList(fileList) {
    getNewFileList(fileList);
}
function reloadFileList(fileList) {
  initFileList(fileList);
}
function initFileList(fileList) {
  initData();
  getNewFileList(fileList);
}

function getNewFileList(fileList) {
  clearTimeout(queryResultTimeout.value);
  hasFirstResult.value = false;
  if(imagelist.value.length === 0){
    resultId.value = null
  }
  if (fileList.length + imagelist.value.length > acceptCount.value) {
    if (isVipPro.value) {
      messageBox.error(t("index-upload"), t("index-upload-limit"));
    } else {
      messageBox.error(t("index-upload-batch"), t("index-upload-batch-limit"));
    }
    return;
  }
  currIndex.value = 0;

  // totalCount.value += fileList.length;
  setTimeout(() => {
    // 跳转到底部
    document.getElementById("image-box").scroll({
      top: 10000,
      behavior: "smooth",
    });
  }, 1000);
  fileList.map((v) => {
    let orientation = 1;
    EXIF.getData(v, function () {
      orientation = EXIF.getTag(this, "Orientation") || 1;

      console.log("orientation", orientation);
    });
    var reader = new FileReader();
    reader.onload = function (e) {
      let image = new Image();
      image.onload = async function () {
        // 处理部分照片旋转问题
        let imgWidth = image.width;
        let imgHeight = image.height;
        console.log("图片加载成功:%d * %d", imgWidth, imgHeight);
        imagelist.value.push({
          status: uploadStatus.none,
          file: v,
          localUrl: e.target.result,
          preImage: e.target.result,
          width: imgWidth,
          height: imgHeight,
          id: Math.random(),
          isDownloading: false,
          enhanceUrl: "",
          originUrl: "",
          orientation,
          isEvaluate: false,
          enhanceRecordId: "",
        });
      };
      image.onerror = function () {
        console.log("图片加载异常");
        // reject(new Error("error"));
        imagelist.value.push({
          status: uploadStatus.imageLoadFail,
          file: v,
          localUrl: e.target.result,
          preImage: e.target.result,
          width: 0,
          height: 0,
          id: Math.random(),
          isDownloading: false,
          enhanceUrl: "",
          originUrl: "",
          orientation,
          isEvaluate: false,
          enhanceRecordId: "",
        });
      };
      image.src = e.target.result;
    };
    reader.readAsDataURL(v);
  });
}
function selectImg(index) {
  console.log(imagelist.value);

  baseControlsRef.value.setScale(100);
  if (imageViewRef.value) {
    imageViewRef.value.setScale(100);
  }
  if (compareImgRef.value) {
    compareImgRef.value.setScale(100);
  }
  if(index === currIndex.value){
    return
  }
  if(preLoadRef.value) {
    preLoadRef.value.removeEventListener('load',enhanceLoad)
  }
  
  currIndex.value = index;
  setTimeout(()=>{
    if(preLoadRef.value) {
      preLoadRef.value.addEventListener('load',enhanceLoad)
    }
  })
  // downActived.value = false;
}
function delImg(index) {
  // let totalLen = imagelist.value.length;
  imagelist.value.splice(index, 1);
  // if (index == 0) {
  //   currIndex.value = 0;
  // } else if (index + 1 === totalLen && currIndex.value == index) {
    
  //   currIndex.value = 0;
  // }else {
  //   currIndex.value = 0;
  // }
  currIndex.value = 0;
  // totalCount.value--;
  // 重置相关索引坐标
  Object.keys(imageMap.value).map(v => {
    console.log(v,imageMap.value[v]);
    if(imageMap.value[v]>index){
      imageMap.value[v]--; 
    }else if(imageMap.value[v]==index){
      delete imageMap.value[v]
    }
  })
  console.log(imageMap.value);
  if(preLoadRef.value) {
    preLoadRef.value.removeEventListener('load',enhanceLoad)
  }
  setTimeout(()=>{
    if(preLoadRef.value) {
      preLoadRef.value.addEventListener('load',enhanceLoad)
    }
  })
  if(imagelist.value.length === 0){
    resultId.value = null
  }
}

function onApply() {
  if (!applyEnabled.value) {
    return;
  }
  if((props.funcIndex === EnhanceFuncs.PNG_HD || props.funcIndex === EnhanceFuncs.Word_HD) && !isVipPro.value){
    messageBox.error(null, t("index-upload-novip-limit"));
    return;
  }
  if(imagelist.value.some(v => v.tarWidth > maxWidth || v.tarHeight > maxWidth) ){
    sizeLimitVisible.value = true
    return
  }
  if (imagelist.value.length === 1) {
    fullLoading.show(
      `${t("enhance-upload-loading")}(0%)`,
      t("enhance-process")
    );
  } else {
    fullLoading.show(
      `${t("enhance-upload-loading")}(0/${imagelist.value.length})`,
      t("enhance-process")
    );
  }
  uploadCount.value = 0;
  // if (resultId.value) {
  //   continueHandle();
  // } else {
    if (isLogin.value) {
      useApi.getQuestion(locale.value).then((res) => {
        if (res.code === 0) {
          // question.value = res.data;
          uploadStore.setQuestion(res.data);
        }
      });
    }
    // fullLoading.show(t("enhance-upload-loading"), t("enhance-process"));
    imagelist.value = imagelist.value.filter(
    (v) => v.status >= uploadStatus.lodingImg || v.status == uploadStatus.uploadFail || v.status == uploadStatus.none || v.status == uploadStatus.uploadSuccess
    ).map((v,i) => {
        // 处理成功的，重新处理
        if(v.status >= uploadStatus.lodingImg){
          const arr = v.originUrl.split("/");
          const idx = arr[arr.length - 1];
          console.log('idx:',idx);
          delete imageMap.value[idx]
          const arr2 = v.enhanceUrl.split("/");
          let idx2 = arr2[arr2.length - 1];
          if (idx2.includes("@1m")) {
            idx2 = idx2.substring(0, idx2.length - 3);
          }
          imageMap.value[idx2] = i;

          v.originUrl = v.enhanceUrl
          v.width = v.tarWidth
          v.height = v.tarHeight
          v.enhanceUrl = ''
          v.isEvaluate = false,
          v.enhanceRecordId = ''
          v.status = uploadStatus.uploadSuccess
          v.tarWidth = 0
          v.tarHeight = 0
        }
        return v;
      });
    // 如果有存在未上传的，则重新开始上传，否则都上传了，则直接进行处理
    if (imagelist.value.some((v) => !v.originUrl)) {
      imagelist.value = imagelist.value.map((v) => {
        // 失败的重新上传
        if (v.status == uploadStatus.uploadFail) {
          v.status = uploadStatus.none;
        }
        return v;
      });
      currUploadIdx.value = 0
      startUpload();
    } else {
      startEnhance();
    }
  // }
}
function startEnhance() {
  let resourceUrls = [];
  imagelist.value.map((v) => {
    if (v.originUrl) {
      let originUrl = v.originUrl;
      if (originUrl.includes("@1m")) {
        originUrl = originUrl.substring(0, originUrl.length - 3);
      }
      resourceUrls.push(originUrl)
    }
  });
  enhanceImgAPI(resourceUrls, () => {
    // 上传成功的都标记为增强中
    imagelist.value.map((v) => {
      if (v.status == uploadStatus.uploadSuccess) {
        v.status = uploadStatus.handle;
      }
    });
  });
}
function resultError() {
  //如果超时了，将所有没有结果的都置失败
    imagelist.value.map((v) => {
      if (v.status == uploadStatus.handle) {
        v.status = uploadStatus.fail;
      }
    });
    // 查询失败的数量，如果失败的等于图片总的数量，则禁止继续应用
    // const failLen = imagelist.value.filter(
    //   (v) =>
    //     v.status == uploadStatus.fail || v.status == uploadStatus.uploadFail
    // ).length;
    // if (failLen === imagelist.value.length) {
    //   emit("applyEnabled", false);
    // }
    if (imagelist.value.some((v) => v.status === uploadStatus.fail)) {
      reTryVisible.value = true;
    }
    queryResultTimeout.value = null
}
/**
 * 查询增强结果
 * @param {*} id 
 * @param {*} count 
 */
function getImageListResult(id, count) {
  if (count > 200) {
    messageBox.error(t("network-err"), t("network-err-try"));
    resultError()
    
    return;
  }
  useApi
    .getResult(id)
    .then((res) => {
      if (res.code == 0) {
        console.log("res", res);
        const { imgList } = res.data;
        let isAllHandle = true;

        imgList.map(async (v) => {
          const arr = v.oriResourceUrl.split("/");
          const idx = arr[arr.length - 1];
          const imginfo = imagelist.value[imageMap.value[idx]]
          // console.log('idx',idx);
          if(!imginfo){
            console.log('未匹配到',idx,imageMap.value);
            return
          }
          //增强失败
          if (v.errorCode) {
            imginfo.status = uploadStatus.fail;
          } else if (
            (v.enhanceUrl && v.oriHeight) ||
            (v.enhanceUrl && v.tarHeight)
          ) {
            if (
              imginfo.status == uploadStatus.lodingImg ||
                imginfo.status == uploadStatus.success
            ) {
              return;
            }
            // console.log('有结果:',imageMap.value[idx],imginfo);
            if (imginfo.enhanceUrl) {
              imginfo.preImage = imginfo.enhanceUrl;
            }

            checkoutIosImageOrientation(v, idx);
            // console.log('有结果2:',imageMap.value[idx]);
            imginfo.status = uploadStatus.lodingImg;
              imginfo.enhanceRecordId = v.enhanceRecordId;
              imginfo.isEvaluate = false;
              if(currIndex.value === imageMap.value[idx]){
                setTimeout(()=>{
                  if(preLoadRef.value) {
                    preLoadRef.value.removeEventListener('load',enhanceLoad)
                    preLoadRef.value.addEventListener('load',enhanceLoad)
                  }
                },10)
                
              }
          } else {
            isAllHandle = false;
          }
        });
        if (!isAllHandle) {
          clearTimeout(queryResultTimeout.value);
          queryResultTimeout.value = setTimeout(() => {
            clearTimeout(queryResultTimeout.value);
            getImageListResult(id, count + 1);
          }, 3000);
        } else {
          //处理好，检查本地使用了多少次，1和5次弹出问卷调查,如果同一个问题提交过了，就不弹
          if (isLogin.value && !hasFirstResult.value && uploadStore.question) {
            hasFirstResult.value = true;
            setTimeout(() => {
              const key = "question-" + mainStore.userInfo.userId;
              let questionStr = localStorage.getItem(key);
              const first = {
                id: uploadStore.question.autoId,
                count: 1,
                isSubmit: 0,
              };
              if (questionStr) {
                let d = JSON.parse(questionStr);
                try {
                  if (d.id == uploadStore.question.autoId) {
                    d.count++;
                    if (d.count == 5 && d.isSubmit == 0) {
                      // questionVisible.value = true;
                      uploadStore.setQuestionVisible(true);
                    }
                    localStorage.setItem(key, JSON.stringify(d));
                    return;
                  }
                } catch (e) {
                  localStorage.setItem(key, JSON.stringify(first));
                  // questionVisible.value = true;
                  uploadStore.setQuestionVisible(true);
                }
              }

              localStorage.setItem(key, JSON.stringify(first));
              // questionVisible.value = true;
              uploadStore.setQuestionVisible(true);
            }, 6000);
          }
          // isReUpload.value = true;
          // 存在部分处理失败，弹窗提示是否重新增强失败的
          if (imagelist.value.some((v) => v.status === uploadStatus.fail)) {
            reTryVisible.value = true;
          }
          queryResultTimeout.value = null
        }

        //如果都处理好了，则关闭查询
      } else {
        clearTimeout(queryResultTimeout.value);
        queryResultTimeout.value = null
        imagelist.value.map((v) => {
          if (v.status == uploadStatus.handle) {
            v.status = uploadStatus.fail;
          }
        });
      }
    })
    .catch((err) => {
      console.log("err", err);
      resultError()
    });
}

function baseCtlScaleChange(scale) {
  if (imagelist.value[currIndex.value].status == uploadStatus.success) {
    compareImgRef.value.setScale(scale);
  } else {
    if (imageViewRef.value) {
      imageViewRef.value.setScale(scale);
    }
  }
}
function baseCtlChangeMove(isMove) {
  smearRef.value.setMove(scale);
}

function CompareImgScaleChange(scale) {
  baseControlsRef.value.setScale(scale);
}
function imageViewChange(scale) {
  baseControlsRef.value.setScale(scale);
}

function onDownloadOne(index) {
  // const index = currIndex.value;
  if (
    imagelist.value[index].status <= uploadStatus.lodingImg ||
    imagelist.value[index].isDownloading
  ) {
    return;
  }
  if (!isLogin.value) {
    uploadStore.setSignUpVisible(true);
    return;
  }
  const isFree = isVip.value || mainStore.userInfo.freeDownloadCount > 0;
  if (!isFree) {
    clickDownloadIdx.value = index;
    mainStore.setVipFrom("download_one");
    mainStore.setVisibleVipDialog(true);
    return;
  }
  console.log("下载");
  useLogEvent({ eventName: "enhance-download-one" });
  useFBLogEvent({ eventName: "enhance-download-one" });
  imagelist.value[index].isDownloading = true;
  let downloadUrl = imagelist.value[index].enhanceUrl;
  if (!isVip.value) {
    downloadUrl = imagelist.value[index].watermark;
    if (!downloadUrl) {
      downloadUrl = imagelist.value[index].enhanceUrl;
    }
  }
  fetch(downloadUrl, {
    mode: "cors",
  })
    .then(async (res) => {
      const e = await res.blob();
      return e;
    })
    .then(async (blob) => {
      let url = URL.createObjectURL(blob);
      if (!isVip.value && !imagelist.value[index].watermark) {
        console.log("尝试下载图片转水印");
        // 没有水印
        try {
          const imageInfo = await drawWaterMark(url);
          if (imageInfo.base64) {
            url = imageInfo.base64;
            imagelist.value[index].watermark = imageInfo.base64;
          }
        } catch (e) {
          console.log("水印转换失败");
        }
      }
      if (mainStore.userInfo.freeDownloadCount > 0) {
        useApi.freeDownloadCount().then((result) => {
          if (result.code == 0) {
            mainStore.updateUserInfo();
          }
        });
      }

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      const t = dayjs().format("YYYYMMDDHHmmss");
      a.download = "Picma_" + t + ".jpg";
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(a.href);
      imagelist.value[index].isDownloading = false;
      // downActived.value = false;
      setTimeout(() => {
        a.remove();
      }, 2000);
    })
    .catch((e) => {
      messageBox.error(t("network-err"), t("network-err-try"));
      imagelist.value[index].isDownloading = false;
    });
}

function getS3Token(callback) {
  if (Object.keys(uploadStore.s3Info).length) {
    callback();
    return;
  }
  useApi
    .getS3Token(2)
    .then((result) => {
      console.log("s3", result);
      if (result.code == 0) {
        uploadStore.setS3Info({
          ...result.data,
          time: new Date().getTime(),
        });
        callback();
      } else {
        fullLoading.hide();
      }
    })
    .catch((e) => {
      console.error("getS3Token error");
      messageBox.error(t("network-err"), t("network-err-try"));
      fullLoading.hide();
    });
}
function startUpload() {
  getS3Token(() => {
    getS3UrlHandle(getFileNames(), 0, (names) => {
      console.log("names", names);
      const urls = imagelist.value.filter(v => v.originUrl != '')
      currUploadIdx.value = urls.length
      console.log("已上传%d,从%d开始上传", urls.length,currUploadIdx.value);
      console.log(imagelist.value);
      handleFile(imagelist.value[currUploadIdx.value].file, fileNameList.value[currUploadIdx.value]);
    });
  });
}
function getS3UrlHandle(fileNames, count, callback) {
  console.log("getS3UrlHandle");
  if (count >= 2) {
    console.log("getS3UrlHandle超过3次");
    messageBox.error(t("network-err"), t("network-err-try"));
    fullLoading.hide();
    return;
  }
  useApi
    .getS3Url({ fileNames, enhanceType: 2 })
    .then((result) => {
      if (result.code == 0) {
        const urls = imagelist.value.filter(v => v.originUrl != '')
        fileNameList.value = [...urls.map(v=>v.originUrl),...result.data];
        // handleFile(imagelist.value[0].file, fileNameList.value[0]);
        callback(result.data);
      } else {
        // this.images = []
        // 获取token异常  待处理
      }
    })
    .catch((err) => {
      // 获取token异常  待处理
      console.log("getS3Url 上传异常", err);
      getS3UrlHandle(fileNames, count + 1, callback);
    });
}
function handleFile(file, s3Name) {
  compressorImg(file, s3Name);
}
function compressorImg(file, s3Name) {
  if (file.size < 5 * 1024 * 1024) {
    upS3Token(file, s3Name);
  } else {
    console.log("压缩前:", file.size);
    new Compressor(file, {
      quality: 0.8,
      convertSize: 5 * 1024 * 1024,
      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        console.log("压缩后文件:", result);
        console.log("压缩后:", result.size);
        upS3Token(result, s3Name);
      },
      error(err) {
        console.log(err.message);
        // 压缩失败则原图进行上传
        upS3Token(file, s3Name);
      },
    });
  }
}
function getFileNames() {
  let fileNames = [];
  imagelist.value.map((v, i) => {
    if (v.status == uploadStatus.none) {
      let name = v.file.name;
      let tmp = name.replaceAll(":", "");
      fileNames.push(tmp);
    }
  });
  return fileNames;
}
// 上传到s3
function upS3Token(file, s3Name) {
  const s3Info = uploadStore.s3Info;
  // 设置上传AWS的参数
  const params = {
    Bucket: s3Info["bucketName"],
    Key: s3Name, // 对上传远程文件的添加时间戳， urlKey为远程地址
    ContentType: file.type,
    "Access-Control-Allow-Credentials": "*",
    Body: file,
    // "ACL": "public-read",
  };
  console.log("upS3Token", s3Info, params);
  // const AWS = require('aws-sdk');
  if (typeof AWS === "undefined") {
    messageBox.error(t("index-upload"), t("index-upload-load-fail"));
    retryLoad();
    return;
  }
  AWS.config.update({
    region: s3Info["region"],
    // apiVersion: '2006-03-01',
    sessionToken: s3Info["sessionToken"], // 从后台获取数据
    accessKeyId: s3Info["accessKeyId"], // 从后台获取数据
    secretAccessKey: s3Info["secretAccessKey"], //
  });
  const s3 = new AWS.S3();
  // 设置上传配置和获取进去进度条数据
  const s3Upload = s3
    .upload(params, function (err, data) {
      console.log("照片上传", err, data);
      if (err == null) {
        data.success = true;
        resolve(data);
      } else {
        data.success = false;
        data.message = err;
        resolve(data);
      }
    })
    .on("httpUploadProgress", function (progress) {
      let per = parseInt(progress.loaded, 10) / parseInt(progress.total, 10);
      const percent = Number((per * 100).toFixed(2));
      console.log("进度:", percent);
      if (imagelist.value.length === 1) {
        fullLoading.updateText({
          title: `${t("enhance-upload-loading")}(${percent}%)`,
        });
      } else {
        // if (percent == 100) {
        //   uploadCount.value++;
        //   fullLoading.updateText({
        //     title: `${t("enhance-upload-loading")}(${uploadCount.value}/${
        //       imagelist.value.length
        //     })`,
        //   });
        // }
      }
    });
  s3Upload.send((err, data) => {
    if (err) {
      console.log("上传错误", err);
      if (err.code == "ExpiredToken") {
        console.log(" token过期");
        uploadStore.setS3Info({});
        getS3Token(() => {
          upS3Token(file, s3Name);
        });
        return;
      } else if (err.code == "NetworkingError") {
        messageBox.error(t("network-err"), t("network-err-try"));
      }
      imagelist.value[currUploadIdx.value].status = uploadStatus.uploadFail;
      currUploadIdx.value++;
      // console.log(
      //   "currUploadIdx:",
      //   currUploadIdx.value,
      //   imagelist.value.length
      // );
      if (currUploadIdx.value == imagelist.value.length) {
        console.log("开始处理");
        currUploadIdx.value = 0;
        startEnhance();
      } else {
        console.log("还有待上传");
        handleFile(
          imagelist.value[currUploadIdx.value].file,
          fileNameList.value[currUploadIdx.value]
        );
      }
    } else {
      //   console.log(data.Location); // 获取上传视频的远程连接
      uploadCount.value++;
      fullLoading.updateText({
        title: `${t("enhance-upload-loading")}(${uploadCount.value}/${
          imagelist.value.length
        })`,
      });
      console.log("上传成功:",currUploadIdx.value);
      imagelist.value[currUploadIdx.value].status = uploadStatus.uploadSuccess;
      imagelist.value[currUploadIdx.value].originUrl = data.Location;
      const arr = data.Location.split("/");
      const id = arr[arr.length - 1];
      imageMap.value[id] = currUploadIdx.value;
      currUploadIdx.value++;
      if (currUploadIdx.value == imagelist.value.length) {
        startEnhance();
      } else {
        handleFile(
          imagelist.value[currUploadIdx.value].file,
          fileNameList.value[currUploadIdx.value]
        );
      }
    }
  });
}
function onLimitUpload() {
  mainStore.setVipFrom("enhance_add");
  mainStore.setVisibleVipDialog(true);
}

async function checkoutIosImageOrientation(result) {
  let enhanceUrl = decryptUrl(result.enhanceUrl);
  // let enhanceUrl = result.enhanceUrl;
  const arr = result.oriResourceUrl.split("/");
  const idx = arr[arr.length - 1];
  let originIdx = imageMap.value[idx];
  // let originIdx = idx;
  // debugger
  // EXIF.getData(imagelist.value[originIdx].file, function () {
  //   orientation = EXIF.getTag(this, "Orientation");
  //   console.log("orientation2222", orientation);
  // });
  let orientation = imagelist.value[originIdx].orientation;
  console.log("orientation:%d,originIdx:%d", orientation,originIdx);
  // 需要进行旋转
  if (orientation && orientation != 1) {
    let image = new Image();
    image.src = enhanceUrl;
    image.setAttribute("crossOrigin", "Anonymous");
    image.onload = async function () {
      // 处理部分照片旋转问题
      let imgWidth = image.width;
      let imgHeight = image.height;
      let orientationUrl = "";
      // if (orientation && orientation != 1) {
      // 判断图片是否需要旋转，如果需要旋转则进行旋转处理
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      canvas.width = imgWidth;
      canvas.height = imgHeight;
      console.log("需要旋转", orientation);
      switch (orientation) {
        case 6: // 旋转90度
          canvas.width = imgHeight;
          canvas.height = imgWidth;

          ctx.rotate(Math.PI / 2);
          // // (0,-imgHeight) 从旋转原理图那里获得的起始点
          ctx.drawImage(this, 0, -imgHeight, imgWidth, imgHeight);
          // ctx.rotate((3 * Math.PI) / 2);
          // ctx.drawImage(this, -imgWidth, 0, imgWidth, imgHeight);
          break;
        case 3: // 旋转180度
          ctx.rotate(Math.PI);
          ctx.drawImage(this, -imgWidth, -imgHeight, imgWidth, imgHeight);
          break;
        case 8: // 旋转-90度
          canvas.width = imgHeight;
          canvas.height = imgWidth;
          ctx.rotate((3 * Math.PI) / 2);
          // ctx.rotate(Math.PI / 2);
          ctx.drawImage(this, -imgWidth, 0, imgWidth, imgHeight);
          break;
      }
      orientationUrl = canvas.toDataURL("image/jpeg");
      imgHeight = image.width;
      imgWidth = image.height;
      imagelist.value[originIdx].tarHeight = imgHeight;
      imagelist.value[originIdx].tarWidth = imgWidth;
      // return;
      // }
      if (isVip.value) {
        console.log("会员不加水印");
        imagelist.value[originIdx].enhanceUrl = orientationUrl;
        return;
      }
      try {
        const imageInfo = await drawWaterMark(orientationUrl);
        console.log("添加水印");
        if (imageInfo.base64) {
          imagelist.value[originIdx].watermark = imageInfo.base64;
          imagelist.value[originIdx].enhanceUrl = orientationUrl;
        }
      } catch (e) {
        // imagelist.value[originIdx].enhanceUrl = enhanceUrl;
        // 加载异常
        console.log("加载增强图异常，重试一次");
        setTimeout(async () => {
          try {
            console.log("开始重试一次");
            const imageInfo = await drawWaterMark(orientationUrl);
            if (imageInfo.base64) {
              // imagelist.value[originIdx].enhanceUrl = imageInfo.base64;
              imagelist.value[originIdx].watermark = imageInfo.base64;
              imagelist.value[originIdx].enhanceUrl = orientationUrl;
            }
          } catch (e) {
            console.log("第二次加载增强图异常");
          }
        }, 500);
      }
    };
    image.onerror = function () {
      // reject(new Error("error"));
      imagelist.value[originIdx].enhanceUrl = enhanceUrl;
    };
  } else {
    imagelist.value[originIdx].tarHeight = result.tarHeight || result.oriHeight;
    imagelist.value[originIdx].tarWidth = result.tarWidth || result.oriWidth;
    // 不需要旋转
    if (isVip.value) {
      imagelist.value[originIdx].enhanceUrl = enhanceUrl;
      return;
    }
    // 服务端有加水印的话
    if (enhanceUrl?.endsWith("@1m")) {
      imagelist.value[originIdx].enhanceUrl = enhanceUrl;
      imagelist.value[originIdx].watermark = enhanceUrl;
      return;
    }
    try {
      const imageInfo = await drawWaterMark(enhanceUrl);
      if (imageInfo.base64) {
        imagelist.value[originIdx].enhanceUrl = enhanceUrl;
        imagelist.value[originIdx].watermark = imageInfo.base64;
      }
    } catch (e) {
      imagelist.value[originIdx].enhanceUrl = enhanceUrl;
      // 加载异常
      console.log("加载增强图异常，重试一次");
      setTimeout(async () => {
        try {
          const imageInfo = await drawWaterMark(enhanceUrl);
          if (imageInfo.base64) {
            imagelist.value[originIdx].watermark = imageInfo.base64;
          }
        } catch (e) {
          console.log("第二次加载增强图异常");
        }
      }, 1500);
    }
  }
}
function decryptUrl(url) {
  let enhanceUrl = "";
  if (url) {
    $encryptor?.setPrivateKey(privateKey);
    let part1 = url.substring(0, url.length / 2);
    let part2 = url.substring(url.length / 2);
    // console.log("part1", part1);
    // console.log("part2", part2);
    // 解密
    const dec1 = $encryptor?.decrypt(part1);
    const dec2 = $encryptor?.decrypt(part2);
    // console.log("dec1", dec1);
    // console.log("dec2", dec2);
    // 打印解密后的明文
    console.log("decryptedMessage", dec1 + dec2);
    enhanceUrl = dec1 + dec2;
  }
  return enhanceUrl;
}

function retryLoad() {
  var scriptEle = document.createElement("script");
  scriptEle.src = "https://sdk.amazonaws.com/js/aws-sdk-2.1181.0.min.js";
  scriptEle.onload = function () {
    console.log("Script loaded successfully.");
    startUpload();
  };
  scriptEle.onerror = function () {
    if (jsTryCount.value >= 2) {
      messageBox.error(t("network-err"), t("network-err-try"));
      fullLoading.hide();
      return;
    }
    jsTryCount.value++;
    console.log("Script loading failed. Retrying...");
    retryLoad();
  };
  document.head.appendChild(scriptEle);
}

function noVipToVip(cb) {
  // 非会员升级会员，水印去掉
  console.log("非会员升级会员，水印去掉", imagelist.value);
  if (
    imagelist.value &&
    imagelist.value.length &&
    imagelist.value[0].watermark
  ) {
    useApi
      .getResult(resultId.value)
      .then((res) => {
        if (res.code == 0) {
          console.log("res", res);
          const { imgList } = res.data;
          imagelist.value[0].enhanceUrl = decryptUrl(imgList[0].enhanceUrl);
          imagelist.value[0].watermark = "";
          checkoutIosImageOrientation(imgList[0]);
          //如果都处理好了，则关闭查询
          cb && cb();
        }
      })
      .catch((err) => {});
  }
}
function enhanceVipEvent() {
  noVipToVip();
}
function visitorLoginEvent(e) {
  // console.log("登录回调", e);
  const info = e.detail;
  //游客登录后，如果是会员,并且点击了下载，则直接下载图片
  if (info.memberType > 0) {
    noVipToVip(() => {
      if (clickDownloadIdx.value > -1) {
        setTimeout(() => {
          onDownloadOne(clickDownloadIdx.value);
          clickDownloadIdx.value = -1;
        }, 200);
      }
    });
  }
}
function enhanceLoad() {
  // console.log("加载成功", currIndex.value);
  if(imagelist.value[currIndex.value]){
    imagelist.value[currIndex.value].status = uploadStatus.success;
  }
}
function enhanceError(error) {
  // console.log("加载错误", error);
  if (imagelist.value.length === 1 && tryCount.value < 3) {
    tryCount.value = tryCount.value + 1;
    console.log("加载失败，重试", tryCount.value);
    tryFlag.value = false;
    setTimeout(() => {
      tryFlag.value = true;
    }, 1000);
  }
}
/**
 * 继续增强
 */
function continueHandle() {
  // 如果结果都处理好了，则可以继续增强
  if (!isAllDownload.value) {
    fullLoading.hide();
    return;
  }
  if (!isLogin.value) {
    fullLoading.hide();
    mainStore.setVisibleLogin(true);
    return;
  }
  if(imagelist.value.length === 1 && (imagelist.value[0].tarWidth > maxWidth || imagelist.value[0].tarHeight > maxWidth)){
    fullLoading.hide();
    messageBox.error(t("enhance-process-fail"), t('errCode-1001003'));
    return
  }
  //获取成功的，删除失败的
  const successImgList = imagelist.value.filter(
    (v) => v.status >= uploadStatus.lodingImg
  );
  imagelist.value = [...successImgList];
  currIndex.value = 0;

  imageMap.value = {};
  // imagelist.value[0].enhanceUrl = 'https://resaws.ivmagic.com/2024/04/07/1c95d383-c009-4883-ae4a-b45a8d8561e9-10002/1c95d383-c009-4883-ae4a-b45a8d8561e9-1.jpg'
  enhanceImg(successImgList, () => {
    imagelist.value.map((v) => {
      if (v.status >= uploadStatus.lodingImg) {
        v.status = uploadStatus.handle;
      }
    });
  });
}
function enhanceImg(list, callback) {
  let resourceUrls = list.map((v, i) => {
    // 继续增强有enhanceUrl 重试只有originUrl
    let enhanceUrl = v.enhanceUrl || v.originUrl;
    if (enhanceUrl.includes("@1m")) {
      enhanceUrl = v.enhanceUrl.substring(0, v.enhanceUrl.length - 3);
    }
    const arr = enhanceUrl.split("/");
    const id = arr[arr.length - 1];
    imageMap.value[id] = i;
    return enhanceUrl;
  });
  enhanceImgAPI(resourceUrls, callback);
}
function enhanceImgAPI(resourceUrls, callback) {
  if (!resourceUrls.length) {
    messageBox.error(t("network-err"), t("enhance-upload-fail-retry"));
    fullLoading.hide();
    return;
  }

  const params = {
    enhanceCfgIds: [],
    resourceUrls,
  };
  if (props.funcIndex === EnhanceFuncs.Enhance) {
    params.enhanceCfgIds = props.configs.filter((v) => v != "");
  } else if (props.funcIndex === EnhanceFuncs.WildFocus) {
    params.enhanceCfgIds = [55];
  } else if (props.funcIndex === EnhanceFuncs.Landscape) {
    params.enhanceCfgIds = [56];
  } else if (props.funcIndex === EnhanceFuncs.StableMotion) {
    params.enhanceCfgIds = [57];
  } else if (props.funcIndex === EnhanceFuncs.PNG_HD) {
    params.enhanceCfgIds = [59];
  } else if (props.funcIndex === EnhanceFuncs.Word_HD) {
    params.enhanceCfgIds = [58];
  }
  fullLoading.updateText({ title: t("enhance-upload-process") });
  useApi
    .enhanceImg(params)
    .then((result) => {
      if (result.code == 0) {
        fullLoading.hide();
        callback();
        resultId.value = result.data;
        queryResultTimeout.value = -1
        getImageListResult(result.data, 0);
        mainStore.updateUserInfo();
      } else {
        fullLoading.hide();
        imagelist.value.map(v => {
          v.status = uploadStatus.fail
        })
      }
    })
    .catch((e) => {
      fullLoading.hide();
      imagelist.value.map(v => {
          v.status = uploadStatus.fail
        })
      messageBox.error(t("network-err"), t("network-err-try"));
    });
}
/**
 * 重试失败的
 */
function onTryHandle() {
  reTryVisible.value = false;
  fullLoading.show(t("enhance-upload-loading"), t("enhance-process"));
  //获取成功的，删除失败的
  const failImgList = imagelist.value.filter(
    (v) => v.status == uploadStatus.fail
  );
  let resourceUrls = failImgList.map((v, i) => {
    // 继续增强有enhanceUrl 重试只有originUrl
    let enhanceUrl = v.enhanceUrl || v.originUrl;
    if (enhanceUrl.includes("@1m")) {
      enhanceUrl = v.enhanceUrl.substring(0, v.enhanceUrl.length - 3);
    }
    return enhanceUrl;
  });
  enhanceImgAPI(resourceUrls, () => {
    imagelist.value.map((v) => {
      if (v.status == uploadStatus.fail) {
        v.status = uploadStatus.handle;
      }
    });
  });
}
function handleCancel() {
  reTryVisible.value = false;
  const successImgList = imagelist.value.filter(
    (v) => v.status >= uploadStatus.lodingImg
  );
  imagelist.value = [...successImgList];
  totalCount.value = imagelist.value.length
  if(imagelist.value.length === 0){
    resultId.value = null
  }
}
//点赞评价
function onEvaluate() {
  imagelist.value[currIndex.value].isEvaluate = true;
}
function reloadImg() {
  tryCount.value = 0;
  enhanceError();
}
function onBatchUpolad() {
  uploadStore.setBatchVisible(true)
}
function sizeLimitCancel() {
  sizeLimitVisible.value = false
}
function sizeLimitOk() {
  imagelist.value = imagelist.value.filter(v => {
    if(v.status >= uploadStatus.lodingImg){
      if(v.tarWidth < maxWidth && v.tarHeight < maxWidth){
        return true
      }
    }else {
      if(v.width < maxWidth && v.height < maxWidth){
        return true
      }
    }
    
  })
  currIndex.value = 0
  sizeLimitVisible.value = false
  setTimeout(()=>{
    onApply()
  },100)
}
</script>
<style lang="scss">
.arco-switch[role="switch"] {
  height: 18px;
  line-height: 18px;
  min-width: 30px;
}
.arco-switch {
  background-color: $color-bg;
  .arco-switch-handle {
    top: 1px;
    left: 2px;
  }
}
.arco-switch-checked[role="switch"] .arco-switch-handle {
  left: calc(100% - 16px - 2px);
}

.arco-switch-checked {
  background-color: $mainColor;
}
</style>
