import {  createApp  } from "vue";
import Message from "@/components/messageBox.vue";

// const app = createApp(messageBox);
// const dom = document.createElement("div");
// const instant:any = app.mount(dom); 
// document.body.appendChild(dom)
class MessageBox {
    success(title: string|null, text: string|null, delay: number=3000){
        this.show(title,text,'success',delay);
    }
    error(title: string|null, text: string|null, delay: number=3000){
        this.show(title,text,'error',delay);
    }
    show(title: string|null, text: string|null, type:string='success',delay: number=3000){
        // 创建容器
        const mountNode = document.createElement('div');
        // 将容器插入到body内
        document.body.appendChild(mountNode);
        // 创建节点
        const app = createApp({
            render() {
                // 这里使用了h()函数,等价于<myForm :title="title" :text="text" :options="options" @submit="..." @onClosed="..."></myForm>
                return h(Message, {
                    // ref: myFormRef,
                    // 参数
                    title,
                    text,
                    type,
                    node: mountNode,
                    delay
                })
            }
        });
        // 挂载容器，instance就是容器的实例
        let instance = app.mount(mountNode);
    }
}
export default new MessageBox();
// export function  messageBox(title: string|null, text: string|null, type:string='success',delay: number=3000) {
//     return new Promise((resolve, reject) => {
//         // 保存组件实例
//         let myFormRef = ref();
//         // 创建容器
//         const mountNode = document.createElement('div');
//         // 将容器插入到body内
//         document.body.appendChild(mountNode);
//         // 创建节点
//         const app = createApp({
//             render() {
//                 // 这里使用了h()函数,等价于<myForm :title="title" :text="text" :options="options" @submit="..." @onClosed="..."></myForm>
//                 return h(MessageBox, {
//                     // ref: myFormRef,
//                     // 参数
//                     title,
//                     text,
//                     type,
//                     node: mountNode,
//                     delay
//                 })
//             }
//         });
//         // 挂载容器，instance就是容器的实例
//         let instance = app.mount(mountNode);
//         // 打开弹窗
//         // myFormRef.value.showDialog();
//     })
// }