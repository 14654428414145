<template>
  <div class="ball-loading">
    <div class="ball1"></div>
    <div class="ball2"></div>
  </div>
</template>

<style lang="scss">
.ball-loading {
  height: 10px;
  width: 40px;
  position: relative;
}
.ball1 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(66, 83, 255);
  position: absolute;
  top: 0;
  right: 0;
  animation: ball-move1 1.5s;
  -webkit-animation: ball-move1 1.5s linear infinite; /* Safari and Chrome */
}
.ball2 {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(255, 169, 126);
  position: absolute;
  top: 0;
  left: 0;
  animation: ball-move2 1.5s;
  -webkit-animation: ball-move2 1.5s linear infinite; /* Safari and Chrome */
}
@keyframes ball-move1 {
  0% {
    right: 0;
  }
  50% {
    right: 30px;
  }
  100% {
    right: 0;
  }
}
@-webkit-keyframes ball-move1 {
  0% {
    right: 0;
  }
  50% {
    right: 30px;
  }
  100% {
    right: 0;
  }
}
@keyframes ball-move2 {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
@-webkit-keyframes ball-move2 {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}
</style>
