<template>
  <div
    class="w-full h-full  m-auto relative select-none overflow-hidden"
    ref="canvasRef"
    @mouseleave="isEnter = false"
    @mouseenter="isEnter = true"
  >
    <div
      class="absolute left-0 top-0 h-full overflow-hidden loop"
      v-if="isEnter"
    >
      <NuxtImg :src="props.origin" alt="origin" class="w-auto h-full max-w-none"/>
    </div>
    <NuxtImg :src="props.enhance" alt="enhance" class="w-full h-auto" />
    <div
    v-if="props.title"
          class="absolute left-1/2 -translate-x-1/2 w-max bottom-10 bg-[#00000088] rounded-full text-center px-6 h-11 leading-[44px] text-base font-Roboto-Bold font-bold text-white block"
        >
          {{ props.title }}
        </div>
    <div
    v-if="isEnter"
      class="absolute top-0 bottom-0 z-[4] cursor-grab select-none w-px flex flex-col items-center bg-text-deep loop2"
    >
      <!-- <div
        class="absolute top-5 bg-text-deep rounded-[20px] h-[30px] leading-[30px] px-5 text-white text-xs w-max right-5"
      >
        {{ $t("before") }}
      </div>
      <div
        class="absolute top-5 bg-text-deep rounded-[20px] h-[30px] leading-[30px] px-5 text-white text-xs w-max left-5"
      >
        {{ $t("after") }}
      </div> -->
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  origin: {
    type: String,
    default: "",
  },
  enhance: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
});
const isEnter = ref(false);
const canvasRef = ref(null);


function mouseleave() {
  console.log("mouseleave");
  isEnter.value = false
}
</script>
<style lang="scss" scoped>
.loop {
  animation: loop 3s  alternate linear infinite;
}
.loop2 {
  animation: loop2 3s alternate linear infinite;
}
@keyframes loop {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes loop2 {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
</style>
