<template>
  <div
    class="h-[88px] rounded-[20px] flex items-center justify-center relative"
    :class="
      props.downloadEnable
        ? 'bg-text-deep group cursor-pointer'
        : 'bg-btn-gray cursor-not-allowed'
    "
  >
    <div
      class="absolute top-0 left-0 w-full -translate-y-full hidden group-hover:block"
    >
      <div class="rounded-[20px] px-3 py-4 text-white w-full bg-text-deep">
        <div
          class="font-Roboto-Bold font-bold  text-center h-10 leading-10 rounded-[6px] hover:bg-[#D8D9DE1A]"
          v-show="showJPG"
          @click="download('jpg')"
        >
          JPG
        </div>
        <div v-show="showJPG && (props.funcIndex === EnhanceFuncs.PNG_HD || !mainStore.userInfo.memberType || isVipPro)" class="h-px my-3 bg-[#FFFFFF33]"></div>
        <div
          class="font-Roboto-Bold font-bold text-center h-10 leading-10 rounded-[6px] hover:bg-[#D8D9DE1A] relative"
          @click="download('png')"
          v-if="(props.funcIndex === EnhanceFuncs.PNG_HD || !mainStore.userInfo.memberType || isVipPro)"
        >
          PNG
        <img :src="vip_icon" alt="vip icon" v-show="isNormalVip || !isVip" width="24" height="24" class="absolute right-0 top-1/2 -translate-y-1/2">
        </div>
      </div>
      <div class="h-3"></div>
    </div>

    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.07031 21.6339V28.9277H28.8434V21.6339M15.9568 3.41406V21.7685M7.55381 14.7973L15.9568 22.5739L23.8229 14.7973"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <div class="ml-8 flex flex-col text-white">
      <span class="font-Roboto-Bold font-bold  text-[24px] leading-[28px]">{{
        $t("enhance-save")
      }}</span>
      <span v-show="!isVip" class="font-Roboto-Bold font-bold  text-[12px] leading-[14px]">{{
        $t("enhance-remove")
      }}</span>
    </div>
    <BaseModal
      :title="$t('modal-png-title')"
      :content="$t('modal-png-text')"
      :visible="visible"
      @close="visible = false"
    >
      <div>
        <span
          class="bg-text-deep inline-block h-10 leading-10 px-10 cursor-pointer rounded-full text-white mr-3"
          @click="visible = false"
          >{{ $t('modal-png-ok') }}</span
        >
      </div>
    </BaseModal>
  </div>
</template>

<script setup>
import { useUploadStore } from "@/store/upload";
import messageBox from "@/composables/messageBox";
import { useMainStore } from "@/store/index";
import { uploadStatus, EnhanceFuncs} from "@/utils/type";
const mainStore = useMainStore();
const { locale, t } = useI18n();
const dayjs = useDayjs();
const uploadStore = useUploadStore();
const { isLogin, isVip,isVipPro,isNormalVip } = useUserInfo();
const emit = defineEmits("download");
const fullLoading = useFullLoading();
const visible = ref(false);
const vip_icon = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK8SURBVHgB7VRNSFRRFP7OfW8cczJMsIwWUYsgi8BoJQUNRUUu2llSaAVSmxKrVRYUFYVFgRIklouQhKB2Vi5y0SLox01IBZHUpkDCn3Qc39x73+28+2bSdGZ0VwsvfNz33jnn+84759wLLK5/vSiXwYy83aFJdEHQ6tCTcrobYCA1pXbHyrb+WJCAGXpT7juml0k3W2KGCVwpLZRFzMDvc5PR/bRi48TM72IOuXnkKOM1QasN0BoZkB/A/wPwO4wfBFh6Am33Y7rWGEN5/8B87dtnXPGEs4xCiDD7TNZ5YH2MGfQNHSgo2fIuq0DyQ8+a6JLIU5CosIFWIFOWWWIiXSaRtlk2wSbznEYmamltfHROiQpdc5WkrICSsJDBrnhX4Ts/UwA9vVt7AFtKBaP9XWpZ7FT2HkhZHRCxSIiQdAyaFVRGRIaCVlR5jETgl/YN4ApfV2cXUPoxQwWZIMxuhAPrOdtumpUpw+PGnxNKn+eYcBBCeEKrh9kFhgdOcGAb/4m25ZGpIiivDN/7j3HmPSRThhFkO2mkbMRkosOXsnL6r2TSKNmMuy/ack/Rq3uliBTdBpnD3DTBjR7icWxAbGk/PK+Dv+0EOS1Iea2IRprgRM5yoyMGQpJDF1C6sp2WV47mFLAin1uj+FnSCYcO2tEQYhgKcRQVpHgU9zJXN3Skge3NbC7kQii4uIj3qetUU6NncuW+Kl52liEirvEZOsKz5zA+Mo7j25fXWLe+jolvcebF7KrhupexSV8h+ps8r0Ao0soixS3Qpp7J+bDSJyYe5M5VcWgJZ67hiDsYLz9D8bjKxpFXIBRpXwVwzYFDaZF0JPHI0A145hLFj07lip9XwIr0PoghOvWM3bcFlw7vPpfnPpyxRqo6ncwXKxYiQHvqEnCdap6mLr7kfvFxvYnxxMn5yBfX/7F+A3JMXdI0gxGcAAAAAElFTkSuQmCC'

const props = defineProps({
  imagelist: {
    type: Array,
    default: [],
  },
  downloadEnable: {
    type: Boolean,
    default: false,
  },
  resultId: {
    type: String,
    default: "",
  },
  funcIndex: {
    type: Number,
    default: null,
  },
});
const timeout2 = ref();
const isAllDownloading = ref(false);
defineExpose({ clearTimer });
onUnmounted(() => {
  clearTimeout(timeout2.value);
});
const showJPG = computed(() => {
  return props.funcIndex !== EnhanceFuncs.PNG_HD;
});
// const showPNG = computed(() => {
//   return (props.funcIndex === EnhanceFuncs.PNG_HD || !!!mainStore.userInfo.memberType || isVipPro)
// });
function download(format) {
  //正在下载的话，则不允许点击
  if (!props.downloadEnable || isAllDownloading.value) {
    return;
  }
  if (!isLogin.value) {
    uploadStore.setSignUpVisible(true);
    return;
  }
  const isFree = isVip.value || mainStore.userInfo.freeDownloadCount > 0;
  //需要区分不同会员下载png的
  if (!isFree) {
    mainStore.setVipFrom("download-all");
    mainStore.setVisibleVipDialog(true);
    return;
  }
  if (format === "png") {
    // 年会员和周会员pro才支持png下载
    if (isNormalVip.value) {
      return;
    }
    // if (mainStore.userInfo.freeDownloadCount > 0) {
    //   mainStore.setVipFrom("download-all");
    //   mainStore.setVisibleVipDialog(true);
    //   return;
    // }
    if (
      props.imagelist.length &&
      props.imagelist[0].enhanceUrl.includes(".jpg")
    ) {
      visible.value = true;
      return;
    }
  }
  useLogEvent({ eventName: "enhance-download-all" });
  useFBLogEvent({ eventName: "enhance-download-all" });
  isAllDownloading.value = true;
  fullLoading.show(t("enhance-downloading"));
  // queryzip(format);
  const list = props.imagelist.filter(
    (v) => v.status >= uploadStatus.lodingImg
  );
  // 检测是否是大文件
  let isLarge = false;
  list.map((v) => {
    if (v.tarHeight > 2048 || v.tarWidth > 2048) {
      isLarge = true;
    }
  });
  console.log('isLarge',isLarge,list.length);
  if (isLarge) {
    for (let i = 0; i < list.length; i++) {
      setTimeout(() => {
        onDownloadOne(list[i], format);
      }, 2000+100*i);
    }
    fullLoading.hide();
    isAllDownloading.value = false;
  } else {
    if (list.length >= 10) {
      console.log('大于10张');
      for (let i = 0; i < 8; i++) {
        // console.log('enhanceUrl:',list[i].enhanceUrl);
        setTimeout(() => {
          onDownloadOne(list[i], format);
        }, 50*i);
      }
      setTimeout(() => {
        console.log('4秒后下载');
        let k = 0;
        for (let i = 8; i < list.length; i++) {
          // console.log('enhanceUrl:',list[i].enhanceUrl);
          k++;
          setTimeout(() => {
            onDownloadOne(list[i], format);
          }, 50*k);
        }
      }, 4000);
    } else {
      console.log('小于10张');
      list.map((v,i) => {
        setTimeout(() => {
          onDownloadOne(v, format)
          }, 50*i);
      });
    }

    fullLoading.hide();
    isAllDownloading.value = false;
  }
}
function onDownloadOne(imgInfo, format) {
  let enhanceUrl = imgInfo.enhanceUrl;
  if (enhanceUrl.includes("@1m") && mainStore.userInfo.freeDownloadCount > 0) {
    enhanceUrl = imgInfo.enhanceUrl.substring(0, imgInfo.enhanceUrl.length - 3);
  }
  console.log("下载", enhanceUrl);
  const  a = enhanceUrl.split('/');
  const key = a[a.length - 2]
  fetch(enhanceUrl, {
    mode: "cors",
  })
    .then(async (res) => {
      const e = await res.blob();
      return e;
    })
    .then(async (blob) => {
      let url = URL.createObjectURL(blob);

      if (mainStore.userInfo.freeDownloadCount > 0) {
        useApi.freeDownloadCount().then((result) => {
          if (result.code == 0) {
            mainStore.updateUserInfo();
          }
        });
      }

      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      const tt = dayjs().format("YYYYMMDD");
      a.download = "Picma_" + tt + '_' + key + (format === "png" ? ".png" : ".jpg");
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(a.href);
      // downActived.value = false;
      setTimeout(() => {
        a.remove();
      }, 2000);
    })
    .catch((e) => {
      messageBox.error(t("network-err"), t("network-err-try"));
    });
}
function queryzip(format) {
  useApi
    .getResult(props.resultId)
    .then((res) => {
      if (res.code == 0) {
        let downloadUrl = "";
        if (format === "png" && res.data.downloadUrl) {
          downloadUrl = res.data.downloadUrl;
        } else if (format === "jpg" && res.data.downloadUrlT) {
          downloadUrl = res.data.downloadUrlT;
        }
        if (downloadUrl) {
          fetch(downloadUrl, {
            mode: "cors",
          })
            .then(async (res) => {
              const e = await res.blob();
              return e;
            })
            .then((blob) => {
              isAllDownloading.value = false;
              fullLoading.hide();
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = URL.createObjectURL(blob);
              const tt = dayjs().format("YYYYMMDDHHmmss");
              a.download = "all_Picma" + tt + ".zip";
              document.body.appendChild(a);
              a.click();
              URL.revokeObjectURL(a.href);
              // downActived.value = false;
              setTimeout(() => {
                a.remove();
              }, 2000);
            })
            .catch((e) => {
              isAllDownloading.value = false;
              fullLoading.hide();
              clearTimeout(timeout2.value);
              messageBox.error(t("network-err"), t("network-err-try"));
            });
        } else {
          clearTimeout(timeout2.value);
          timeout2.value = setTimeout(() => {
            clearTimeout(timeout2.value);
            queryzip(format);
          }, 3000);
        }
      } else {
        isAllDownloading.value = false;
        fullLoading.hide();
        clearTimeout(timeout2.value);
      }
    })
    .catch((e) => {
      isAllDownloading.value = false;
      clearTimeout(timeout2.value);
    });
}
function clearTimer() {
  clearTimeout(timeout2.value);
}
</script>
