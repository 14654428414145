<template>
  <div class="">
    <div v-for="item in list" :key="item.title" class="group cursor-pointer" @click="select(item.title)">
        <div class=" font-Montserrat font-bold h-[60px] text-[16px] flex items-center justify-between lg:h-[90px] lg:text-[20px]">
            <div>{{ item.title }}</div>
            <div class="flex items-center justify-end">
                <div class="w-10 h-10 rounded-full relative transition duration-300" :class="[active===item.title?' border border-text-gray bg-text-gray':'bg-gray-line hover:bg-text-gray']">
                    <span class=" w-4 h-[2px] inline-block  absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 "  :class="[active===item.title?'bg-white ease-linear duration-300 rotate-0':'-rotate-90 bg-white']"></span>
                    <span class="bg-white w-4 h-[2px] inline-block absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2" :class="[active===item.title?'hidden':'']"></span>
                </div>
            </div>
            
        </div>
        <div v-html="item.content" class=" text-text-deep text-[14px] leading-[24px]  overflow-hidden " :class="[active===item.title?'max-h-[900px] collapse-open mb-[30px]':'max-h-0 collapse-close']"></div>
        <div class="h-px my-3 bg-gray-line"></div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    list: {
        type: Array,
        default: []
    }
})
const active = ref('')

function select(key) {
    if(key == active.value){
        active.value = ''
    }else
    active.value =key
}
</script>

<style scoped lang='scss'>
.collapse-open {
    transition: max-height 300ms ease-in;
}
.collapse-close {
    transition: 0 100ms ease-in;
}
</style>