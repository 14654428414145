<template>
  <a-spin :loading="loading">
    <div
    class="h-10 w-10 rounded-[8px] border border-dashed border-gray-line flex items-center justify-center"
  >
    <a-upload
      ref="uploadRef"
      name="file"
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      @before-upload="onBeforeUpload"
      :accept="imageAccept.map(v=>'.'+v).join()"
      :show-file-list="false"
      class="w-full h-full flex items-center justify-center"
    >
      <template #upload-button>
        <div class="h-full flex items-center justify-center">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1978_3833)">
              <path
                d="M7.97886 11.2871V2.1099M3.77734 5.59547L7.97886 1.70717L11.9119 5.59547"
                stroke="#8A8EA1"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1.53516 10.8174V14.4643H14.4217V10.8174"
                stroke="#8A8EA1"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1978_3833">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </template>
    </a-upload>
  </div>
  </a-spin>
</template>

<script setup>
import Compressor from "compressorjs";
import { useMainStore } from "@/store/index";
const mainStore = useMainStore();
const loading = ref(false)
const imageAccept = ["jpg","png","jpeg"];
const s3Info = ref({bucketName:'',region:'',sessionToken:'',accessKeyId:'',secretAccessKey:''})
const { t } = useI18n();
function onBeforeUpload(file) {
  const suffix = getFileSuffix(file.name).toLowerCase();
  console.log(suffix, file);
  if (imageAccept.includes(suffix)) {
    // 检查是否符合图片大小
    if (file.size < 2 * 1024 * 1024) {
      startUpload(file);
    } else {
      messageBox.error(t("index-upload"), t("header-profile-msg"));
    }
  } else {
    messageBox.error(t("index-upload"), t("header-profile-msg"));
  }

  return false;
}
function startUpload(file) {
  loading.value = true
  getS3Token(() => {
    let name = file.name.replaceAll(":", "");
    getS3UrlHandle([name], 0, (names) => {
      console.log("names", names);
      handleFile(file, names[0]);
    });
  });
}
function getS3Token(callback) {
  useApi
    .getS3Token(3)
    .then((result) => {
      console.log("s3", result);
      if (result.code == 0) {
        s3Info.value = {...result.data}
        callback();
      } else {
        loading.value = false
      }
    })
    .catch((e) => {
      console.error("getS3Token error");
      messageBox.error(t("network-err"), t("network-err-try"));
      loading.value = false
    });
}
function getS3UrlHandle(fileNames, count, callback) {
  console.log("getS3UrlHandle");
  if (count >= 2) {
    console.log("getS3UrlHandle超过3次");
    messageBox.error(t("network-err"), t("network-err-try"));
    loading.value = false
    return;
  }
  useApi
    .getS3Url({ fileNames, enhanceType: 3 })
    .then((result) => {
      if (result.code == 0) {
        console.log("getS3Url");
        // handleFile(imagelist.value[0].file, fileNameList.value[0]);
        callback(result.data);
      } else {
        // this.images = []
        // 获取token异常  待处理
        getS3UrlHandle(fileNames, count + 1, callback);
      }
    })
    .catch((err) => {
      // 获取token异常  待处理
      console.log("getS3Url 上传异常", err);
      getS3UrlHandle(fileNames, count + 1, callback);
    });
}
function handleFile(file, s3Name) {
  compressorImg(file, s3Name);
}
function compressorImg(file, s3Name) {
  if (file.size < 1 * 1024 * 1024) {
    upS3Token(file, s3Name);
  } else {
    console.log("压缩前:", file.size);
    new Compressor(file, {
      quality: 0.8,
      convertSize: 1 * 1024 * 1024,
      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(result) {
        console.log("压缩后文件:", result);
        console.log("压缩后:", result.size);
        upS3Token(result, s3Name);
      },
      error(err) {
        console.log(err.message);
        // 压缩失败则原图进行上传
        upS3Token(file, s3Name);
      },
    });
  }
}

// 上传到s3
function upS3Token(file, s3Name) {
  const {bucketName,region,sessionToken,accessKeyId,secretAccessKey} = s3Info.value;
  // 设置上传AWS的参数
  const params = {
    Bucket: bucketName,
    Key: s3Name, // 对上传远程文件的添加时间戳， urlKey为远程地址
    ContentType: file.type,
    "Access-Control-Allow-Credentials": "*",
    Body: file,
    // "ACL": "public-read",
  };
  console.log("upS3Token", s3Info, params);
  // const AWS = require('aws-sdk');
  if (typeof AWS === "undefined") {
    messageBox.error(t("index-upload"), t("index-upload-load-fail"));
    retryLoad();
    return;
  }
  AWS.config.update({
    region: region,
    // apiVersion: '2006-03-01',
    sessionToken: sessionToken, // 从后台获取数据
    accessKeyId: accessKeyId, // 从后台获取数据
    secretAccessKey: secretAccessKey, //
  });
  const s3 = new AWS.S3();
  // 设置上传配置和获取进去进度条数据
  const s3Upload = s3
    .upload(params, function (err, data) {
      console.log("照片上传", err, data);
      if (err == null) {
        data.success = true;
        resolve(data);
      } else {
        data.success = false;
        data.message = err;
        resolve(data);
      }
    })
    .on("httpUploadProgress", function (progress) {
      let per = parseInt(progress.loaded, 10) / parseInt(progress.total, 10);
      const percent = Number((per * 100).toFixed(2));
      console.log("进度:", percent);
    });
  s3Upload.send((err, data) => {
    if (err) {
      console.log("上传错误", err);
      if (err.code == "ExpiredToken") {
        console.log(" token过期");
        getS3Token(() => {
          upS3Token(file, s3Name);
        });
        return;
      }
      messageBox.error(t("network-err"), t("network-err-try"));
      loading.value = false
    } else {
      uploadAvatar(data.Location);
    }
  });
}
function uploadAvatar(url) {
  useApi
    .uploadAvatar(url)
    .then((result) => {
      if (result.code == 0) {
        mainStore.updateUserInfo();
      } else {
      }
      loading.value = false
    })
    .catch((e) => {
      loading.value = false
      messageBox.error(t("network-err"), t("network-err-try"));
    });
}
</script>

<style scoped lang="scss"></style>
