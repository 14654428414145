<template>
  <div class="w-[68px] gap-5 items-center justify-end hidden lg:flex fadeOut">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="cursor-pointer hidden xl:block hover:scale-150"
      @click="onBad"
    >
      <path
        d="M4.24533 14.7892H9.1931C7.27612 21.6476 10.514 22 10.514 22C11.8853 22 11.6026 20.9482 11.7062 20.7747C11.7062 17.4187 15.3834 14.7892 15.3834 14.7892V5.27681C15.3834 4.33886 14.0625 4 13.5448 4H6.10914C5.40952 4 4.83862 5.77831 4.83862 5.77831C3 11.837 3 13.6398 3 13.6398C3 14.8895 4.24533 14.7892 4.24533 14.7892ZM17.4151 14.7864H20.3787C20.9916 14.7864 21 14.2036 21 14.2036L20.3871 4.60994C20.3871 4 19.7547 4 19.7547 4H17.1912C16.6567 4 16.6623 4.40392 16.6623 4.40392V14.0599C16.6623 14.7973 17.4151 14.7864 17.4151 14.7864Z"
        fill="#8A8EA1"
      />
    </svg>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="cursor-pointer  hidden xl:block hover:scale-150"
      @click="onGood"
    >
      <path
        d="M19.7547 10.2108H14.8069C16.7239 3.35241 13.486 3 13.486 3C12.1147 3 12.3974 4.05181 12.2938 4.2253C12.2938 7.58133 8.6166 10.2108 8.6166 10.2108V19.7232C8.6166 20.6611 9.9375 21 10.4552 21H17.8909C18.5905 21 19.1614 19.2217 19.1614 19.2217C21 13.163 21 11.3602 21 11.3602C21 10.1105 19.7547 10.2108 19.7547 10.2108ZM6.58489 10.2136H3.62127C3.0084 10.2136 3 10.7964 3 10.7964L3.61287 20.3901C3.61287 21 4.24534 21 4.24534 21H6.80877C7.34328 21 7.33769 20.5961 7.33769 20.5961V10.9401C7.33769 10.2027 6.58489 10.2136 6.58489 10.2136Z"
        fill="#8A8EA1"
      />
    </svg>
  </div>
</template>

<script setup>
const emit = defineEmits('onEvaluate')
const props = defineProps({
  id: {
    type: String,
    default: "",
  },
});
function onBad() {
    imgEvaluate(2)
}
function onGood() {
    imgEvaluate(1)
}
function imgEvaluate(type) {
    if(!props.id)return
    useApi
    .imgEvaluate({enhanceRecordId: props.id,evaluate: type})
    .then((result) => {
      if (result.code == 0) {
        emit('onEvaluate')
      }
    });
}
</script>
<style lang="scss">

</style>
