<script setup lang="ts">
const props = defineProps({
  error: Object
})
const { locale } = useI18n();
const handleError = () => {
  if(locale.value === 'en'){
    clearError({ redirect: '/' })
  } else {
    clearError({ redirect:  '/'+locale.value })
  }
  }
</script>

<template>
  <div class="container mx-auto">
    <div class="bg">
      <div class="blur1"></div>
      <div class="blur2"></div>
      <div class="blur3"></div>
      <div class="blur4"></div>
    </div>
    <div class="content">
      <div v-if="props.error?.statusCode === 404" class="page">
        <p class="tip lost-title">{{ $t("404-title") }}</p>
        <p class="tip lost-desc">{{ $t("404-desc") }}</p>
        <nuxt-link @click="handleError">
        <div class="btn-normal-2 home">{{$t("back-home")}}</div>
        </nuxt-link>
        <nuxt-link @click="handleError">
          <img src="@/assets/images/404.webp" alt="404" />
          <!-- <div class="tip">{{ $t("404") }}</div> -->
        </nuxt-link>
      </div>
      <div v-else class="page">
        <p class="tip lost-title">{{ $t("500-title") }}</p>
        <p class="tip lost-desc">{{ $t("500-desc") }}</p>
        <nuxt-link @click="handleError">
        <div class="btn-normal-2 home">{{$t("back-home")}}</div>
        </nuxt-link>
        <nuxt-link @click="handleError">
          <img src="@/assets/images/500.webp" alt="500" />
          <!-- <div class="tip">{{ $t("500") }}</div> -->
        </nuxt-link>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .bg {
    position: absolute;
    margin: auto auto;
    width: 822px;
    height: 419px;
    .blur1 {
      position: absolute;
      width: 440px;
      height: 419px;
      border-radius: 440px;
      background: rgba(119, 125, 255, 0.2);

      filter: blur(150px);
    }

    .blur2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      right: 0;
      width: 276px;
      height: 260px;
      border-radius: 276px;
      background: rgba(119, 125, 255, 0.3);

      filter: blur(150px);
    }
    .blur3 {
      position: absolute;
      width: 406px;
      height: 396px;
      border-radius: 406px;
      background: rgba(255, 181, 137, 0.3);
      right: 0;
      top: 0;
      filter: blur(150px);
    }
    .blur4 {
      position: absolute;
      width: 265px;
      height: 251px;
      border-radius: 265px;
      background: rgba(255, 181, 137, 0.3);
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      filter: blur(150px);
    }
  }
  .content {
    height: 420px;
    position: relative;
    display: flex;
    
    max-width: 800px;
    margin-top: -100px;
  }
}
.tip {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: $text-deep;
    // margin-top: 60px;
  }
.lost-title {
  font-size: 36px;
}
.lost-desc {
  font-size: 22px;
  margin: 40px 0 34px 0;
  font-weight: 300;
  font-family: "Roboto";
}
.home {
  height: 48px;
  padding: 0 24px;
  // margin: 40px 0 60px 0;
  margin-bottom: 50px;
  font-size: 20px;
}

.page {
  // max-width: 680px;
  text-align: center;
  width: 822px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    // width: 314px;
    height: 140px;
  }

  
}

@media screen and (max-width: 1400px) {
  .container {
    .bg {
      transform: scale(0.8);
    }
  }

  .content {
    transform: scale(0.8);
  }
}
@media screen and (max-width: 1020px) {
  .container {
    .bg {
      transform: scale(0.6);
    }
  }

  .content {
    transform: scale(0.6);
  }
}
@media screen and (max-width: 778px) {
  .container {
    .bg {
      transform: scale(0.4);
    }
  }

  .content {
    transform: scale(0.4);
  }
}
</style>