export const verifyEmail = (email: string) => {
    const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    if (email.trim() == '') {
        return -1;
    }
    if (!email.match(reg)) {
        return -2;
    }
    return 0;
}
export const verifyPw = (password: string) => {
    const reg = /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z]{8,16}$/
    const p = password.trim()
    if (p == '') {
        return -1;
    }
    if (p.length <8 || p.length > 16 || !p.match(reg)) {
        return -2;
    }
    return 0;
}