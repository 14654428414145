<template>
  <div class="w-full bg-[#1A1A1A] select-none">
    <div class="bg1 pt-[160px]">
      <div class="mx-[20px] text-white flex items-center flex-col">
        <h1
          class="my-0 text-text-gray text-[12px] leading-[14px] tracking-[0.2em]"
        >
          {{ $t("index-title") }}
        </h1>
        <h2
          class=" font-Montserrat font-normal text-[48px] leading-[52px] my-2 text-center flex flex-col items-center"
        >
          <span>{{ $t("index-desc1") }}</span>
          <span class="bg-vip bg-clip-text text-transparent">{{
            $t("index-desc2")
          }}</span
          ><span>{{ $t("index-desc3") }}</span>
        </h2>
        <ArrowBtn
          id="btn-tryNow"
          class="mt-6 mb-10 border border-white-line"
          :text="$t('tryNow')"
          path="picma_web-enhance"
          @click="toEnhance(FuncType.Enhance_PRO.path)"
          mode="white"
        ></ArrowBtn>
      </div>
      <div>
        <!-- 图片滚动区域 -->
        <IndexImageScroll></IndexImageScroll>
      </div>
      <div class="flex flex-col items-center">
        <div class="mt-10 mb-4 flex gap-6">
          <a
            href="https://apps.apple.com/us/app/picma-ai-photo-enhancer/id1599103406"
            target="_blank"
            @click="downIOS"
          >
            <NuxtImg
              src="/assets/images/index/appStore.webp"
              alt="app store"
              width="150"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.magictiger.ai.picma"
            target="_blank"
            @click="downGooglePlay"
          >
            <NuxtImg
              src="/assets/images/index/googlePlay.webp"
              alt="google play"
              width="150"
            />
          </a>
        </div>
      </div>
      <div class="px-[20px] mb-6 text-center flex flex-col items-center">
        <p
          class="font-Montserrat font-bold text-[30px] leading-[32px] text-white"
        >
          {{ $t("index-enhance-title") }}
        </p>
        <p class="mt-3 mb-6 text-[12px] leading-4 text-text-gray">
          {{ $t("index-enhance-desc") }}
        </p>
        <ArrowBtn
          mode="white"
          :text="$t('index-enhance-btn')"
          path="picma_web-enhance"
          @click="toEnhance(FuncType.Enhance_PRO.path)"
        ></ArrowBtn>
      </div>
      <!-- 功能对比图 -->
      <div class="relative z-[3] px-4 h-[575px]">
        <IndexFuncCompare></IndexFuncCompare>
      </div>
    </div>

    <div class="flex flex-col items-center bg-white relative">
      <div class="w-full pb-[60px] z-[2] ">
        <!-- 缩放操作 -->
        <div class="pt-[80px] px-4">
          <p class="text-[11px] leading-3 text-text-deep mb-3 tracking-[5%]">
            {{ $t("AI-Eraser") }}
          </p>
          <p class="mb-3 font-Montserrat font-bold text-[30px] leading-[32px]">
            {{ $t("index-eraser-desc") }}
          </p>
          <p class="flex-1 text-[12px] leading-4 text-text-gray mb-8">
            {{ $t("index-eraser-detail") }}
          </p>
          <ArrowBtn
            :text="$t('tryNow')"
            class="mb-8"
            path="picma_web-enhance"
            @click="toEnhance(FuncType.AI_Eraser.path)"
          ></ArrowBtn>
          <IndexCtlShow></IndexCtlShow>
        </div>
        <div class="mt-[100px] px-4 bg2">
          <div>
            <p class="text-[11px] leading-3 text-text-deep mb-3 tracking-[5%]">
              {{ $t("ColorMaster") }}
            </p>
            <p
              class="font-Montserrat font-bold text-[30px] leading-[32px] text-text-deep"
            >
              {{ $t("index-colorMaster-desc") }}
              <!-- <br /><span class="text-mainColor">Colorize different types of B&W photos.</span> -->
            </p>
            <p class="text-[12px] leading-[16px] mt-3 mb-6 text-text-gray">
              {{ $t("index-colorMaster-detail") }}
            </p>
          </div>
            <ArrowBtn
              :text="$t('tryNow')"
              path="picma_web-enhance"
              @click="toEnhance(FuncType.Color_Master.path)"
            ></ArrowBtn>
          <div
            class="mt-8 flex-1 bg-white rounded-[30px] p-5 border-white-line shadow-[0_8px_30px_0px_rgba(200,200,232,0.25)]"
          >
            <NuxtImg
              src="/assets/images/index/3/1.webp"
              alt="Color Master"
              loading="lazy"
              height="560"
              width="560"
              class="rounded-[20px]"
            />
          </div>
          
        </div>
        <div class="mt-[80px] px-4 bg2">
          <div>
            <p class="text-[11px] leading-3 text-text-deep mb-3 tracking-[5%]">
              {{ $t("Descratch") }}
            </p>
            <p
              class="font-Montserrat font-bold text-[30px] leading-[32px] text-text-deep"
            >
              {{ $t("index-descratch-desc") }}
              <!-- <br /><span class="text-mainColor">DeScratch</span> -->
            </p>
            <p class="text-[12px] leading-[16px] mt-3 mb-6 text-text-gray">
              {{ $t("index-descratch-detail") }}
            </p>
            <ArrowBtn
              :text="$t('tryNow')"
              path="picma_web-enhance"
              @click="toEnhance(FuncType.Descratch.path)"
            ></ArrowBtn>
          </div>

          <div
            class="mt-8 flex-1 bg-white rounded-[30px] p-5 border-white-line shadow-[0_8px_30px_0px_rgba(200,200,232,0.25)]"
          >
            <NuxtImg
              src="/assets/images/index/3/2.webp"
              alt="AI Descratch"
              loading="lazy"
              height="560"
              width="560"
              class="rounded-[20px]"
            />
          </div>
        </div>
      </div>
      <!-- <img
        class="absolute left-1/2 -translate-x-1/2 bottom-0 scale-[-1]"
        src="/assets/images/index/bg2.webp"
        alt="bg"
      />

      <img
        class="absolute left-1/2 -translate-x-1/2 bottom-[950px]"
        src="/assets/images/index/bg2.webp"
        alt="bg"
      /> -->
    </div>
    <!-- 图片 -->
    <div class="bg-[#1A1A1A] relative pt-20 h-[606px]" id="ai">
      <div class=" bg-no-repeat bg-cover bg-center w-full h-full" style="background-image: url('/assets/images/index/img1.webp');">

      </div>
      <!-- <NuxtImg
        src="/assets/images/index/img1.webp"
        alt="origin"
        loading="lazy"
        format="webp"
        class="h-full"
        fit="cover"
      /> -->
      <div
        class="absolute top-10 w-full flex flex-col items-center"
      >
        <p
          class="text-[10px] text-center leading-[12px] tracking-[5px] text-white mb-2"
        >
          {{ $t("index-generation") }}
        </p>
        <p
          class="my-0 font-Montserrat font-bold text-[30px] leading-[36px] text-center text-white px-6"
        >
          <span>{{ $t("index-generation-desc1") }}</span>
          <span class="bg-vip bg-clip-text text-transparent">{{
            $t("index-generation-desc2")
          }}</span
          ><span>{{ $t("index-generation-desc3") }}</span>
        </p>
        <p class="text-[12px] leading-4 mt-2 text-text-gray">
          {{ $t("index-generation-desc-2") }}
        </p>
        <div class="flex items-center gap-4 mt-6">
          <a
            href="https://apps.apple.com/us/app/picma-ai-photo-enhancer/id1599103406"
            target="_blank"
            @click="downIOS"
          >
            <NuxtImg
              src="/assets/images/index/appStore.webp"
              alt="app store"
              width="150"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.magictiger.ai.picma"
            target="_blank"
            @click="downGooglePlay"
          >
            <NuxtImg
              src="/assets/images/index/googlePlay.webp"
              alt="google play"
              width="150"
            />
          </a>
        </div>
      </div>
    </div>
    <!-- 视频列表 -->
    <!-- <div class="bg-white">
      <div class="w-[1200px] mx-auto pt-[160px] flex flex-col items-center">
        <p class="font-Roboto-Bold font-bold  text-[56px] leading-[68px]">
          AI Video <span class="text-mainColor">Enhancer</span>
        </p>
        <p class="text-xl leading-6 mt-5 mb-8 text-text-gray">
          Illuminate every frame to captivate your audience and elevate the
          viewing experience
        </p>
        <ArrowBtn text="Learn More" path="picma_web-enhance"></ArrowBtn>
        <IndexVideoBanner class="mt-10"></IndexVideoBanner>
      </div>
    </div> -->
    <!-- 评价列表 -->
    <div class="bg-light-gray-bg relative">
      <div
        class=" mx-auto pt-[80px] flex flex-col items-center px-4"
      >
        <div>
          <p
            class="w-full mx-auto font-Montserrat font-bold text-[30px] leading-[32px] text-center "
          >
            {{ $t("index-review1") }} <br /><span class="text-mainColor">{{
              $t("index-review2")
            }}</span>
          </p>
          <div
            class="my-[60px] flex gap-x-2 gap-y-3 flex-wrap justify-center"
          >
            <span
              class="bg-btn-bg-hover h-[40px] rounded-full text-center text-[13px] leading-[40px] px-5 text-text-deep"
              v-for="tag in [
                'Photographer',
                'Designer',
                'Entrepreneur',
                'Family',
                'Marketing',
                'E-Commerce',
                'Museum',
              ]"
              :key="tag"
              >{{ tag }}</span
            >
          </div>
          <IndexReview></IndexReview>
        </div>
      </div>
      <div
        class="absolute left-0 bottom-0 h-2/5 w-full"
        style="
          background-image: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 27.58%,
            #ffffff 76.91%
          );
        "
      ></div>
    </div>
    <TryNow></TryNow>
  </div>
</template>
<script setup>
import { useUploadStore } from "@/store/upload";
const uploadStore = useUploadStore();
// import { FuncType } from "@/utils/type";
const {getFuncType} = useFuncType()
const FuncType = ref(getFuncType())
// const localePath = useLocalePath();
// import { termsofService, privacyPolicy, cookiePolicy } from "@/utils/config";

// function goTop() {
//   window.scrollTo({ top: 0, behavior: "smooth" });
// }
function downIOS() {
  useLogEvent({ eventName: "index-ios-download" });
  useFBLogEvent({ eventName: "index-ios-download" });
}
function downGooglePlay() {
  useLogEvent({ eventName: "index-google-play-download" });
  useFBLogEvent({ eventName: "index-google-play-download" });
}
function toEnhance(path) {
  // uploadStore.setFuncType(FuncType.Enhance_PRO.path);
  uploadStore.setFuncType(path);
}
</script>

<style scoped lang="scss">
.bg1 {
  background-image: url("~/assets/images/index/bg1.webp");
  background-position: center;
  background-size: cover;
}
.bg2 {
  background-image: url("~/assets/images/index/bg2.webp");
  background-position: center;
  background-size: cover;
}
</style>
