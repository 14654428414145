<template>
  <div class="common-mask" v-if="uploadStore.questionVisible && uploadStore.question">
    <div class="content">
      <div class="main">
        <div class="dialog">
          <div class="ellipse1" />
          <div class="ellipse2" />
          <div class="closeBox" @click="cancel">
            <img src="@/assets/images/close.png" alt="close" />
          </div>
          <div class="dialog-box">
            <div class="dialog-header">
              <p>{{ $t("question-title") }}</p>
              <div class="line"></div>
            </div>

            <div class="dialog-content">
              <div class="question-title">{{ uploadStore.question.question }}</div>
              <div
                class="radio"
                v-for="item in uploadStore.question.options"
                :key="item.autoId"
                @click="onSelect(item.questionAnswerLevel)"
              >
                <img
                  v-if="item.checked"
                  src="@/assets/images/vipDialog/check.png"
                  alt="check"
                />
                <img
                  v-else
                  src="@/assets/images/vipDialog/uncheck.png"
                  alt="unchecked"
                />
                <span>{{ item.questionAnswer }}</span>
              </div>
              <ATextarea
                class="textarea"
                v-show="
                  uploadStore.question.options &&
                  uploadStore.question.options[uploadStore.question.options.length - 1]
                    .checked &&
                  uploadStore.question.isOther
                "
                autoSize
                :placeholder="$t('question-other-placehoder')"
                v-model="questionOther"
                :maxLength="200"
                @change="change"
              />
              <p v-show="otherRequire" style="color: red; margin-top: 5px">
                *{{ $t("question-other-placehoder") }}
              </p>
            </div>

            <div class="dialog-footer">
              <a-button
                class="btn-normal-2"
                :class="{ disable: !isSubmitEnable }"
                @click="submit"
                :loading="loading"
              >
                {{ $t("question-send") }}
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import messageBox from "@/composables/messageBox";
import { useMainStore } from "@/store/index";
import { useUploadStore } from "@/store/upload";
const uploadStore = useUploadStore();
const mainStore = useMainStore();
const { t } = useI18n();
// const uploadStore = defineProps({
//   visible: {
//     type: Boolean,
//     default: false,
//   },
//   question: {
//     type: Object,
//     default: null,
//   },
// });
// const emit = defineEmits(["update:visible"]);
const questionOther = ref("");
const loading = ref(false);
const otherRequire = ref(false);
const isSubmitEnable = computed(() => {
  return !!(
    uploadStore.question &&
    uploadStore.question.options &&
    uploadStore.question.options.filter((v) => v.checked).length
  );
});
function onSelect(key) {
  // type=1单选2多选
  if (uploadStore.question.type === 1) {
    uploadStore.question.options = uploadStore.question.options.map((v) => {
      if (v.questionAnswerLevel === key) {
        v.checked = true;
      } else {
        v.checked = false;
      }
      return v;
    });
  } else {
    uploadStore.question.options = uploadStore.question.options.map((v) => {
      if (v.questionAnswerLevel === key) {
        v.checked = !v.checked;
      }
      return v;
    });
  }
  if(uploadStore.question.isOther && uploadStore.question.options[uploadStore.question.options.length - 1].checked ){
    if (!questionOther.value || questionOther.value.trim().length == 0) {
      otherRequire.value = true;
      return;
    } else {
      otherRequire.value = false;
    }
  }else {
    otherRequire.value = false;
  }
}
function cancel() {
  loading.value = false;
  // emit("update:visible", false);
  uploadStore.setQuestionVisible(false)
  useLogEvent({ eventName: "question-close" });
  useFBLogEvent({ eventName: "question-close" });
}

function submit() {
  if (loading.value || !isSubmitEnable.value) {
    return;
  }
  if(uploadStore.question.isOther && uploadStore.question.options[uploadStore.question.options.length - 1].checked ){
    if (!questionOther.value || questionOther.value.trim().length == 0) {
      otherRequire.value = true;
      return;
    } else {
      otherRequire.value = false;
    }
  }else {
    otherRequire.value = false;
  }
  
  useLogEvent({ eventName: "question-form-submit" });
  useFBLogEvent({ eventName: "question-form-submit" });
  loading.value = true;
  let questionNaireOptions = uploadStore.question.options
    .filter((v) => v.checked)
    .map((v) => v.questionAnswerLevel)
    .toString();
  useApi
    .sendQuestion({
      questionOther: questionOther.value.trim(),
      questionNaireOptions,
      questionNaireId: uploadStore.question.autoId,
    })
    .then((res) => {
      if (res.code === 0) {
        messageBox.success(t("question-succeed"), t("question-succeed-txt"));
        const key = "question-" + mainStore.userInfo.userId;
        let question = localStorage.getItem(key);
        if (question) {
          let d = JSON.parse(question);
          try {
            if (d.id == uploadStore.question.autoId) {
              d.isSubmit = 1;
              localStorage.setItem(key, JSON.stringify(d));
            }
          } catch (e) {}
        }
        // emit("update:visible", false);
        uploadStore.setQuestionVisible(false)
      }
      loading.value = false;
    })
    .catch((e) => {
      loading.value = false;
      messageBox.success(t("question-failed"), t("question-failed-txt"));
    });
}
function change(val) {
  if (!val|| val.trim().length == 0) {
    otherRequire.value = true;
  } else {
    otherRequire.value = false;
  }
}
</script>

<style scoped lang="scss">
.content {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: auto;

  .main {
    margin: 0 auto;
    width: 620px;
    position: relative;
    // top: 100px;
    // padding-bottom: 60px;
    top: 50%;
    transform: translateY(-50%);
    .dialog {
      background: #ffffff;
      border-radius: 30px;
      position: relative;
      overflow: hidden;

      .ellipse1 {
        position: absolute;
        width: 80%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(209, 214, 239, 0.15);
        filter: blur(250px);
        z-index: 0;
      }
      .ellipse2 {
        position: absolute;
        width: 30%;
        height: 100%;
        right: 0;
        top: 0;
        background: rgba(254, 236, 225, 0.1);
        filter: blur(150px);
        z-index: 0;
      }
      .closeBox {
        position: absolute;
        top: 0;
        right: 0;
        padding: 22px;
        z-index: 2;
        cursor: pointer;

        img {
          width: 20px;
        }
      }
      .dialog-box {
        position: relative;
        height: 100%;
        width: 100%;
        z-index: 1;
        padding: 60px;

        .dialog-header {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          color: $text-deep;
          text-align: left;
          position: relative;
          .line {
            height: 2px;
            width: 100px;
            background-color: $text-deep;
            margin-top: 20px;
          }
        }

        .dialog-content {
          z-index: 1;
          text-align: left;
          .question-title {
            margin: 40px 0;
            font-size: 30px;
          }
          .radio {
            display: flex;
            // align-items: ;
            margin-bottom: 16px;
            cursor: pointer;
            img {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              margin-right: 20px;
            }
            span {
            }
          }
          .textarea {
            padding: 0;
            font-family: "Roboto-Bold";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            color: $text-deep;
            border: 0;
            box-shadow: none;
            border-radius: 0;
            background: transparent;
            border-bottom: 2px solid $gray-middle;
            margin: 30px 0 0;
          }
          :deep(.ant-form-item) {
            margin: 30px 0 0;
            padding: 0;

            .ant-form-item-control-wrapper {
              .ant-form-item-children {
                padding: 0 0 4px;
                border-bottom: 2px solid $gray-middle;

                &:focus-within {
                  border-bottom: 2px solid $text-deep;
                }

                textarea,
                input {
                  padding: 0;
                  height: 20px;
                  font-family: "Roboto-Bold";
                  font-style: normal;
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 20px;
                  color: $text-deep;
                  border: 0;
                  box-shadow: none;
                  border-radius: 0;
                  background: transparent;
                }
              }

              .ant-form-explain {
                margin-top: 8px;
              }
            }
          }
        }

        .dialog-footer {
          margin-top: 40px;

          .btn-normal-2 {
            margin: 0 auto;
            width: 300px;
            height: 51px;
            border-radius: 44px;
            font-family: "Roboto-Bold";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #fff;
            background: $main-btn-color-hover;
          }
          .disable {
            background-color: #d1d3db;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .content {
    padding: 100px 16px 40px;
    .main {
      width: 343px;
      margin: 0 auto;
      transform: none;
      top: 0;
      .dialog {
        padding: 48px 24px 40px 24px;
        .dialog-box {
          padding: 0px;
          .dialog-header {
            font-size: 20px;
          }
          .dialog-content {
            .question-title {
              margin: 20px 0;
              font-size: 26px;
            }
            .radio {
              cursor: pointer;
              img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                margin-right: 14px;
              }
            }
          }
          .dialog-footer {
            .btn-normal-2 {
              width: auto;
              height: 55px;
            }
          }
        }
      }
    }
  }
}
</style>
