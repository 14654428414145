import { defineStore } from "pinia";

export const useUploadStore = defineStore("upload", {
  state: () => ({
    s3Info: {},
    images: <any>[],
    questionVisible: false,
    question: <Object|null>null,
    signUpVisible: false,
    funcType: '',
    batchVisible: false
  }),
  actions: {
    setS3Info(info: object) {
      this.s3Info = info;
    },
    setImages(images: Array<any>) {
      this.images = images;
    },
    setQuestionVisible(visible: boolean) {
      this.questionVisible = visible;
    },
    setQuestion(question: Object) {
      this.question = question;
    },
    setSignUpVisible(visible: boolean) {
      this.signUpVisible = visible;
    },
    setFuncType(funcType: string) {
      this.funcType = funcType;
    },
    setBatchVisible(batchVisible: boolean) {
      this.batchVisible = batchVisible;
    },
  },
  persist: {
    paths: ["funcType"],
  },
});
