import { createApp } from "vue";
import Loading from "@/components/FullLoading.vue";

// const app = createApp(messageBox);
// const dom = document.createElement("div");
// const instant:any = app.mount(dom);
// document.body.appendChild(dom)
interface Load {
  title?: string|null
  subText?: string|null
}
let instance:any = null
class FullLoading {
  mountNode: HTMLDivElement | HTMLElement | null | undefined;

  show(title: string, subText: string | null, closeCB?: Function | null) {
    // 创建容器
    const element = document.createElement("div");
    // 将容器插入到body内
    document.body.appendChild(element);
    this.mountNode = element;

    // 创建节点
    const app = createApp({
      render() {
        // 这里使用了h()函数,等价于<myForm :title="title" :text="text" :options="options" @submit="..." @onClosed="..."></myForm>
        return h(Loading, {
          ref: 'loadRef',
          // 参数
          title,
          subText,
          closeCB,
        });
      },
    });
    // 挂载容器，instance就是容器的实例
    instance = app.mount(this.mountNode);
  }
  hide() {
    this.mountNode?.remove();
  }
  updateText({title=null,subText=null}:Load) {
    instance.$refs.loadRef?.updateText({loadTitle:title,loadSubText:subText})
  }
}
export const useFullLoading = () => {
  return new FullLoading();
};
// export default new FullLoading();
// export function  messageBox(title: string|null, text: string|null, type:string='success',delay: number=3000) {
//     return new Promise((resolve, reject) => {
//         // 保存组件实例
//         let myFormRef = ref();
//         // 创建容器
//         const mountNode = document.createElement('div');
//         // 将容器插入到body内
//         document.body.appendChild(mountNode);
//         // 创建节点
//         const app = createApp({
//             render() {
//                 // 这里使用了h()函数,等价于<myForm :title="title" :text="text" :options="options" @submit="..." @onClosed="..."></myForm>
//                 return h(MessageBox, {
//                     // ref: myFormRef,
//                     // 参数
//                     title,
//                     text,
//                     type,
//                     node: mountNode,
//                     delay
//                 })
//             }
//         });
//         // 挂载容器，instance就是容器的实例
//         let instance = app.mount(mountNode);
//         // 打开弹窗
//         // myFormRef.value.showDialog();
//     })
// }
