<template>
  <div
    v-if="show"
    @click="onLoad"
    style="
      background-color: rgb(65, 93, 249);
      align-items: center;
      border-radius: 60px;
      display: flex;
      height: 36px;
      padding: 0px 14px;
      position: fixed;
      width: fit-content;
      right: 12px;
      z-index: 100;
      bottom: 24px;
      margin: 0 12px;
    "
  >
    <div style="margin-left: -2px; margin-right: 4px">
      <div style="display: flex; align-items: center">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.75 11.9125C0.75 5.6422 5.66254 1 12 1C18.3375 1 23.25 5.6422 23.25 11.9125C23.25 18.1828 18.3375 22.825 12 22.825C10.8617 22.825 9.76958 22.6746 8.74346 22.3925C8.544 22.3376 8.33188 22.3532 8.1426 22.4368L5.90964 23.4224C5.32554 23.6803 4.66618 23.2648 4.64661 22.6267L4.58535 20.6253C4.57781 20.3789 4.46689 20.1483 4.28312 19.9839C2.09415 18.0264 0.75 15.1923 0.75 11.9125ZM8.54913 9.86084L5.24444 15.1038C4.92731 15.6069 5.54578 16.1739 6.01957 15.8144L9.56934 13.1204C9.80947 12.9381 10.1413 12.9371 10.3824 13.118L13.0109 15.0893C13.7996 15.6809 14.9252 15.4732 15.451 14.6392L18.7556 9.39616C19.0727 8.893 18.4543 8.326 17.9805 8.68555L14.4307 11.3796C14.1906 11.5618 13.8587 11.5628 13.6176 11.3819L10.9892 9.41061C10.2005 8.81909 9.07479 9.02676 8.54913 9.86084Z"
            fill="white"
          ></path>
        </svg>
      </div>
    </div>
    <div
      style="
        color: white;
        display: flex;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        user-select: none;
        white-space: nowrap;
      "
    >
      {{ loading ? "Loading" : "Help" }}
    </div>
  </div>
</template>
<script setup>
const show = ref(true);
const loading = ref(false);
const id = "facebook-jssdk";
onMounted(() => {
  // 创建 fb-root 元素
var fbRoot = document.createElement("div");
fbRoot.id = "fb-root";

// 创建 fb-customer-chat 元素
var fbCustomerChat = document.createElement("div");
fbCustomerChat.id = "fb-customer-chat";
fbCustomerChat.classList.add("fb-customerchat");
fbCustomerChat.setAttribute("page_id", "107690918395449");
fbCustomerChat.setAttribute("attribution", "biz_inbox");

// 将元素添加到页面的 body 尾部
document.body.appendChild(fbRoot);
document.body.appendChild(fbCustomerChat);

window.fbAsyncInit = function () {
  FB.init({
    xfbml: true,
    version: "v17.0",
  });
};
  if (document.getElementById(id)) {
    show.value = false;
  }
});
function onLoad() {
  if (loading.value) {
    return;
  }
  loading.value = true;
  useLogEvent({ eventName: "Contact" });
  useFBLogEvent({ track: "track",eventName: "Contact",needPrefix: false });
  if (document.getElementById(id)) return;
  let js = document.createElement("script");
  js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
  js.onload = () => {
    console.log("customerchat load ok");
    setTimeout(() => {
      show.value = false;
      loading.value = false;
    }, 1500);
  };
  document.body.appendChild(js);
}
</script>
