<template>
  <div
    class="h-[48px] sm:h-[64px] rounded-full bg-white p-1 pl-[16px] sm:pl-[32px] flex sm:gap-5  items-center"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1753_3409)">
        <path
          d="M22.4625 22.4625L17.2878 17.2878M17.2878 17.2878C19.0112 15.5644 19.9794 13.227 19.9794 10.7897C19.9794 8.3525 19.0112 6.01507 17.2878 4.29168C15.5644 2.56829 13.227 1.6001 10.7897 1.6001C9.58294 1.6001 8.38796 1.83779 7.27302 2.29962C6.15808 2.76144 5.14502 3.43834 4.29168 4.29168C3.43835 5.14502 2.76144 6.15808 2.29962 7.27302C1.83779 8.38796 1.6001 9.58294 1.6001 10.7897C1.6001 11.9965 1.83779 13.1915 2.29962 14.3065C2.76144 15.4214 3.43835 16.4345 4.29168 17.2878C5.14502 18.1411 6.15808 18.818 7.27302 19.2799C8.38796 19.7417 9.58294 19.9794 10.7897 19.9794C13.227 19.9794 15.5644 19.0112 17.2878 17.2878Z"
          stroke="#8A8EA1"
          stroke-width="2"
          stroke-linecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1753_3409">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <a-input
      class="flex-1 bg-white text-[16px] leading-[20px] sm:text-[20px] sm:leading-[24px]"
      :placeholder="$t('help-placeholder')"
      allow-clear
      style="border-width: 0; background-color: white"
      v-model="text"
    />
    <div
      v-if="props.type === 'blue'"
      class="h-full rounded-full px-[16px] sm:px-[30px] text-white-75 text-[16px] sm:text-[20px] leading-[40px] sm:leading-[56px]  bg-mainColor"
      :class="[canSearch ? 'cursor-pointer' : ' opacity-80 cursor-not-allowed']"
      @click="onSearch"
    >
    {{ $t('help-search') }}
    </div>
    <div
     v-else
      class="h-full rounded-full px-[16px] sm:px-[30px] text-white-75 text-[16px] sm:text-[20px] leading-[40px] sm:leading-[56px] "
      :class="[canSearch ? ' bg-text-deep cursor-pointer' : 'bg-text-gray cursor-not-allowed']"
      @click="onSearch"
    >
      {{ $t('help-search') }}
    </div>
  </div>
</template>

<script setup>
// type = 'blue' | 'black'
const props = defineProps({
  type: {
    type: String,
    default: "blue",
  },
});
const emit = defineEmits("onSearch");
const text = ref("");
const canSearch = computed(() => !!text.value.trim());

function onSearch() {
  if (text.value.trim()) {
    console.log("search", text.value.trim());
    emit("onSearch", text.value.trim());
    // const router = useRouter();
    // const localePath = useLocalePath();
    // router.push(
    //   localePath({
    //     name: "support-search",
    //   })
    // );
  }
}
</script>

<style lang="scss" scoped>
.search-btn {
  background: linear-gradient(0deg, #777dff, #777dff),
    linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
  text-align: center;
}
</style>
