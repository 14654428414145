<template>
  <div class="fixed top-0 left-0 z-50 w-full">
    <nav
      v-show="!visibleNav"
      class="flex h-[100px] px-4 items-center justify-between header"
      :class="theme"
    >
      <nuxt-link :to="localePath({ name: 'index' })" :prefetch="false" class="flex items-center">
        <NuxtImg
          :src="logo"
          alt="logo"
          height="40"
          width="40"
          class="mr-3"
        />
        <NuxtImg
          v-if="mainStore.theme === 'dark'"
          v-show="!showBtn"
          :src="pslight"
          alt="logo"
          class="h-[28px] w-[152px]"
        />
        <NuxtImg
          v-else
          v-show="!showBtn"
          :src="psBlack"
          alt="logo"
          class="h-[28px] w-[152px]"
        />
      </nuxt-link>
      <div class="flex-1 flex justify-end pl-4">
        <ArrowBtn
          v-show="showBtn"
          :text="$t('tryNow')"
          path="picma_web-enhance"
          @click="toEnhance(FuncType.Enhance_PRO.path)"
          class="h-12"
          :mode="mainStore.theme === 'dark'?`white`:`black`"
          />
      </div>
      <div class="h-12 pr-[6px] pl-5 flex flex-col gap-[6px] justify-center" @click.stop="showNav">
        <span class="w-6 h-[2px] rounded-full" :class="theme === 'light'?'bg-text-deep':'bg-white'"></span>
        <span class="w-6 h-[2px] rounded-full" :class="theme === 'light'?'bg-text-deep':'bg-white'"></span>
        <span class="w-6 h-[2px] rounded-full" :class="theme === 'light'?'bg-text-deep':'bg-white'"></span>
      </div>
    </nav>
    <!-- <Login></Login> -->
    <MobileNavigator v-model:visible="visibleNav"></MobileNavigator>
  </div>
</template>
<script setup>
import { logo,psBlack,pslight } from "~/utils/base64";
import { useMainStore } from "@/store/index";
import { useUploadStore } from "@/store/upload";
const uploadStore = useUploadStore();
const mainStore = useMainStore();
const route = useRoute();
const visibleNav = ref(false);
const showBtn = ref(false);
const opacity = ref(1)
const theme = ref('')
const transparentList = [
  "apiServices",
  'index'
];
onMounted(()=>{
  document.addEventListener("scroll",onScroll)
})

onUnmounted(()=> {
  document.removeEventListener('scroll',onScroll)
})
function onScroll(e) {
  var navbar = document.querySelector(".header");
      var btn = document.querySelector("#btn-tryNow");
      if(navbar && btn){
        showBtn.value=  doElementsOverlap(navbar,btn)
      }
      if (!navbar) {
        return;
      }
      if(route.name.includes('picma_web-enhance')){
        showBtn.value = false
      }
      
      if (window.scrollY > 100) {
        if (theme.value == "light") {
          navbar.classList.add("header-bg-white");
        } else {
          navbar.classList.add("header-bg-dark");
        }
        // opacity.value = 1
        // if(window.scrollY - scrollY.value >0){
        //   hidden.value = true
        // }else {
        //   hidden.value = false
        // }
      } else {
        // opacity.value = 1- window.scrollY / 100
        // hidden.value = false
        if (theme.value == "light") {
          navbar.classList.remove("header-bg-white");
        } else  {
          navbar.classList.remove("header-bg-dark");
        }
      }
      // scrollY.value = window.scrollY
}
watch(
  () => route.name,
  (newPath, oldPath) => {
    console.log(route.name);
    showBtn.value = false;
    const page = newPath?.split("___")[0];
    if (transparentList.includes(page)) {
      theme.value = '';
    }else {
      theme.value = 'light';
    }
    if(page === 'enhance'){
      showBtn.value = false
    }
  },
  { immediate: true }
);
function showNav() {
  visibleNav.value = true;
}
function toEnhance(path) {
  // uploadStore.setFuncType(FuncType.Enhance_PRO.path);
  uploadStore.setFuncType(path);
}
function doElementsOverlap(el1, el2) {
  const rect1 = el1.getBoundingClientRect();
  const rect2 = el2.getBoundingClientRect();
 
  return !(rect1.left + rect1.width < rect2.left ||
           rect1.right <= rect2.left ||
           rect1.top + rect1.height < rect2.top ||
           rect1.bottom <= rect2.top);
}
</script>

<style scoped lang="scss">

.transparent {
  background-color: transparent;
}
.light,.header-bg-white {
  background-color: #fff;
  // border-bottom: 1px solid $gray-line;
}
.header-bg-dark {
  background-color: #000;
  // border-bottom: 1px solid $gray-line;
}
</style>