<template>
  <ABackTop style="bottom: 76px">
    <div class="antBack">
      <icon-up />
    </div>
  </ABackTop>
</template>

<style lang="scss">
.antBack {
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  background: #f7f7f8;
  border: 1px solid $gray-line;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:active {
    background: $gray-line;
  }
  .arco-icon {
    color: #1f1f1f;
    font-size: 33px;
    font-weight: bold;
    // transform: scale(1.25, 1);
  }
}
@media screen and (max-width: 767px) {
  .antBack {
    width: 30px;
    height: 30px;
    .arco-icon {
      font-size: 14px;
    }
  }
}
</style>
