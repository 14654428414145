<template>
  <div>
    <footer class="bg-black border-t border-white-line overflow-hidden">
      <div
        class="max-w-[1600px] lg:mx-5  2xl:mx-0 mx-auto px-5 py-10 lg:px-0 lg:py-[80px]"
        id="footer"
      >
        <div
          class="flex flex-col gap-5 lg:min-h-[440px] lg:flex-row lg:justify-between"
        >
          <!-- logo -->
          <div class="flex flex-col">
            <div class="hidden sm:block">
              <NuxtImg
                src="/assets/images/logolight.webp"
                alt="logo"
                loading="lazy"
                height="50"
                width="226"
              />
            </div>
            <ul class="flex mt-11 gap-x-[30px] w-full h-6">
              <li>
                <a
                  :href="config.facebook"
                  target="_blank"
                  class="w-6 h-6 link-facebook"
                  aria-label="facebook link"
                ></a>
              </li>
              <li>
                <a
                  :href="config.ins"
                  target="_blank"
                  class="w-6 h-6 link-ins"
                  aria-label="ins link"
                ></a>
              </li>
              <li>
                <a
                  :href="config.YouTube"
                  target="_blank"
                  class="w-6 h-6 link-youtub"
                  aria-label="youtube link"
                ></a>
              </li>
              <li>
                <a
                  :href="config.Twitter"
                  target="_blank"
                  class="w-6 h-6 link-twitter"
                  aria-label="twitter link"
                ></a>
              </li>
            </ul>
            <div class="gap-4 flex sm:hidden mt-6">
              <a
                href="https://apps.apple.com/us/app/picma-ai-photo-enhancer/id1599103406"
                target="_blank"
                @click="downIOS"
              >
                <NuxtImg
                  src="/assets/images/index/appStore.webp"
                  alt="app store"
                  height="50"
                  width="160"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.magictiger.ai.picma"
                target="_blank"
                @click="downGooglePlay"
              >
                <NuxtImg
                  src="/assets/images/index/googlePlay.webp"
                  alt="google play"
                  height="50"
                  width="160"
                />
              </a>
            </div>
          </div>
          <!-- 链接 -->
          <div
            class="flex-1 flex justify-between flex-col sm:flex-row lg:justify-end lg:gap-[50px] xl:gap-[80px]"
          >
            <ul class="flex flex-col gap-y-[30px] mt-12 sm:mt-0">
              <li
                class="font-Roboto-Bold font-bold text-[16px] leading-[18px] text-white"
              >
                {{ $t("header-ai-editor") }}
              </li>
              <li
                v-for="item in editor"
                :key="item.name"
                class="text-text-gray text-[14px] font-normal hover:text-white"
              >
                <nuxt-link
                  :to="
                    localePath({
                      name: 'picma_web-funcType',
                      params: { funcType: item.funcType },
                    })
                  "
                  :prefetch="false"
                  >{{ item.name }}</nuxt-link
                >
              </li>
            </ul>
            <ul class="flex flex-col gap-y-[30px] mt-12 sm:mt-0">
              <li
                class="font-Roboto-Bold font-bold text-[16px] leading-[18px] text-white"
              >
                API
              </li>
              <li
                v-for="item in apiLinks"
                :key="item.name"
                class="text-text-gray hover:text-white"
              >
                <nuxt-link
                  :to="
                    localePath({
                      name: 'apiServices',
                      params: { funcType: item.funcType },
                    })
                  "
                  :prefetch="false"
                  >{{ item.name }}</nuxt-link
                >
              </li>
            </ul>
            <ul class="flex flex-col gap-y-[30px] mt-12 sm:mt-0">
              <li
                class="font-Roboto-Bold font-bold text-[16px] leading-[18px] text-white"
              >
                {{ $t("footer-suport") }}
              </li>
              <li
                v-for="item in support"
                :key="item.name"
                class="text-text-gray hover:text-white"
              >
                <nuxt-link
                  :to="
                    localePath({
                      name: item.path,
                    })
                  "
                  :prefetch="false"
                  >{{ item.name }}</nuxt-link
                >
              </li>
            </ul>
            <!-- <ul class="gap-y-[30px]">
              <li>{{ $t("footer-freeApps") }}</li>
              <li>
                <a
                  href="https://apps.apple.com/us/app/picma-ai-photo-enhancer/id1599103406"
                  target="_blank"
                  class="text-text-shallow"
                  >{{ $t("footer-iPhone") }}</a
                >
              </li>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.magictiger.ai.picma"
                  target="_blank"
                  class="text-text-shallow"
                  >{{ $t("footer-Andorid") }}</a
                >
              </li>
              <li>
                <nuxt-link
                  :to="localePath({ name: 'apiServices' })"
                  :prefetch="false"
                  class="text-text-shallow"
                  >{{ $t("footer-appCenter") }}</nuxt-link
                >
              </li>
            </ul> -->
            <ul class="flex flex-col gap-y-[30px] mt-12 sm:mt-0">
              <li
                class="font-Roboto-Bold font-bold text-[16px] leading-[18px] text-white"
              >
                {{ $t("footer-legal") }}
              </li>
              <li class="text-text-gray hover:text-white">
                <a :href="config.termsofService" target="_blank">{{
                  $t("termsOfService")
                }}</a>
              </li>
              <li class="text-text-gray hover:text-white">
                <a :href="config.privacyPolicy" target="_blank">{{
                  $t("privacyPolicy")
                }}</a>
              </li>
              <li class="text-text-gray hover:text-white">
                <a :href="config.cookiePolicy" target="_blank">{{
                  $t("cookiePolicy")
                }}</a>
              </li>
            </ul>
          </div>
          <!-- 语言选择 -->
          <div class="lg:ml-[70px] lg:-mt-5">
            <div
              class="group min-h-[50px] rounded-[30px] border border-white-line px-4 py-[9px] text-base hover:border-white  sm:w-1/2 lg:w-auto"
            >
              <div class="flex items-center">
                <NuxtImg
                  src="/assets/images/lang.png"
                  alt="lang icon"
                  loading="lazy"
                  height="32"
                  width="32"
                />
                <span class="text-white flex-1 mx-5 min-w-20 text-center">{{
                  localeName
                }}</span>
                <NuxtImg
                  src="/assets/images/arrow.png"
                  alt="lang icon"
                  loading="lazy"
                  height="20"
                  width="20"
                />
              </div>
              <!-- 多语言列表 -->
              <ul class="hidden group-hover:block cursor-pointer">
                <li
                  class="block h-8 text-center rounded-[6px] text-base leading-8 text-text-shallow my-4 hover:bg-gray-bg hover:text-white active:text-white"
                  :class="[item.code === locale ? 'text-white' : '']"
                  v-for="(item, index) in locales"
                  :key="index"
                  :value="item.code"
                  @click="handleChange(item.code)"
                >
                  {{ $t("lang-" + item.code) }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 应用下载 -->
        <div class="flex justify-between items-center mt-3">
          <div class="gap-4 hidden sm:flex">
            <a
              href="https://apps.apple.com/us/app/picma-ai-photo-enhancer/id1599103406"
              target="_blank"
              @click="downIOS"
            >
              <NuxtImg
                src="/assets/images/index/appStore.webp"
                alt="app store"
                height="50"
                width="160"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.magictiger.ai.picma"
              target="_blank"
              @click="downGooglePlay"
            >
              <NuxtImg
                src="/assets/images/index/googlePlay.webp"
                alt="google play"
                height="50"
                width="160"
              />
            </a>
          </div>
          <div class="flex items-center gap-3">
            <NuxtImg
              src="/assets/images/tiger.webp"
              alt="logo icon"
              loading="lazy"
              height="32"
              width="32"
            />

            <span class="text-sm text-text-gray"
              >© 2024 Magictiger All Rights Reserved</span
            >
          </div>
        </div>
        <div class="text-white mt-2" v-if="!hideVersion">
          Version: {{ config.version + `(${config.build})` }}
        </div>
      </div>
    </footer>
  </div>
</template>
<script setup>
import config from "@/utils/config";
// import { FuncType } from "~/utils/type";

const route = useRoute();
const router = useRouter();
const {
  public: { MODE },
} = useRuntimeConfig();
const localePath = useLocalePath();
// import { useMainStore } from "@/store/index";
// const mainStore = useMainStore();
const { locale, locales, setLocale, t } = useI18n();
const {getFuncType} = useFuncType()
const FuncType = ref(getFuncType())
const hideVersion = ref(true);
const editor = ref(getEditor());
const apiLinks = ref([
  { name: t("footer-image"), tag: "1" },
  { name: t("footer-video"), tag: "2" },
]);
const support = ref([
  { name: "Q&A", tag: "1", path: "support" },
  { name: t("footer-about"), tag: "2", path: "apiServices" },
]);
watch(
  () => locale.value,
  () => {
    support.value = [
      { name: "Q&A", tag: "1", path: "support" },
      { name: t("footer-about"), tag: "2", path: "apiServices" },
    ];
    apiLinks.value = [
      { name: t("footer-image"), tag: "1" },
      { name: t("footer-video"), tag: "2" },
    ];
    FuncType.value = getFuncType()
    editor.value = getEditor()
  }
);
// const switchLocalePath = useSwitchLocalePath()
// const availableLocales = computed(() => {
//   return (locales.value).filter(i => i.code !== locale.value)
// })
const localeName = computed(() => {
  return locales.value.filter((v) => v.code === locale.value)[0].name;
});
if (MODE !== "pro") {
  hideVersion.value = false;
}
function getEditor() {
  FuncType.value = getFuncType()
  return [
      { name: FuncType.value.Enhance_PRO.name, funcType: FuncType.value.Enhance_PRO.path },
      { name: FuncType.value.PNG_HD.name, funcType: FuncType.value.PNG_HD.path },
      { name: FuncType.value.Word_HD.name, funcType: FuncType.value.Word_HD.path },
      { name: FuncType.value.WildFocus_AI.name, funcType: FuncType.value.WildFocus_AI.path },
      { name: FuncType.value.Landscape_AI.name, funcType: FuncType.value.Landscape_AI.path },
      { name: FuncType.value.Stable_Motion_AI.name, funcType: FuncType.value.Stable_Motion_AI.path },
      { name: FuncType.value.AI_Eraser.name, funcType: FuncType.value.AI_Eraser.path },
      { name: FuncType.value.Color_Master.name, funcType: FuncType.value.Color_Master.path },
      { name: FuncType.value.Descratch.name, funcType: FuncType.value.Descratch.path },
    ]
}
function handleChange(value) {
  if (value == locale.value) {
    return;
  }
  if (route.name?.indexOf("blog-slug") > -1) {
    let path = value == "en" ? "/" : "/" + value;
    router.push(path);
  } else {
    setLocale(value);
  }
  // setTimeout(() => {
  //   if (window.innerWidth < 767) {
  //     if (!mainStore.isMobile) mainStore.setMobile(true);
  //   } else {
  //     if (mainStore.isMobile) mainStore.setMobile(false);
  //   }
  // }, 100);
}
function downIOS() {
  useLogEvent({ eventName: "index-ios-download" });
  useFBLogEvent({ eventName: "index-ios-download" });
}
function downGooglePlay() {
  useLogEvent({ eventName: "index-google-play-download" });
  useFBLogEvent({ eventName: "index-google-play-download" });
}
</script>
<style scoped lang="scss">
$facebook-actived: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAPFBMVEUAAACsrrysrrusrrurq7usrruqrb2hprusrryrrbqtr7esr7yqrbqsrrqrrburrbmrrbuqrbqsrLysrrsDFljeAAAAE3RSTlMA37+QQO9gEO+wIKAw34CAcGBQmZMhWwAAAKtJREFUWMPt07sOwyAMhWEHY25Jr37/d20llIihQ/BZGsn/hoS+hQN5vcxFJyucB4DVEA+AWAAZADXlwCwQnu/WWowxVQswribNAxKJIGAjDHgQBtwIBFYUiLSXt/iNZ4HjBbmYhrTsl16qGFBRIDiAA+vSk7NAoN8FFFhQQEGgoUBEgYQCdxSopwHtuzmc2s/lwn/BAQccuAIgFkAGgC0AD0BmmQWEM3l/0wcKRqnrFb9C2AAAAABJRU5ErkJggg==");
$facebook-hover: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABABAMAAABYR2ztAAAAMFBMVEUAAAD///////////////////////////////////////////////////////////87TQQwAAAAD3RSTlMA37/vkEBgELAgoIAwcFDSxDp7AAAAsklEQVRIx2MYHKDE/j928NkdosD/Py7wBaIAbgCmERAF/3GDQazg072ZU0MzcCv4UgASZcOp4HMAA34FfxgIKNhAQMFHBgIKfhFS8BUkxH40NASXgu8gIWc8ASUANp6AAg5CCpiGggIuQUFBYWwKPjEgABMhBYyEFPATUjCfkIJ4QgryCSnYT0hBP9aAAgaQAii6gbT9YI/NUQWDVoE9pgLUCsUfp4IvsEoNQwG8UhsuAACRd3fk5YapRgAAAABJRU5ErkJggg==");
$facebook: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAANlBMVEUAAADY2d7Y2t/Y2d7X2t3f39/Y2N3a2t/X29/X19/Z2d7X19/Y2t/X2N3Y2t/Y2N3W2d/Y2d6TFuGAAAAAEXRSTlMA37/vYBCQj0BAsCCggDBwULekMrcAAACpSURBVFjD7dOxDsMgDEVRg4MJkKTl/3+2VZFSDx2C39JIvhvLEYMfeaOUuU/GOSkgd0NZAWwBWAHdlAOzQHw8W2ullKVaAFFXs8wDXIgg4CAM2AkDAoHAhgLlu/pjfSezQDpHz6ZDCurrGFBRIDqAA1sY8VUg0u8iCgQU6CDQUGBFgQUFdhSol4EePp1OHW++8RYccMCBOwBsAVgBYgFEAUl4FmBJ5P1NL/bXmEXTKa+aAAAAAElFTkSuQmCC");
$ins-actived: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAARVBMVEUAAACrq7uprbmsrrysrrusr7ysrrysrbyrrbusrrusrryfpriqrbysr7uqr7qsrruusLysrLyprLyrrbmqqrqvr7+srrtP+yOCAAAAFnRSTlMAQCDfv5/voHCQgBBgrzDPb1BQgDAQaYKofQAAAbJJREFUWMPtl9tuwzAIQIvBjp3msmQd//+pk9oppMGNsStNe9h5rMqJi4GSy18DoqcZMbACEYl8hNNoH7hI8K8cQGwEXS4+cQW9Cr92XEV3FIzMbxl6ribt4x03ADsBtghI4oH5vSOYb2CEK0i6/SYIXEYiaKvJ6l/gHl9Xv+HDKjheWSykgOLinJugp+wJJAmUbbr+uquTblc7o+T0RECHhnN0f+TtAqOuBGTFp4SeVDtqgWSnYFAC9fyyQQSqXmRKrMMAkkyVrBcCJ4l7CLcPLAI5wEfQFWMSOKnPo2GyCCh33PBjJYOgl9NqbSoIpMW++MDjLgaDYMq3+Hr/+GYQOHnUnkEy8zuCJT9BwSBYz5K4GARJblyXZ6oopJArTywKZNbGTClDVTOJIdQ1E9+eRylTZTvzLAMFhmGRgYIvBEH/9WdJ9qHaNw5VIRmWKBHMhk0OkDV0umB5KK6QVPhzRR8BYI1qf1XjlxuJasFoXZICNxFUhVTi31/zNogbwIswtQieSi1yHXqJ6LiSUfdqFd1Vd2vjy4JaBg3QdoFKgY2vfcI0JCJEzAQizvcXz38U3xFj2k2zvlCqAAAAAElFTkSuQmCC");
$ins-hover: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAANlBMVEUAAAD////////////////////////////////////////////////////////////////////xY8b8AAAAEXRSTlMAQJ8g379w74AQYK9QMJCPzzWy4OkAAAGoSURBVFjD7ZfZjsMgDEVjY0MWsvj/f3akFoVMDGGpNJqH3seqPsC1Dc7w3wQW+SByokREzGjhMRqdFOUwxwCWSpFJxe/SoFGFTyhNwjtgEfmIMEqz9mu8kQ5dk0E9AI7xIPLZFqozsMAES8JH1+Q8S5BrPoF5/12dwdYC7imzBQvYemPMCiMndxBN4GTTjdOlTvBSO0v09AHAt4Yz/FpyHWDRlUCZbitWO2lAdKdA0AC9vibkAPlOnfw8QzRTmZUBmNO4ADx/eALoDVinK6YKYEJ8wtW1BsCp7bpA5RxApwCT2K0CAG//5aZ3LuYKwJpucR9MKANMWOqmOTjzZwCfvkGhAuCfTPQVgC2ZcVRpLBaSS5UnVQAE8qUMbc0UC7mtmWT9fZUKN7azHPFCgXn2U/4Nzr1s+5DUXn+pjs2XankPE0oWcFRMckCixY8DFkJxhOTC40poAcBbNb/qiumUVQNG75DkpEvu9GjrA+DnY94plg7RELX2AEKp9WVS9wtKndSA1ZsJnJ66taxtSMhUH4MhhGgENX72aa3zxkxEiUCi4/Xh+ZXSD6YoqV6xgj3mAAAAAElFTkSuQmCC");
$ins: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAQlBMVEUAAADZ2t/X19/Y2d7Y2t/X29/Y2N3X19/Y2d7Y2d7f39/Z2d/X2d3Y2N3X2t3a2t/Y2t/Z2t7X193a2t/V1d/Y2d6n1JhkAAAAFXRSTlMAnyDfv0BwQO+AEK9QkGCPMM9gbzBnjh30AAABs0lEQVRYw+2X2Y6DMAxFSewsQNg69f//6khDRSg2waHSaB7mPlbySXK94DZ/Tc4bXAAiMQEAovGuGG0iXSqaM4ZDUgqsFD9RhVoW3hmqkjkCeqKPCC1Va9rHW7qhsAPAHQDuEkj02RXUGehDF3rBx1jlPG41Wf2CtQADe4PXAo4p8xcWoE/W2jm0+HYDx0xAsenablcnZlc7ffa0AMBDw1n8OXJuXM8rAQrdVqx24IDsziWBA/j5nCADzju1S+PospnMrBOAzcatwO2HEoBf4CvyilEB7CtecHXWAFC6bnxRUQFo8205dlAAwuo/HbTmYlQAnnKLp2zCBcDmo/YaszO/A0jyBHUKQCqZmBSAQcy4yWnUFlKUyhMuAXnWeqGUXVUzZUKsayZ6vo9Swsp2piUPlPB4pDxQ4AQQ+adf1KQfqm3tUGWaCksUByyKTc4BcWFxwTLucoXEi48rGO+cS57tr7xi6sSGdbgLCHzF0SuvOLlJ7y+r4e4LNiFpxcuIT+u6ne1mJnm/GNKJLVh3M2E6qVv1GhpBVv0M3BLIEKD52xeagubHgAgAQiDAgsaH5l9M36590JX7qDFwAAAAAElFTkSuQmCC");
$twitter-actived: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAPFBMVEUAAACorLqsrruqrbusrrusr7yrrburq7urrrurrbusrrurrburrLyqrLqfn7+fr6+srryvr7+rrbisrrsuMNM7AAAAE3RSTlMAIN9gv6CAQM+Q73BQMBAQ7xBwK1NCpgAAAXlJREFUWMPtldGuwiAMQAe0FMaY8/b///W6uFjZHEh9MvEkRmc4pbQlG358E/OUEGMipU7AGxbN09/ze7oBfsY/oobtVzD17RcuWdcbH5jdfYFjW4sw8QGLdv3CbQVwLYKR/UtE2h5OsFV//dD9Mb/23ZkfzExxoS3Ayl9XAiOGrSGyh3RYIK7hd2usOwTwbX8wLMA+Caz6GyML+3PAqT6mx6LIBeAqNRR8pVAWqZ2Bb+QJfspSg0YAM54NG5wfIZXj1o9UKhtpdwf0tD2k7Cx3kov7rkAKcFX5oeiiAiwujKoJwnVUBCiuTFKWQEDuJZYBrkF5AokQ+3wYDjir7IHg3j+HPdqTI5dAl4AMkz4BGSZ9BZLiGiiGqfoeDsoDCLFziHXDZIcqDpQFEC6UFo0vkP3In+NH+c9+qfhwadiNCvrXWp5vqskuQqP8dLKvQX6D0VfSJ2j62KgeoXJ3wTg4sWESvRUDw16OVNpt8pR8RETv3WSGH9/CP2JpjqZ4EL36AAAAAElFTkSuQmCC");
$twitter-hover: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAM1BMVEUAAAD///////////////////////////////////////////////////////////////+3leKCAAAAEHRSTlMAIN9gv5+AQBDvz5BwUDCwtb7lZAAAAXNJREFUWMPtltuOAiEMQLm03GH7/1+7O3GyFWcGhvpk4kmMjuGU0pao+vJJhFoQc/FC3QPtGNRPX4d7ugZ6xv1HTfunpMfbR+rZ1muXiOxjgSUzilDpgEGzveG+AmgUQUc6hyV+OMMM/e3lH4/t3LdXftLB5+j3ABtlKYGIaW8I78EdZjyNcC9rjD0EcHNfaWLgNYmfob/DfTqeAy71yDXL1AF2UEPGDQpl0M8zcJM8wdVtMnAUYD6tBq6PUMbjNocr1TS3ewH/tD2UZg0t0rr7LoALEER+6rooALsLI2oCE6IgQHdlirAEDNIquQ8QkuwETMhrPqgD1gh7wNj75zBHu1pvC8gS4GGSJ8DDJK9AEVwDwTANf4eT8ABMXhxi2TAZNcSCsABM8CVKfMabt/yQ38vfjQYSwsSeVNCday38qbrZDJPye3WORrpBdGH4T3wG6knnULg7oy1c2FBZn8XA9CpnH9QarRaXEdE5W7X68in8AobLeLqUmAxjAAAAAElFTkSuQmCC");
$twitter: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAPFBMVEUAAADX19/Y2d7Y2t/Z2t/Y2d7X2t/f39/Y2d7X197Z2t7X2t3Z2d7Y2N3X2d/Z2dzW2d/X39/Y2dzY2d4HeMgtAAAAE3RSTlMAIN+/n4BAEO9gz2CQcDBQUCCwLgq3jAAAAXlJREFUWMPtltmOgzAMRZPYWcgC7fj//3UGgcbQ0KRxnyr1SAiBch0vNwL15ZPwS0HMxQnlDmjHoD689q/JNdAR+x81qo2o29sHOrOuv9tING0LEplWhIUqDJr1hmoDqBVBB7qGRfxwhWnq18ttj/O1Pj3TR+1dDm4PsFKGEggY94HwHjxhxlEL+7DGpCqA7euVJgYek/hp6nd4TnUd8FQeuGeZTkBq9JCxjUYZdP0MbCdPsMvqDGwF6LvVwGUJtW0mGoc7NWse9wDu4HYoczI0yHw47yK4AV6kj6cpCsDTgREwKcYHQQCtDhRhCxikUbI64aOsAsbnMT2oislIZ8AhXq/D1OpbcqmALAE2kzwBNpO8A0VwDARman6Ho7AAJg+aWGYmo5pMIGwA410JEj3jzFt6n9/L37YMCb6jTtD/n6mZ/Z/0PqcMnfY7dY1+ycLB+uafeA/UncmhcHdGP7NggIXlvRgYH8XZeTXGfCs2I6K1002rL5/CLzkmjsauo2CtAAAAAElFTkSuQmCC");
$youtube-actived: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAQlBMVEUAAACsrryrrbusrrusrrurq7utr7yfpLusrrusrrurrLyprbmsrrqrrbmqrbqqrb2qrLusrryrrbqsrryvr7+srrt4JzygAAAAFXRSTlMA34C/cECfEM/vUCDfsGBgMK+Q7xBWIzBHAAABOklEQVRYw+3XSZKDMAwFUMvywDwl//5X7Q2FK+lYGDlV3Qveno8AWTbm9t/E3s/OhrByQ0Q4EBEzh8262S8xc61bCYWIJ/POEy6hzrxwuMy93B8K3iQEBUrXT1BJr2GESjgCGqg0R0ALldbsnlCK6SPqLHtAB6XppA2ptBltrtP6AJHdA0L+K09tSSOMQnzPQsC4B7BYn0UWp0YUAkw/nC0nygQcrDogFSEGQAgQi2gvBBj/qYgrAebZVAaYrjbAVwbEykfwhGxAex4QN3xS3EgL/Vkryyu6KVrO7oEcrh4o4kjb9tsLgviO2s5EhsiejPVH6Vifgbr9fYGSr95ca7f37x0wAlTGNKy0u/thgMLwxYOm/qibdMPF+r15NzGhEK0u99Ow+NnZLTDz+w9Hw2sI1s2+j+Z2++UHUuTG25Rn1QEAAAAASUVORK5CYII=");
$youtube-hover: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAOVBMVEUAAAD///////////////////////////////////////////////////////////////////////8KOjVvAAAAEnRSTlMA34C/QBBw72DPsJ9QIDCgkG99ua1MAAABNElEQVRYw+2XS5KEIAxACeETQG079z/sbCgsneFjmKruhW/vA0lIgnr4NuxqyOmUIgYA4AIAIGLatSOz2cq3LgIPAkjqigG+BSzqxJtv407rswCjDkAiAFUgFnEcQ5QJUhEEmSAUgZcJfMkhFmJLEIVsWbBIBZQFrhbo0WTUtUxbX22BzoJXPcrkRxIhNvQrNgQxC7C5P10XYDMRtcqs0LtOUBEUtFhwbKIpqMeoswnfEfSL5h2BsmFSoJZZgfnYL/QP0fcFdue/GE6kDT6Vyp0bHYau89tzDZwuKM2StuflG6TmGflFWeQmulPW/WhZJ+a5/r5JBWa6uc629/8bMF4yQTwVKwE0PebND5oHTjjqHix3h22jrhAOOyC62qNhM+T0nhDx+uAIGFPSjsxq1cPDL34A7iuqpU6Wj9kAAAAASUVORK5CYII=");
$youtube: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAP1BMVEUAAADY2d7X2d3Y2t/f39/Y2N3Y2d7X2d3Z2d7Z2t/Z2t7X19/X2d3X2N/X29/X2d/X2d7Z2d7a2t/Z2d7Y2d7pLruwAAAAFHRSTlMA34C/EHDvYLCfz0BQIEAwoJBv0BOFUdMAAAE5SURBVFjD7ZfLcsUgCEBFfJvnLf//rd1kdG5uJQY70y5y9jkaQUD18N8w8+KtjjFgAgAqAAAixl1bv6ym8a0NQJ0AenVmAboFTOqNF93Gvq1PArKqgEQAquBJRD2GIBPEIkgyQSoCJxO4kkMkxJQgClkPwSQV+ENgW4HuTUbdyrR54wX6EGztKHvXkwiB0c/ICMIhQHZ/ui3AkoicQM1wdZ2gIShosaBughUwMeI34W4IVAb65I5AmTQoUNOoYBn9ha8xwcIcorsWmJ1+ojuRVvizVOZvdOq6zi9HLXC4oLAlbT+WZ4jsGblJGSQWfVHWXW9Z90Rj/X2VCvJwcx1t7783YGwyQWCKVRd+eMyrZGEQK1Y46lamu8N2Vmc8djsg2NajYc3e6j0i4vnBkTDEqK3Ps1EPDx98AzrnvVyJdgflAAAAAElFTkSuQmCC");
// .footerBox {
//   background: #000;
//   border-top: 1px solid rgba(255, 255, 255, 0.2);
// }
.link-facebook {
  background-image: $facebook;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  &:hover {
    background-image: $facebook-hover;
  }

  &:active {
    background-image: $facebook-actived;
  }
}

.link-ins {
  background-image: $ins;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;

  &:hover {
    background-image: $ins-hover;
  }

  &:active {
    background-image: $ins-actived;
  }
}

.link-youtub {
  background-image: $youtube;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;

  &:hover {
    background-image: $youtube-hover;
  }

  &:active {
    background-image: $youtube-actived;
  }
}

.link-twitter {
  background-image: $twitter;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;

  &:hover {
    background-image: $twitter-hover;
  }

  &:active {
    background-image: $twitter-actived;
  }
}
</style>
