
// @ts-nocheck


export const localeCodes =  [
  "en",
  "ja",
  "zh",
  "zh_Hant",
  "es",
  "de",
  "fr",
  "ru",
  "pt"
]

export const localeMessages = {
  "en": [],
  "ja": [],
  "zh": [],
  "zh_Hant": [],
  "es": [],
  "de": [],
  "fr": [],
  "ru": [],
  "pt": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "iso": "en"
    },
    {
      "code": "ja",
      "name": "日本語",
      "iso": "ja"
    },
    {
      "code": "zh",
      "name": "中文",
      "iso": "zh"
    },
    {
      "code": "zh_Hant",
      "name": "繁体",
      "iso": "zh-Hant-HK"
    },
    {
      "code": "es",
      "name": "Español",
      "iso": "es"
    },
    {
      "code": "de",
      "name": "Deutsch",
      "iso": "de"
    },
    {
      "code": "fr",
      "name": "Français",
      "iso": "fr"
    },
    {
      "code": "ru",
      "name": "Русский",
      "iso": "ru"
    },
    {
      "code": "pt",
      "name": "Português",
      "iso": "pt"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://picma.magictiger.ai",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "en",
      "name": "English",
      "iso": "en",
      "files": []
    },
    {
      "code": "ja",
      "name": "日本語",
      "iso": "ja",
      "files": []
    },
    {
      "code": "zh",
      "name": "中文",
      "iso": "zh",
      "files": []
    },
    {
      "code": "zh_Hant",
      "name": "繁体",
      "iso": "zh-Hant-HK",
      "files": []
    },
    {
      "code": "es",
      "name": "Español",
      "iso": "es",
      "files": []
    },
    {
      "code": "de",
      "name": "Deutsch",
      "iso": "de",
      "files": []
    },
    {
      "code": "fr",
      "name": "Français",
      "iso": "fr",
      "files": []
    },
    {
      "code": "ru",
      "name": "Русский",
      "iso": "ru",
      "files": []
    },
    {
      "code": "pt",
      "name": "Português",
      "iso": "pt",
      "files": []
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

