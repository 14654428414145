<template>
  <div
    ref="tipRef"
    id="tip"
    v-show="props.funcIndex !== null && props.position.left"
    :style="position"
    class="fixed rounded-2xl p-5 z-20 bg-white shadow-md"
  >
    <div class="w-[210px]">
      <template v-if="props.funcIndex === EnhanceFuncs.Enhance">
        <video
          src="/assets/video/Enhance.mp4"
          style="width: 100%"
          autoplay
          loop
          muted
        ></video>
        <p class="break-words mt-3 text-text-deep text-sm max-h-[140px] overflow-y-auto">
          {{ $t("enhance-func-enhance-detail") }}
        </p>
      </template>
      <template v-else-if="props.funcIndex === EnhanceFuncs.WildFocus">
        <img
          class="rounded-lg"
          src="/assets/images/enhance/WildFocus.webp"
          alt="image"
        />
        <p class="break-words mt-3 text-text-deep text-sm max-h-[140px] overflow-y-auto">
          {{ $t("enhance-func-wildFocus-detail") }}
        </p>
      </template>
      <template v-else-if="props.funcIndex === EnhanceFuncs.Landscape">
        <img
          class="rounded-lg"
          src="/assets/images/enhance/Landscape.webp"
          alt="image"
        />
        <p class="break-words mt-3 text-text-deep text-sm max-h-[140px] overflow-y-auto">
          {{ $t("enhance-func-landscape-detail") }}
        </p>
      </template>
      <template v-else-if="props.funcIndex === EnhanceFuncs.StableMotion">
        <img
          class="rounded-lg"
          src="/assets/images/enhance/Stable.webp"
          alt="image"
        />
        <p class="break-words mt-3 text-text-deep text-sm max-h-[140px] overflow-y-auto">
          {{ $t("enhance-func-stableMotion-detail") }}
        </p>
      </template>
      <template v-else-if="props.funcIndex === EnhanceFuncs.Eraser">
        <video
          src="/assets/video/Eraser.mp4"
          style="width: 100%"
          autoplay
          loop
          muted
        ></video>
        <p class="break-words mt-3 text-text-deep text-sm max-h-[140px] overflow-y-auto">
          {{ $t("enhance-func-eraser-detail") }}
        </p>
      </template>
      <template v-else-if="props.funcIndex === EnhanceFuncs.PNG_HD">
        <img
          class="rounded-lg"
          src="/assets/images/enhance/png.jpg"
          alt="image"
        />
        <p class="break-words mt-3 text-text-deep text-sm max-h-[140px] overflow-y-auto">
          {{ $t("enhance-func-PNG_HD-detail") }}
        </p>
      </template>
      <template v-else-if="props.funcIndex === EnhanceFuncs.Word_HD">
        <img
          class="rounded-lg"
          src="/assets/images/enhance/word.jpg"
          alt="image"
        />
        <p class="break-words mt-3 text-text-deep text-sm max-h-[140px] overflow-y-auto">
          {{ $t("enhance-func-Word_HD-detail") }}
        </p>
      </template>
    </div>
  </div>
</template>

<script setup>
import { EnhanceFuncs } from "@/utils/type";
const props = defineProps({
  funcIndex: {
    type: Number,
    default: EnhanceFuncs.Enhance,
  },
  position: {
    type: Object,
    default: { left: 0, top: 0 }, // leftTop | leftBottom
  },
});
const tipRef = ref(null);
const emit = defineEmits("close");

function hide(e) {
  // 判断是否点击需隐藏的 div 及其内部元素
  if (tipRef.value && !tipRef.value.contains(e.target)) {
    emit("close", false);
  }
}
onMounted(() => {
  document.addEventListener("click", hide);
});
onBeforeMount(() => {
  document.removeEventListener("click", hide);
});
</script>
