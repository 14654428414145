<template>
  <div class="common-mask" v-show="props.visible">
    <div class="dialog bg-cover bg-center" :style="`background-image: url(${bg});`">
      <div class="btn-close" @click="onClose"></div>
      <!-- <div class="ellipse1" />
      <div class="ellipse2" /> -->
      <div class="dialog-content">
        <slot name="default"/>
      </div>
      <p class="link">
        <span>{{ $t("login-agree1")}}</span>
        &nbsp;<a :href="termsofService" target="_blank">{{ $t("login-agree2") }}</a>&nbsp;
        <span>{{ $t("login-agree3") }}</span>
        &nbsp;<a :href="privacyPolicy" target="_blank">{{ $t("login-agree4") }}</a
        >
        <span>{{ $t("login-agree5") }}</span>
      </p>
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script setup>
import { termsofService, privacyPolicy } from "@/utils/config";
const bg='data:image/webp;base64,UklGRqAFAABXRUJQVlA4IJQFAADQUACdASrgAVgCPpFIoU0lpCMiIAgAsBIJaW7gdm12c3U3lXz1dn2/8QBaOoB//9m5Cfrxj9AC0tk5D32ych77ZOSkw+DFTWMV5QfKBl4uThU4MDxyRpT0f64y25teLk5Oix4k5voqcf/b19kYYMlLUBGB+z2N5kymyi8CcfyIoRBEOuiBbB89MZFJW75SrYGxX0WZoSdm2U2NSmUS3BoKksbG8flhCZbyxlJpwXr5E4rzWZ+ppEoexRSUacZDsIYL5pprT36zp2REREQyKLEBPRweKnt7eRtTN1HIRFA0aeV+v7bAwmb3fu9i341GoWFsWSgY6m3fOETsRF8FJWcck+ogNeKt4FF5Lzr3XXzAc9OX7H7t+woc/qBWK0lwrWsolh8JAki5aCfcFGE9EaAqjTyhNWZHt3ovpD9stFiRLZPrCRSgZwXDWkIsjpZ48edyuWk5ZqpEtZZpP/SqKImHlAy8juyt51FZzFlDtyTH60nqQ9WOvMuX1438YBj+v7Wjl/Xb/b3KavI6BQiDREwPWnE8S1GK3hTVbgW8BISMg26TzeOIQ9P2IdHO2gWS7lu5FpM+LLpuDUUQFrMtzCwY1aTEFXXXVcWlOEP6q9S8VFCbU6vvLXeuWOpOKTrEc7/NvoWkeESykqOoD5Dj9aHz3h+7qLgity1HC93BxK3y3x8bpo6PbM4H5aypZsoliYyz3hyvCya5X1gE11zpo5WOBBDuwsvkX2ioIiYIASD9sVbww46EtPbJ4L+4JCrgA+GKvAv9Z3nXxWYyvhICCS+nubIpvwlQh+zmWO9rBRRNymBGuCv2CVYCujOjzm+BASqpcTuzN2n7v7qq0eJEdLDlicSoFnHhBdw2Z40SLb4AAP73bF67n5DF0N/Xt1FvQD1sV0sFRt8G03Ism7QwbbNask2bqgXcuASidCj6QTcc2Bna094+/ci+MuDO6jA8ASIXyMnfNrMQ+B+FLg8gOLcMUxCJ84EAgtd/DXVeKexm7ACI4C+Qd7gIEZykl+Q8ci/B8ve3YKSCTzY+DFHU0eCR5oewmXlPb5iAUNAaGgo2AKo7Cldo9mOjuCUaUPxwnRuAAP6f9xBSUG1FCQCXq0S7LZivG1KJn9Ymq1v4VpMTJVmzPL0oKG1YB51t66all6bUkw0yVFwwUfvUOLHuZGWY4UCgQBBeIa7COZL2mQaChuCh7kaeSXpRGidwRyPFts07SNJBWSCBAAVpdXeGpsKibDmgShbh9fezgDZ2H5c5IwpyjK6bLGP/z0JyWHdcsuhNIBKXnUa2tFQ+onrL15MvNl6JEIURJBnsyN8A06uaBktZSrfaB9619JfvIMyS/beUYMVZ3gsHyGQ22DqesRUDjYFaxkQ3rbyk7jnXaJ60CmBE8Yje9WXncUhR6B8ujmoap4AF9141uZx//YpJ10s15F8c0iKNI1Xyr86tKU0ptylMxch3yp6ZN49LDpwH5hrY/q78vd1HVUZmUkTKJnpMF8bjSY50MoxH2aKS1/5iVSepS6IJ5oB0Anu8lWywUOFwGllO6C/FoT67AKDgEUzw/xCmGz/VveDGYiiUVREPDDj/07pk7aQJPuixG8vShfOm1V34ZzOOtlvd5l78s8U1gf+e+HgmQhPXcxdIq35v0Sre3tF6RcT4I3+eA2yzPvzNckpQFJDPifNDxJDTADsJymbVJ5mtOyGPVvy7SEez9tia2FpnbCQCtp3Zz/tTsA1iaOJ3TkZdsPPSeQAhx5elpi2oGCxu2QldaalbOcIwzqnGI9neSsp6YOus+uNgsY6S3Bjf9ZygFgUpHzPoY+ZgVlYmN32yEG6+EwD0keBXXSJ3CKlKbu8oU6lkVNnrljUhzWa+cnWUBM5qyecTXF/HEhanvMiY7NWHOPOVC83VsiX7sAA='
const emit = defineEmits(['close'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});
function onClose() {
      emit("close");
    }
</script>

<style scoped lang="scss">
.dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 480px;
  // height: 554px;
  background-color: #fff;
  // background-image: url("@/assets/images/login-bg.webp");
  // background-size: cover;
  position: relative;
  border-radius: 30px;
  padding: 40px 60px 32px 60px;
  overflow: hidden;
  z-index: 10;
  .btn-close {
    position: absolute;
    top: 20px;
    right: 19px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .ellipse1 {
    position: absolute;
    left: -50%;
    top: 40px;
    width: 779px;
    height: 779px;
    border-radius: 779px;
    opacity: 0.75;
    background: #d0d5ef;
    filter: blur(75px);
    -webkit-filter: blur(75px);
    z-index: -1;
  }
  .ellipse2 {
    position: absolute;
    left: 0px;
    top: -216px;
    width: 100%;
    height: 401px;
    border-radius: 401px;
    background: #feebe0;
    filter: blur(75px);
    -webkit-filter: blur(75px);
    z-index: -1;
  }
  .dialog-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .link {
    margin-top: 20px;
    font-size: 12px;
    color: $text-gray;
    text-align: center;
    line-height: 14px;
    a {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      color: $main-text-color;
      font-size: 12px;
      
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 767px) {
  .dialog {
    // width: 90%;
    height: auto;
    padding: 40px 16px 20px 16px;
    .ellipse1 {
    filter: blur(40px);
    -webkit-filter: blur(40px);
  }
  .ellipse2 {
    filter: blur(40px);
      -webkit-filter: blur(40px);
    }
  }
}
@media screen and (max-width: 500px) {
  .dialog {
    width: 85%;
    height: auto;
    padding: 40px 16px 20px 16px;
  }
}
</style>
