import { defineStore } from "pinia";
import {useApi} from "@/composables/useApi";
export const useMainStore = defineStore("main", {
  state: () => ({
    theme: "dark",
    footer: true,
    header: true,
    locales: ["en", "zh_Hant"],
    locale: "en",
    userInfo: {},
    visibleLogin: false,
    visibleEmailLogin: false,
    visibleSignUp: false,
    visibleForgot: false,
    visibleVipDialog: false,
    visiblePay: false,
    isMobile: false,
    packageType: 1,
    vipFrom: "",
    vipToLogin: false,
  }),
  actions: {
    setTheme(theme: string) {
      this.theme = theme;
    },
    setFooter(footer: boolean) {
      this.footer = footer;
    },
    setHeader(header: boolean) {
      this.header = header;
    },
    setUserInfo(info: object) {
      this.userInfo = info;
    },
    clearLogin() {
      this.userInfo = {};
      localStorage.setItem("token", "");
      localStorage.setItem("userInfo", "");
    },
    async updateUserInfo() {
      const that = this;
      const token = localStorage.getItem("token");
      if (token) {
        await nextTick();
        useApi.getUserInfo().then((res) => {
          console.log("res", res);
          if (res && res.code == 0) {
            that.setUserInfo(res.data);
          }
        });
      }
    },
    setVisibleLogin(visibleLogin: boolean) {
      this.visibleLogin = visibleLogin;
    },
    setVisibleEmailLogin(visibleEmailLogin: boolean) {
      this.visibleEmailLogin = visibleEmailLogin;
    },
    setVisibleSignUp(visible: boolean) {
      this.visibleSignUp = visible;
    },
    setVisibleForgot(visible: boolean) {
      this.visibleForgot = visible;
    },
    setVisibleVipDialog(visible: boolean) {
      this.visibleVipDialog = visible;
    },
    setVisiblePay(visible: boolean) {
      this.visiblePay = visible;
    },
    setMobile(isMobile: boolean) {
      this.isMobile = isMobile;
    },
    setPackageType(packageType: number) {
      this.packageType = packageType;
    },
    setVipFrom(vipFrom: string) {
      this.vipFrom = vipFrom;
    },
    setVipToLogin(vipToLogin: boolean) {
      this.vipToLogin = vipToLogin;
    },
  },
  persist: {
    paths: ["userInfo", "theme"],
  },
});
export const useBlogStore = defineStore("blog", {
  state: () => ({
    articleTagId: -1,
  }),
  actions: {
    setArticleTagId(articleTagId: number) {
      this.articleTagId = articleTagId;
    }
  }
})