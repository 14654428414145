
export const getFileSuffix = (name: string) => {
  return name.substring(name.lastIndexOf(".")+1).toLowerCase();
};
export const getFileName = (name: string) => {
  return name.substring(0, name.lastIndexOf("."));
};

export const drawWaterMark = (imgUrl: string, str: Array<string> = ["PicMa"], density: number = 4) => {
  // 创建一个Image对象
  var img = new Image();

  // 设置Image对象的src属性
  img.src = imgUrl;
  img.setAttribute("crossOrigin", "Anonymous");
  // 创建一个Promise对象
  var promise = new Promise(function (resolve, reject) {
    // 图片加载完成后执行
    img.onload = function () {
      // 将base64编码作为Promise对象的返回值
      resolve({
        base64: addWaterMark(img, str, density),
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };

    // 图片加载失败后执行
    img.onerror = function () {
      reject(new Error("image load failed!"));
    };
  });

  return promise;
};
export const addWaterMark = (
  img: HTMLImageElement,
  str:Array<string>= ["PicMa"],
  density: number = 6,
  fontSize: number = 12
) => {
  const canvas = <HTMLCanvasElement>document.createElement("canvas");

  // 设置Canvas的宽高为图片的真实尺寸
  canvas.width = img.naturalWidth;
  canvas.height = img.naturalHeight;
  console.log(canvas.width, canvas.height);
  // 将图片绘制到Canvas上
  const ctx = <CanvasRenderingContext2D>canvas.getContext("2d");
  if(ctx === null) return;
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

  // var rotate = -45;
  //绘制水印
  //   if (objmsg.rotate != undefined && objmsg.rotate != null) {//旋转角度[默认20]
  //     ctx.rotate((Math.PI / 120) * -objmsg.rotate);
  // } else {
  //     ctx.rotate((Math.PI / 120) * -20);
  // }
  // ctx.rotate((Math.PI / 180) * rotate);
  // var fontSize = 14;

  let imgWidth = canvas.width;
  let imgHeight = canvas.height;
  if (imgWidth >= 3456) {
    fontSize = 190;
  } else if (imgWidth >= 2700) {
    fontSize = 140;
  } else if (imgWidth >= 2000) {
    //
    fontSize = 110;
  } else if (imgWidth >= 1436) {
    fontSize = 90;
    density = 5;
  } else if (imgWidth >= 1080) {
    //
    fontSize = 50;
  } else if (imgWidth >= 800) {
    fontSize = 40;
  } else if (imgWidth >= 500) {
    //
    fontSize = 34;
  } else {
    fontSize = 14;
  }
  // console.log("fontSize", fontSize);
  // if (objmsg.fontsize != undefined && objmsg.fontsize != null) {//字体大小[默认20px]
  //     fontsize = objmsg.fontsize;
  // }
  ctx.font = fontSize + "px Roboto";
  var fontcolor = "255, 255, 255, 0.2";
  // if (objmsg.fontcolor != undefined && objmsg.fontcolor != null) {//字体颜色透明度[默认白色]
  //     fontcolor = objmsg.fontcolor;
  // }
  ctx.fillStyle = "rgba(" + fontcolor + ")";
  ctx.textAlign = "left";
  ctx.textBaseline = "middle";
  // var density = 3;
  // if (objmsg.density != undefined && objmsg.density != null) {//稠密度[默认3]
  //     density = objmsg.density
  // }
  // for (var i = -2000; i < img.height; i += img.width / density) {
  //   for (var k = 0; k < img.height; k += img.width / density) {
  //     // var str = objmsg.str;
  //     if (str.length == 1) {
  //       ctx.fillText(str[0], i, k);
  //     } else if (str.length == 2) {
  //       ctx.fillText(str[0], i, k);
  //       ctx.fillText(str[1], i, k + (fontSize - 0 + 5)); //多行
  //     } else if (str.length == 3 || str.length > 3) {
  //       ctx.fillText(str[0], i, k);
  //       ctx.fillText(str[1], i, k + (fontSize - 0 + 5)); //多行
  //       ctx.fillText(str[2], i, k + (fontSize * 2 - 0 + 5)); //多行
  //     }
  //   }
  // }

  //文字坐标

  const maxPx = Math.max(imgWidth, imgHeight);

  const stepPx = Math.floor(maxPx / density);

  let arrayX = [0]; //初始水印位置 canvas坐标 0 0 点
  while (arrayX[arrayX.length - 1] < maxPx / 2) {
    arrayX.push(arrayX[arrayX.length - 1] + stepPx);
  }
  arrayX.push(
    ...arrayX.slice(1, arrayX.length).map((el) => {
      return -el;
    })
  );

  // console.log(arrayX);

  for (let i = 0; i < arrayX.length; i++) {
    for (let j = 0; j < arrayX.length; j++) {
      ctx.save();
      ctx.translate(imgWidth / 2, imgHeight / 2); ///画布旋转原点 移到 图片中心
      ctx.rotate(-Math.PI / 5);
      if (str.length > 3) {
        str = str.slice(0, 3);
      }
      str.forEach((el, index) => {
        let offsetY = fontSize * index + 2;
        ctx.fillText(el, arrayX[i], arrayX[j] + offsetY);
      });
      ctx.restore();
    }
  }

  // 获取图片的base64编码
  return ctx.canvas.toDataURL("image/jpeg");
};

