
import type { NitroFetchRequest } from 'nitropack';
import messageBox  from './messageBox';
import { useMainStore } from "@/store/index";
interface Params {
  url: NitroFetchRequest;
  params?: any;
  method?: 'get' | 'post' | 'delete' | 'put';
}
const getErrCodeMsg = (code: number) => {
  
 const app = useNuxtApp()
  if(code === 300612){
    return
  }
  if(code === 300017 || code === 300015){
    code = 300020
  }
  const msg =  app.$i18n.t(`errCode-${code}`)|| app.$i18n.t(`network-err`)
  const title =  app.$i18n.t(`network-err`)
  messageBox.error(title,msg);

}
interface ResData {
  code: number,
  data?: any,
  msg?: string
}
function getGMTOffset() {
  var offset = new Date().getTimezoneOffset();
  var hours = Math.abs(Math.floor(offset / 60));
  var sign = offset > 0 ? "-" : "+";

  return "GMT" + sign + hours;
}

function pad(num: number) {
  return num < 10 ? "0" + num : num;
}
export const request = async ({ url, params, method = 'get' }: Params) => {
  // 接口传参要求
  // interface QueryItem {
  //   uid?: string;
  //   token?: LocationQuery;
  // }
  // const route = useRoute();
  // const query: QueryItem = route.query;

  const config = useRuntimeConfig();
  const mainStore = useMainStore();
  const {data} = await useFetch(url, {
  	// method此处仅仅只处理了get与post请求
    method,
    // ofetch库会自动识别请求地址，对于url已包含域名的请求不会再拼接baseURL
    baseURL: config.public.VUE_APP_BASE_API,
    // onRequest相当于请求拦截
    onRequest({ request, options }) {
      // console.log('request',request)
      const dayjs = useDayjs();
      let Authorization = ''
      let timeZone = getGMTOffset();
      if(process.client){
        Authorization = localStorage.getItem('token')||''
      }
      // 设置请求头
      options.headers = { ...options.headers, Authorization,timeZone };
      // 设置请求参数
      if (method === 'post') {
        options.body = { ...params };
        // options.query = query;
      } else {
        // options.query = Object.assign(query, { ...params });
        options.query = params;
      }
    },
    // onResponse相当于响应拦截
    onResponse({ response }) {
      // 处理响应数据
      if (response._data.code !== 0) {
        // console.warn('=== error url: ', url, '\n params:', params, '\n response:', response._data);
        // 采用vant组件弹出报错弹窗
        // showToast(response._data.message);
        if(response._data.code == 401){
          mainStore.clearLogin()
        }
        if(process.client){
          getErrCodeMsg(response._data.code)
        }
		// 处理服务端渲染时接口报错，可接入日志系统或者邮件系统，此处测试demo采取写入文件系统
        // throw new Error("6666");
        
      } else {
      	// 这里可以根据后端返回的数据结构对返回数据进行处理，比如后端数据返回得比较深还有一层data结构，我这里就统一取出来，在后续接口请求的时候就直接能拿到了
        // response._data = response._data.data;
        // throw new Error("6666");
        // return response._data;
      }
      // return response._data;

    },
    onRequestError({ request, options, error }) {
      // 处理请求错误
      console.warn('request error', error);
      // showToast('Request Error');
      // getErrCodeMsg(2)
    },
    onResponseError({ request, response, options }) {
      // 处理响应错误
      console.warn('Response error', response);
      // showToast('Request Error');
      // getErrCodeMsg(1)
    },
  });
  if(!data.value && process.client){
    const app = useNuxtApp()
    const title =  app.$i18n.t(`network-err`)
    const msg =  app.$i18n.t(`network-err-try`)
    messageBox.error(title,msg);
  }
  // 这里data本身是个ref对象，将其内部值抛出去方便调用时获得数据。
  return <ResData>data.value;
}
export const useDefaultRequest = {
  get: ({ url, params }: Params) => {
    return request({ url, params, method:'get' });
  },
  post: ({ url, params }: Params) => {
    return request({ url, params, method:'post' });
  },
  delete: ({ url, params }: Params) => {
    return request({ url, params, method :'delete' });
  },
  put: ({ url, params }: Params) => {
    return request({ url, params, method : 'put' });
  },
};