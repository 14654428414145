export function getSupportDoc(t:any) {
  const support = {
    guide: [
      {
        q: t("help-guide-q-1"),
        a: t("help-guide-a-1"),
        extend: false,
      },
      {
        q: t("help-guide-q-2"),
        a: t("help-guide-a-2"),
        extend: false,
      },
      {
        q: t("help-guide-q-3"),
        a: t("help-guide-a-3"),
        extend: false,
      },
      {
        q: t("help-guide-q-4"),
        a: t("help-guide-a-4"),
        extend: false,
      },
      {
        q: t("help-guide-q-5"),
        a: t("help-guide-a-5"),
        extend: false,
      },
      {
        q: t("help-guide-q-6"),
        a: t("help-guide-a-6"),
        extend: false,
      },
      {
        q: t("help-guide-q-7"),
        a: t("help-guide-a-7"),
        extend: false,
      },
      {
        q: t("help-guide-q-8"),
        a: t("help-guide-a-8"),
        extend: false,
      },
      {
        q: t("help-guide-q-9"),
        a: t("help-guide-a-9"),
        extend: false,
      },
    ],
    payments: [
      {
        q: t("help-payments-q-1"),
        a: t("help-payments-a-1"),
        extend: false,
      },
      {
        q: t("help-payments-q-2"),
        a: t("help-payments-a-2"),
        extend: false,
      },
      {
        q: t("help-payments-q-3"),
        a: t("help-payments-a-3"),
        extend: false,
      },
      {
        q: t("help-payments-q-4"),
        a: t("help-payments-a-4"),
        extend: false,
      },
      {
        q: t("help-payments-q-5"),
        a: t("help-payments-a-5"),
        extend: false,
      },
      {
        q: t("help-payments-q-6"),
        a: t("help-payments-a-6"),
        extend: false,
      },
      {
        q: t("help-payments-q-7"),
        a: t("help-payments-a-7"),
        extend: false,
      },
      {
        q: t("help-payments-q-8"),
        a: t("help-payments-a-8"),
        extend: false,
      },
      {
        q: t("help-payments-q-9"),
        a: t("help-payments-a-9"),
        extend: false,
      },
      {
        q: t("help-payments-q-10"),
        a: t("help-payments-a-10"),
        extend: false,
      },
      {
        q: t("help-payments-q-11"),
        a: t("help-payments-a-11"),
        extend: false,
      },
      {
        q: t("help-payments-q-12"),
        a: t("help-payments-a-12"),
        extend: false,
      },
      {
        q: t("help-payments-q-13"),
        a: t("help-payments-a-13"),
        extend: false,
      },
    ],
    tech: [
      {
        q: t("help-tech-q-1"),
        a: t("help-tech-a-1"),
        extend: false,
      },
      {
        q: t("help-tech-q-2"),
        a: t("help-tech-a-2"),
        extend: false,
      },
    ],

    api: [
      {
        q: t("help-api-q-1"),
        a: t("help-api-a-1"),
        extend: false,
      },
      {
        q: t("help-api-q-2"),
        a: t("help-api-a-2"),
        extend: false,
      },
      {
        q: t("help-api-q-3"),
        a: t("help-api-a-3"),
        extend: false,
      },
      {
        q: t("help-api-q-4"),
        a: t("help-api-a-4"),
        extend: false,
      },
      {
        q: t("help-api-q-5"),
        a: t("help-api-a-5"),
        extend: false,
      },
    ],
    privacy: [
      {
        q: t("help-privacy-q-1"),
        a: t("help-privacy-a-1"),
        extend: false,
      },
      {
        q: t("help-privacy-q-2"),
        a: t("help-privacy-a-2"),
        extend: false,
      },
      {
        q: t("help-privacy-q-3"),
        a: t("help-privacy-a-3"),
        extend: false,
      },
    ],
  };
  return support;
}

export function getTags(t:any) {
  const tags = {
    guide: {
      key: "guide",
      name: t("help-tag-guide"),
      desc: t("help-tag-guide-desc"),
    },
    payments: {
      key: "payments",
      name: t("help-tag-payments"),
      desc: t("help-tag-payments-desc"),
    },
    tech: {
      key: "tech",
      name: t("help-tag-tech"),
      desc: t("help-tag-tech-desc"),
    },
    api: {
      key: "api",
      name: t("help-tag-api"),
      desc: t("help-tag-api-desc"),
    },
    privacy: {
      key: "privacy",
      name: t("help-tag-privacy"),
      desc: t("help-tag-privacy-desc"),
    },
  };
  return tags;
}
