<template>
  <div class="flex items-center flex-col">
    <p
      class="m-0 mt-[60px] text-center text-text-deep font-Montserrat font-bold text-2xl sm:text-3xl lg:text-5xl lg:mt-[100px]"
    >
      {{ $t("funcType-samples") }}
    </p>
    <div class="flex gap-2 sm:gap-5 justify-center mt-[30px] mb-[60px] flex-wrap">
      <span
        class=" font-Roboto-Bold font-bold cursor-pointer rounded-full h-10 text-sm leading-[40px] border px-3 text-center  sm:px-10 lg:text-base lg:h-11 lg:leading-[44px]"
        v-for="(item, index) in funcTypeData"
        :key="item"
        @click="select(index)"
        :class="
          [index === funcIndex
            ? 'bg-btn-bg-hover border-white-line '
            : 'bg-white border-gray-line ']
        "
        >{{ item.name }}</span
      >
    </div>
    <div
      class="w-full h-[540px] rounded-[20px] bg-light-gray-bg border border-white-line p-3 lg:h-[640px] lg:rounded-[30px] lg:p-5"
    >
      <CompareImg
        class="rounded-[20px] overflow-hidden"
        :origin="funcTypeData[funcIndex].origin"
        :enhance="funcTypeData[funcIndex].enhance"
        :labebType="1"
        animation
      ></CompareImg>
    </div>
    <!-- <p
      class="mt-[30px] mb-[60px] text-center max-w-[900px] text-text-gray text-base leading-[30px]"
    >
      {{ $t("funcType-samples-desc") }}
    </p> -->
  </div>
</template>

<script setup>
const { t } = useI18n();
const props = defineProps({
  funcType: {
    type: String,
    default: "Enhance_PRO",
  },
});
const funcTypeDataMap = ref({
  Enhance_PRO: [
    {
      name: t("funcType-enhance-demo-1"),
      origin: "/assets/images/functype/Enhance/Enhance_01_1.webp",
      enhance: "/assets/images/functype/Enhance/Enhance_01_2.webp",
    },
    {
      name: t("funcType-enhance-demo-2"),
      origin: "/assets/images/functype/Enhance/Enhance_02_1.webp",
      enhance: "/assets/images/functype/Enhance/Enhance_02_2.webp",
    },
    {
      name: t("funcType-enhance-demo-3"),
      origin: "/assets/images/functype/Enhance/Enhance_03_1.webp",
      enhance: "/assets/images/functype/Enhance/Enhance_03_2.webp",
    },
  ],
  WildFocus_AI: [
    {
      name: t("funcType-wildFocus-demo-1"),
      origin: "/assets/images/functype/WildFocus/WildFocus_01_1.webp",
      enhance: "/assets/images/functype/WildFocus/WildFocus_01_2.webp",
    },
    {
      name: t("funcType-wildFocus-demo-2"),
      origin: "/assets/images/functype/WildFocus/WildFocus_02_1.webp",
      enhance: "/assets/images/functype/WildFocus/WildFocus_02_2.webp",
    },
    {
      name: t("funcType-wildFocus-demo-3"),
      origin: "/assets/images/functype/WildFocus/WildFocus_03_1.webp",
      enhance: "/assets/images/functype/WildFocus/WildFocus_03_2.webp",
    },
  ],
  Landscape_AI: [
    {
      name: t("funcType-landscape-demo-1"),
      origin: "/assets/images/functype/Landscape/Landscape_01_1.webp",
      enhance: "/assets/images/functype/Landscape/Landscape_01_2.webp",
    },
    {
      name: t("funcType-landscape-demo-2"),
      origin: "/assets/images/functype/Landscape/Landscape_03_1.webp",
      enhance: "/assets/images/functype/Landscape/Landscape_03_2.webp",
    },
    {
      name: t("funcType-landscape-demo-3"),
      origin: "/assets/images/functype/Landscape/Landscape_02_1.webp",
      enhance: "/assets/images/functype/Landscape/Landscape_02_2.webp",
    },
  ],
  Stable_Motion_AI: [
    {
      name: t("funcType-stableMotion-demo-1"),
      origin: "/assets/images/functype/Motion/Motion_01_1.webp",
      enhance: "/assets/images/functype/Motion/Motion_01_2.webp",
    },
    {
      name: t("funcType-stableMotion-demo-2"),
      origin: "/assets/images/functype/Motion/Motion_02_1.webp",
      enhance: "/assets/images/functype/Motion/Motion_02_2.webp",
    },
    {
      name: t("funcType-stableMotion-demo-3"),
      origin: "/assets/images/functype/Motion/Motion_03_1.webp",
      enhance: "/assets/images/functype/Motion/Motion_03_2.webp",
    },
  ],
  AI_Eraser: [
    {
      name: t("funcType-eraser-demo-1"),
      origin: "/assets/images/functype/Eraser/Eraser_01_1.webp",
      enhance: "/assets/images/functype/Eraser/Eraser_01_2.webp",
    },
    {
      name: t("funcType-eraser-demo-2"),
      origin: "/assets/images/functype/Eraser/Eraser_02_1.webp",
      enhance: "/assets/images/functype/Eraser/Eraser_02_2.webp",
    },
    {
      name: t("funcType-eraser-demo-3"),
      origin: "/assets/images/functype/Eraser/Eraser_03_1.webp",
      enhance: "/assets/images/functype/Eraser/Eraser_03_2.webp",
    },
  ],
  Color_Master: [
    {
      name: t("funcType-colorMaster-demo-1"),
      origin: "/assets/images/functype/Color/Color_02_1.webp",
      enhance: "/assets/images/functype/Color/Color_02_2.webp",
    },
    {
      name: t("funcType-colorMaster-demo-2"),
      origin: "/assets/images/functype/Color/Color_03_1.webp",
      enhance: "/assets/images/functype/Color/Color_03_2.webp",
    },
    {
      name: t("funcType-colorMaster-demo-3"),
      origin: "/assets/images/functype/Color/Color_01_1.webp",
      enhance: "/assets/images/functype/Color/Color_01_2.webp",
    },
  ],
  Descratch: [
    {
      name: t("funcType-descratch-demo-1"),
      origin: "/assets/images/functype/Descratch/Descratch_02_1.webp",
      enhance: "/assets/images/functype/Descratch/Descratch_02_2.webp",
    },
    {
      name: t("funcType-descratch-demo-2"),
      origin: "/assets/images/functype/Descratch/Descratch_03_1.webp",
      enhance: "/assets/images/functype/Descratch/Descratch_03_2.webp",
    },
    {
      name: t("funcType-descratch-demo-3"),
      origin: "/assets/images/functype/Descratch/Descratch_01_1.webp",
      enhance: "/assets/images/functype/Descratch/Descratch_01_2.webp",
    },
  ],
  PNG_HD: [
    {
      name: t("funcType-PNG_HD-demo-1"),
      origin: "/assets/images/functype/PNGHD/PNG1-1.jpg",
      enhance: "/assets/images/functype/PNGHD/PNG1-2.jpg",
    },
    {
      name: t("funcType-PNG_HD-demo-2"),
      origin: "/assets/images/functype/PNGHD/PNG2-1.jpg",
      enhance: "/assets/images/functype/PNGHD/PNG2-2.jpg",
    },
    {
      name: t("funcType-PNG_HD-demo-3"),
      origin: "/assets/images/functype/PNGHD/PNG3-1.jpg",
      enhance: "/assets/images/functype/PNGHD/PNG3-2.jpg",
    },
  ],
  Word_HD: [
    {
      name: t("funcType-Word_HD-demo-1"),
      origin: "/assets/images/functype/WordHD/1-1.jpg",
      enhance: "/assets/images/functype/WordHD/1-2.jpg",
    },
    {
      name: t("funcType-Word_HD-demo-2"),
      origin: "/assets/images/functype/WordHD/2-1.jpg",
      enhance: "/assets/images/functype/WordHD/2-2.jpg",
    },
    {
      name: t("funcType-Word_HD-demo-3"),
      origin: "/assets/images/functype/WordHD/3-1.jpg",
      enhance: "/assets/images/functype/WordHD/3-2.jpg",
    },
  ],
});
const funcTypeData = ref(
  funcTypeDataMap.value[props.funcType || "Enhance_PRO"]
);

const funcIndex = ref(0);
function select(index) {
  funcIndex.value = index;
}
</script>
